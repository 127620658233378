import { Descriptions } from 'antd';
import React from 'react';
import SMResult from 'components/SMResult/SMResult';
import { isEmpty as _isEmpty } from 'lodash';
import moment from 'moment';

const DSSMetadataConstantDetails = props => {
  const { dssConstantMetadata } = props;
  let constantsData = {};

  if (dssConstantMetadata?.dss_constants_metadata_fetching_status === 'fetching') {
    return <SMResult type='loader' message='Loading Dss Constants Data' />;
  }

  if (dssConstantMetadata?.data?.constant_metrics_list) {
    constantsData = {
      ONBOARDING_DATE: _isEmpty(dssConstantMetadata?.data?.constant_metrics_list?.ONBOARDING_DATE)
        ? 'Not Assigned'
        : moment(dssConstantMetadata?.data?.constant_metrics_list?.ONBOARDING_DATE).format('DD-MMM-YYYY'),

      TEAM_LEAD_NAME: _isEmpty(dssConstantMetadata?.data?.constant_metrics_list?.TEAM_LEAD_NAME)
        ? 'Not Assigned'
        : dssConstantMetadata?.data?.constant_metrics_list?.TEAM_LEAD_NAME,

      ACCOUNT_LEAD: _isEmpty(dssConstantMetadata?.data?.constant_metrics_list?.ACCOUNT_LEAD)
        ? 'Not Assigned'
        : dssConstantMetadata?.data?.constant_metrics_list?.ACCOUNT_LEAD,

      STORE_STATUS: _isEmpty(dssConstantMetadata?.data?.constant_metrics_list?.STORE_STATUS)
        ? 'Not Assigned'
        : dssConstantMetadata?.data?.constant_metrics_list?.STORE_STATUS,

      BUSINESS_TYPE: _isEmpty(dssConstantMetadata?.data?.constant_metrics_list?.BUSINESS_TYPE)
        ? 'Not Assigned'
        : dssConstantMetadata?.data?.constant_metrics_list?.BUSINESS_TYPE,

      BUSINESS_MODEL: _isEmpty(dssConstantMetadata?.data?.constant_metrics_list?.BUSINESS_MODEL)
        ? 'Not Assigned'
        : dssConstantMetadata?.data?.constant_metrics_list?.BUSINESS_MODEL,

      CLINET_CODE: _isEmpty(dssConstantMetadata?.data?.constant_metrics_list?.CLINET_CODE)
        ? 'Not Assigned'
        : dssConstantMetadata?.data?.constant_metrics_list?.CLINET_CODE,

      SEO_LEAD_NAME: _isEmpty(dssConstantMetadata?.data?.constant_metrics_list?.SEO_LEAD_NAME)
        ? 'Not Assigned'
        : dssConstantMetadata?.data?.constant_metrics_list?.SEO_LEAD_NAME,

      LOCATION: _isEmpty(dssConstantMetadata?.data?.constant_metrics_list?.LOCATION)
        ? 'Not Assigned'
        : dssConstantMetadata?.data?.constant_metrics_list?.LOCATION,

      ADSPEND_FREEZE_DATE: _isEmpty(dssConstantMetadata?.data?.constant_metrics_list?.ADSPEND_FREEZE_DATE)
        ? 'Not Assigned'
        : moment(dssConstantMetadata?.data?.constant_metrics_list?.ADSPEND_FREEZE_DATE).format('DD-MMM-YYYY')
    };
  }
  return (
    <Descriptions bordered>
      <Descriptions.Item label='Onboarding Date'>{constantsData?.ONBOARDING_DATE}</Descriptions.Item>
      <Descriptions.Item label='Business Type' span={2}>
        {constantsData?.BUSINESS_TYPE}
      </Descriptions.Item>

      <Descriptions.Item label='SEO Lead'>{constantsData?.SEO_LEAD_NAME}</Descriptions.Item>
      <Descriptions.Item label='Client Code' span={2}>
        {constantsData?.CLINET_CODE}
      </Descriptions.Item>

      <Descriptions.Item label='Location'>{constantsData?.LOCATION}</Descriptions.Item>
      <Descriptions.Item label='Freeze Date' span={2}>
        {constantsData?.ADSPEND_FREEZE_DATE}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default DSSMetadataConstantDetails;
