// -- lodash modules
import { find as _find, isArray as _isArray, isEmpty as _isEmpty, uniq as _uniq, includes as _includes } from 'lodash';
import moment from 'moment';
import React from 'react';
import { Select } from 'antd';

// ---- Export values
export const monthlyRecoPublishMaxLimit = 1000;
export const EDIT_CONFIG = 'EDIT';
export const VIEW_CONFIG = 'VIEW';
export const ADD_CONFIG = 'ADD';

export const templateTypeList = [
  {
    key: 'policy',
    value: 'Policy'
  },
  {
    key: 'marketing',
    value: 'Marketing'
  },
  {
    key: 'catalog',
    value: 'Catalog'
  },
  {
    key: 'content',
    value: 'Content'
  },
  {
    key: 'offers',
    value: 'Offers'
  },
  {
    key: 'organic',
    value: 'Organic'
  },
  {
    key: 'website',
    value: 'Website'
  },
  {
    key: 'marketplace',
    value: 'Marketplace'
  }
];
// --- iso to date
export const isoToDate = dateString => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  if (day < 10) {
    day = '0' + day;
  }
  if (month < 10) {
    month = '0' + month;
  }
  return `${day}-${month}-${year}`;
};

// -- get yesterday
export const getYesterday = () => {
  let d = new Date();
  const yesterday = new Date(d.setDate(d.getDate() - 1));
  const yesterdayArray = isoToDate(yesterday).split('-');
  return yesterdayArray[2] + '-' + yesterdayArray[1] + '-' + yesterdayArray[0];
};

// -- convert to name
export const convertTimeCategoryKeyIntoReadableName = str => {
  let i = 0;
  let frags = str.split('_');
  for (i; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(' ');
};

// ---- parameters for formatDateWithMoment  = date, moment date format
export const formatDateWithMoment = (dateValue, dateFormat) => {
  let formattedDate = moment(dateValue).format('YYYY-MM-DD');
  if (dateFormat && !_isEmpty(dateFormat)) {
    formattedDate = moment(dateValue).format(dateFormat);
  }
  return formattedDate || '';
};

// ---- options for recoms
export const recommendationTypes = ['Policy', 'Marketing', 'Content', 'Catalog', 'Offers'];
export const metrics = ['Conversion Rate', 'Revenue Trend', 'AOV', 'Page Load Time'];

// -- convert To Capitalize ex: store_name = Store Name
export const convertToCapitalize = str => {
  let frags = str.split('_');
  for (let i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(' ');
};

export const SIZE = [
  { key: 'large', title: 'Large' },
  { key: 'medium', title: 'Medium' },
  { key: 'small', title: 'Small' }
];

export const TYPE = [
  { key: 'ep_gp', title: 'EP + GP' },
  { key: 'ep_gp_revshare', title: 'EP + GP + RevShare' },
  { key: 'ep_revshare', title: 'EP + RevShare' },
  { key: 'ep', title: 'EP only' },
  { key: 'gp_purerevshare', title: 'GP + PureRevShare' },
  { key: 'gp_revshare', title: 'GP + RevShare' },
  { key: 'gp_only', title: 'GP only' }
];

export const INDUSTRY = [
  { key: 'Ayurvedic Products', title: 'Ayurvedic Products' },
  { key: 'Personalized Gifts', title: 'Personalized Gifts' },
  { key: 'Cosmetics and Personal Care', title: 'Cosmetics and Personal Care' },
  { key: 'Consumer Electronics & Appliances', title: 'Consumer Electronics & Appliances' },
  { key: 'Electrical Appliances', title: 'Electrical Appliances' },
  { key: 'Fashion and Fashion Accessories', title: 'Fashion and Fashion Accessories' },
  { key: 'Health and Nutrition products', title: 'Health and Nutrition products' },
  { key: 'Food & Beverages', title: 'Food & Beverages' },
  { key: 'Furniture, Home Decor & Kitchen Products', title: 'Furniture, Home Decor & Kitchen Products' },
  { key: 'Jewelry', title: 'Jewelry' },
  { key: 'Pet Products', title: 'Pet Products' },
  { key: 'Stationery', title: 'Stationery' },
  { key: 'Liquor', title: 'Liquor' }
];

export const PLATFORM = [
  { key: 'custom', title: 'Custom' },
  { key: 'magento_custom', title: 'Magento Custom' },
  { key: 'shopify', title: 'Shopify' },
  { key: 'shoptimize', title: 'Shoptimize' },
  { key: 'woocommerce', title: 'WooCommerce' }
];

export const MARKETING_OWNERS = [
  { key: 'customer', title: 'Customer' },
  { key: 'shoptimize', title: 'Shoptimize' }
];

export const CURRENCY = [
  {
    code: 'INR',
    name: 'Indian rupee',
    symbol: '₹',
    numeric_code: '356'
  },
  {
    code: 'AED',
    name: 'United Arab Emirates dirham',
    symbol: 'د.إ',
    numeric_code: '784'
  },
  {
    code: 'AFN',
    name: 'Afghan afghani',
    symbol: '؋',
    numeric_code: '971'
  },
  {
    code: 'ALL',
    name: 'Albanian lek',
    symbol: 'L',
    numeric_code: '008'
  },
  {
    code: 'AMD',
    name: 'Armenian dram',
    symbol: '֏',
    numeric_code: '051'
  },
  {
    code: 'ANG',
    name: 'Netherlands Antillean guilder',
    symbol: 'ƒ',
    numeric_code: '532'
  },
  {
    code: 'AOA',
    name: 'Angolan kwanza',
    symbol: 'Kz',
    numeric_code: '973'
  },
  {
    code: 'ARS',
    name: 'Argentine peso',
    symbol: '$',
    numeric_code: '032'
  },
  {
    code: 'AUD',
    name: 'Australlian Dollar',
    symbol: '$',
    numeric_code: '036'
  },
  {
    code: 'AWG',
    name: 'Aruban florin',
    symbol: 'ƒ',
    numeric_code: '533'
  },
  {
    code: 'AZN',
    name: 'Azerbaijani manat',
    symbol: '₼',
    numeric_code: '944'
  },
  {
    code: 'BAM',
    name: 'Bosnia and Herzegovina convertible mark',
    symbol: 'KM',
    numeric_code: '977'
  },
  {
    code: 'BBD',
    name: 'Barbadian dollar',
    symbol: '$',
    numeric_code: '052'
  },
  {
    code: 'BDT',
    name: 'Bangladeshi taka',
    symbol: '৳',
    numeric_code: '050'
  },
  {
    code: 'BGN',
    name: 'Bulgarian lev',
    symbol: 'лв',
    numeric_code: '975'
  },
  {
    code: 'BHD',
    name: 'Bahraini dinar',
    symbol: '.د.ب',
    numeric_code: '048',
    digits_after_decimal_separator: 3
  },
  {
    code: 'BIF',
    name: 'Burundian franc',
    symbol: 'Fr',
    numeric_code: '108',
    digits_after_decimal_separator: 0
  },
  {
    code: 'BMD',
    name: 'Bermudian dollar',
    symbol: '$',
    numeric_code: '060'
  },
  {
    code: 'BND',
    name: 'Brunei dollar',
    symbol: '$',
    numeric_code: '096'
  },
  {
    code: 'BOB',
    name: 'Bolivian boliviano',
    symbol: 'Bs.',
    numeric_code: '068'
  },
  {
    code: 'BRL',
    name: 'Brazilian real',
    symbol: 'R$',
    numeric_code: '986'
  },
  {
    code: 'BSD',
    name: 'Bahamian dollar',
    symbol: '$',
    numeric_code: '044'
  },
  {
    code: 'BTN',
    name: 'Bhutanese ngultrum',
    symbol: 'Nu.',
    numeric_code: '064'
  },
  {
    code: 'BWP',
    name: 'Botswana pula',
    symbol: 'P',
    numeric_code: '072'
  },
  {
    code: 'BYN',
    name: 'New Belarusian ruble',
    symbol: 'Br',
    numeric_code: '933'
  },
  {
    code: 'BZD',
    name: 'Belize dollar',
    symbol: '$',
    numeric_code: '084'
  },
  {
    code: 'CAD',
    name: 'Canadian dollar',
    symbol: '$',
    numeric_code: '124'
  },
  {
    code: 'CDF',
    name: 'Congolese franc',
    symbol: 'Fr',
    numeric_code: '976'
  },
  {
    code: 'CHF',
    name: 'Swiss franc',
    symbol: 'Fr',
    numeric_code: '756'
  },
  {
    code: 'CLP',
    name: 'Chilean peso',
    symbol: '$',
    numeric_code: '152',
    digits_after_decimal_separator: 0
  },
  {
    code: 'CNY',
    name: 'Chinese yuan',
    symbol: '¥',
    numeric_code: '156'
  },
  {
    code: 'COP',
    name: 'Colombia Peso',
    symbol: '$',
    numeric_code: '170'
  },
  {
    code: 'CRC',
    name: 'Costa Rican colón',
    symbol: '₡',
    numeric_code: '188'
  },
  {
    code: 'CUC',
    name: 'Cuban convertible peso',
    symbol: '$',
    numeric_code: '931'
  },
  {
    code: 'CVE',
    name: 'Cape Verdean escudo',
    symbol: 'Esc',
    numeric_code: '132'
  },
  {
    code: 'CZK',
    name: 'Czech koruna',
    symbol: 'Kč',
    numeric_code: '203'
  },
  {
    code: 'DJF',
    name: 'Djiboutian franc',
    symbol: 'Fr',
    numeric_code: '262',
    digits_after_decimal_separator: 0
  },
  {
    code: 'DKK',
    name: 'Danish krone',
    symbol: 'kr',
    numeric_code: '208'
  },
  {
    code: 'DOP',
    name: 'Dominican peso',
    symbol: '$',
    numeric_code: '214'
  },
  {
    code: 'DZD',
    name: 'Algerian dinar',
    symbol: 'د.ج',
    numeric_code: '012'
  },
  {
    code: 'EGP',
    name: 'Egyptian pound',
    symbol: '£',
    numeric_code: '818'
  },
  {
    code: 'ERN',
    name: 'Eritrean nakfa',
    symbol: 'Nfk',
    numeric_code: '232'
  },
  {
    code: 'ETB',
    name: 'Ethiopian birr',
    symbol: 'Br',
    numeric_code: '230'
  },
  {
    code: 'EUR',
    name: 'Euro',
    symbol: '€',
    numeric_code: '978'
  },
  {
    code: 'FJD',
    name: 'Fijian dollar',
    symbol: '$',
    numeric_code: '242'
  },
  {
    code: 'FKP',
    name: 'Falkland Islands pound',
    symbol: '£',
    numeric_code: '238'
  },
  {
    code: 'GBP',
    name: 'Pound Sterling',
    symbol: '£',
    numeric_code: '826'
  },
  {
    code: 'GEL',
    name: 'Georgian Lari',
    symbol: 'ლ',
    numeric_code: '981'
  },
  {
    code: 'GHS',
    name: 'Ghanaian cedi',
    symbol: '₵',
    numeric_code: '936'
  },
  {
    code: 'GIP',
    name: 'Gibraltar pound',
    symbol: '£',
    numeric_code: '292'
  },
  {
    code: 'GMD',
    name: 'Gambian dalasi',
    symbol: 'D',
    numeric_code: '270'
  },
  {
    code: 'GNF',
    name: 'Guinean franc',
    symbol: 'Fr',
    numeric_code: '324',
    digits_after_decimal_separator: 0
  },
  {
    code: 'GTQ',
    name: 'Guatemalan quetzal',
    symbol: 'Q',
    numeric_code: '320'
  },
  {
    code: 'GYD',
    name: 'Guyanese dollar',
    symbol: '$',
    numeric_code: '328'
  },
  {
    code: 'HKD',
    name: 'Hong Kong dollar',
    symbol: '$',
    numeric_code: '344'
  },
  {
    code: 'HNL',
    name: 'Honduran lempira',
    symbol: 'L',
    numeric_code: '340'
  },
  {
    code: 'HRK',
    name: 'Croatian kuna',
    symbol: 'kn',
    numeric_code: '191'
  },
  {
    code: 'HTG',
    name: 'Haitian gourde',
    symbol: 'G',
    numeric_code: '332'
  },
  {
    code: 'HUF',
    name: 'Hungarian forint',
    symbol: 'Ft',
    numeric_code: '348'
  },
  {
    code: 'IDR',
    name: 'Indonesian rupiah',
    symbol: 'Rp',
    numeric_code: '360',
    digits_after_decimal_separator: 0
  },
  {
    code: 'ILS',
    name: 'Israeli New Shekel',
    symbol: '₪',
    numeric_code: '376'
  },

  {
    code: 'IQD',
    name: 'Iraqi dinar',
    symbol: 'ع.د',
    numeric_code: '368',
    digits_after_decimal_separator: 3
  },
  {
    code: 'IRR',
    name: 'Iranian rial',
    symbol: '﷼',
    numeric_code: '364'
  },
  {
    code: 'ISK',
    name: 'Icelandic króna',
    symbol: 'kr',
    numeric_code: '352',
    digits_after_decimal_separator: 0
  },
  {
    code: 'JMD',
    name: 'Jamaican dollar',
    symbol: '$',
    numeric_code: '388'
  },
  {
    code: 'JOD',
    name: 'Jordanian dinar',
    symbol: 'د.ا',
    numeric_code: '400',
    digits_after_decimal_separator: 3
  },
  {
    code: 'JPY',
    name: 'Japanese yen',
    symbol: '¥',
    numeric_code: '392',
    digits_after_decimal_separator: 0
  },
  {
    code: 'KES',
    name: 'Kenyan Shilling',
    symbol: 'K',
    numeric_code: '404'
  },
  {
    code: 'KGS',
    name: 'Kyrgyzstani som',
    symbol: 'с',
    numeric_code: '417'
  },
  {
    code: 'KHR',
    name: 'Cambodian riel',
    symbol: '៛',
    numeric_code: '116'
  },
  {
    code: 'KMF',
    name: 'Comorian franc',
    symbol: 'Fr',
    numeric_code: '174',
    digits_after_decimal_separator: 0
  },
  {
    code: 'KPW',
    name: 'North Korean won',
    symbol: '₩',
    numeric_code: '408'
  },
  {
    code: 'KRW',
    name: 'South Korean won',
    symbol: '₩',
    numeric_code: '410',
    digits_after_decimal_separator: 0
  },
  {
    code: 'KWD',
    name: 'Kuwaiti dinar',
    symbol: 'د.ك',
    numeric_code: '414',
    digits_after_decimal_separator: 3
  },
  {
    code: 'KYD',
    name: 'Cayman Islands dollar',
    symbol: '$',
    numeric_code: '136'
  },
  {
    code: 'KZT',
    name: 'Kazakhstani tenge',
    symbol: '₸',
    numeric_code: '398'
  },
  {
    code: 'LAK',
    name: 'Lao kip',
    symbol: '₭',
    numeric_code: '418'
  },
  {
    code: 'LBP',
    name: 'Lebanese pound',
    symbol: 'ل.ل',
    numeric_code: '422'
  },
  {
    code: 'LKR',
    name: 'Sri Lankan rupee',
    symbol: 'Rs',
    numeric_code: '144'
  },
  {
    code: 'LRD',
    name: 'Liberian dollar',
    symbol: '$',
    numeric_code: '430'
  },
  {
    code: 'LSL',
    name: 'Lesotho loti',
    symbol: 'L',
    numeric_code: '426'
  },
  {
    code: 'LYD',
    name: 'Libyan dinar',
    symbol: 'ل.د',
    numeric_code: '434',
    digits_after_decimal_separator: 3
  },
  {
    code: 'MAD',
    name: 'Moroccan dirham',
    symbol: 'د.م.',
    numeric_code: '504'
  },
  {
    code: 'MDL',
    name: 'Moldovan leu',
    symbol: 'L',
    numeric_code: '498'
  },
  {
    code: 'MGA',
    name: 'Malagasy ariary',
    symbol: 'Ar',
    numeric_code: '969'
  },
  {
    code: 'MKD',
    name: 'Macedonian denar',
    symbol: 'ден',
    numeric_code: '807'
  },
  {
    code: 'MMK',
    name: 'Burmese kyat',
    symbol: 'Ks',
    numeric_code: '104'
  },
  {
    code: 'MNT',
    name: 'Mongolian tögrög',
    symbol: '₮',
    numeric_code: '496'
  },
  {
    code: 'MOP',
    name: 'Macanese pataca',
    symbol: 'P',
    numeric_code: '446'
  },
  {
    code: 'MRO',
    name: 'Mauritanian ouguiya',
    symbol: 'UM',
    numeric_code: '478'
  },
  {
    code: 'MUR',
    name: 'Mauritian rupee',
    symbol: '₨',
    numeric_code: '480'
  },
  {
    code: 'MVR',
    name: 'Maldivian rufiyaa',
    symbol: '.ރ',
    numeric_code: '462'
  },
  {
    code: 'MWK',
    name: 'Malawian kwacha',
    symbol: 'MK',
    numeric_code: '454'
  },
  {
    code: 'MXN',
    name: 'Mexican Peso',
    symbol: '$',
    numeric_code: '484'
  },
  {
    code: 'MYR',
    name: 'Malaysian ringgit',
    symbol: 'RM',
    numeric_code: '458'
  },
  {
    code: 'MZN',
    name: 'Mozambican metical',
    symbol: 'MT',
    numeric_code: '943'
  },
  {
    code: 'NAD',
    name: 'Namibian dollar',
    symbol: '$',
    numeric_code: '516'
  },
  {
    code: 'NGN',
    name: 'Nigerian naira',
    symbol: '₦',
    numeric_code: '566'
  },
  {
    code: 'NIO',
    name: 'Nicaraguan córdoba',
    symbol: 'C$',
    numeric_code: '558'
  },
  {
    code: 'NOK',
    name: 'Norwegian krone',
    symbol: 'kr',
    numeric_code: '578'
  },
  {
    code: 'NPR',
    name: 'Nepalese rupee',
    symbol: '₨',
    numeric_code: '524'
  },
  {
    code: 'NZD',
    name: 'New Zealand dollar',
    symbol: '$',
    numeric_code: '554'
  },
  {
    code: 'OMR',
    name: 'Omani rial',
    symbol: 'ر.ع.',
    numeric_code: '512',
    digits_after_decimal_separator: 3
  },
  {
    code: 'PAB',
    name: 'Panamanian balboa',
    symbol: 'B/.',
    numeric_code: '590'
  },
  {
    code: 'PEN',
    name: 'Nuevo Sol',
    symbol: 'S/',
    numeric_code: '604'
  },
  {
    code: 'PGK',
    name: 'Papua New Guinean kina',
    symbol: 'K',
    numeric_code: '598'
  },
  {
    code: 'PHP',
    name: 'Philippine peso',
    symbol: '₱',
    numeric_code: '608'
  },
  {
    code: 'PKR',
    name: 'Pakistani rupee',
    symbol: '₨',
    numeric_code: '586'
  },
  {
    code: 'PLN',
    name: 'Polish złoty',
    symbol: 'zł',
    numeric_code: '985'
  },
  {
    code: 'PYG',
    name: 'Paraguayan guaraní',
    symbol: '₲',
    numeric_code: '600',
    digits_after_decimal_separator: 0
  },
  {
    code: 'QAR',
    name: 'Qatari riyal',
    symbol: 'ر.ق',
    numeric_code: '634'
  },
  {
    code: 'RON',
    name: 'Romanian leu',
    symbol: 'lei',
    numeric_code: '946'
  },
  {
    code: 'RSD',
    name: 'Serbian dinar',
    symbol: 'дин.',
    numeric_code: '941'
  },
  {
    code: 'RUB',
    name: 'Russian ruble',
    symbol: '₽',
    numeric_code: '643'
  },
  {
    code: 'RWF',
    name: 'Rwandan franc',
    symbol: 'Fr',
    numeric_code: '646',
    digits_after_decimal_separator: 0
  },
  {
    code: 'SAR',
    name: 'Saudi riyal',
    symbol: 'ر.س',
    numeric_code: '682'
  },
  {
    code: 'SBD',
    name: 'Solomon Islands dollar',
    symbol: '$',
    numeric_code: '090'
  },
  {
    code: 'SCR',
    name: 'Seychellois rupee',
    symbol: '₨',
    numeric_code: '690'
  },
  {
    code: 'SDG',
    name: 'Sudanese pound',
    symbol: 'ج.س.',
    numeric_code: '938'
  },
  {
    code: 'SEK',
    name: 'Swedish krona',
    symbol: 'kr',
    numeric_code: '752'
  },
  {
    code: 'SGD',
    name: 'Singapore dollar',
    symbol: '$',
    numeric_code: '702'
  },
  {
    code: 'SHP',
    name: 'Saint Helena pound',
    symbol: '£',
    numeric_code: '654'
  },
  {
    code: 'SLL',
    name: 'Sierra Leonean leone',
    symbol: 'Le',
    numeric_code: '694'
  },
  {
    code: 'SOS',
    name: 'Somali shilling',
    symbol: 'Sh',
    numeric_code: '706'
  },
  {
    code: 'SRD',
    name: 'Surinamese dollar',
    symbol: '$',
    numeric_code: '968'
  },
  {
    code: 'SSP',
    name: 'South Sudanese pound',
    symbol: '£',
    numeric_code: '728'
  },
  {
    code: 'STD',
    name: 'São Tomé and Príncipe dobra',
    symbol: 'Db',
    numeric_code: '678'
  },
  {
    code: 'SYP',
    name: 'Syrian pound',
    symbol: '£',
    numeric_code: '760'
  },
  {
    code: 'SZL',
    name: 'Swazi lilangeni',
    symbol: 'L',
    numeric_code: '748'
  },
  {
    code: 'THB',
    name: 'Baht',
    symbol: '฿',
    numeric_code: '764'
  },
  {
    code: 'TJS',
    name: 'Tajikistani somoni',
    symbol: 'ЅМ',
    numeric_code: '972'
  },
  {
    code: 'TMT',
    name: 'Turkmenistan manat',
    symbol: 'm',
    numeric_code: '934'
  },
  {
    code: 'TND',
    name: 'Tunisian dinar',
    symbol: 'د.ت',
    numeric_code: '788',
    digits_after_decimal_separator: 3
  },
  {
    code: 'TOP',
    name: 'Tongan paʻanga',
    symbol: 'T$',
    numeric_code: '776'
  },
  {
    code: 'TRY',
    name: 'Turkish lira',
    symbol: '₺',
    numeric_code: '949'
  },
  {
    code: 'TTD',
    name: 'Trinidad and Tobago dollar',
    symbol: '$',
    numeric_code: '780'
  },
  {
    code: 'TWD',
    name: 'New Taiwan dollar',
    symbol: '$',
    numeric_code: '901'
  },
  {
    code: 'TZS',
    name: 'Tanzanian shilling',
    symbol: 'Sh',
    numeric_code: '834'
  },
  {
    code: 'UAH',
    name: 'Ukrainian hryvnia',
    symbol: '₴',
    numeric_code: '980'
  },
  {
    code: 'UGX',
    name: 'Ugandan shilling',
    symbol: 'Sh',
    numeric_code: '800',
    digits_after_decimal_separator: 0
  },
  {
    code: 'USD',
    name: 'US Dollar',
    symbol: '$',
    numeric_code: '840'
  },
  {
    code: 'UYU',
    name: 'Uruguayan peso',
    symbol: '$',
    numeric_code: '858'
  },
  {
    code: 'UZS',
    name: 'Uzbekistani som',
    symbol: "so'm",
    numeric_code: '860'
  },
  {
    code: 'VEF',
    name: 'Venezuelan bolívar',
    symbol: 'Bs F',
    numeric_code: '937'
  },
  {
    code: 'VND',
    name: 'Vietnamese đồng',
    symbol: '₫',
    numeric_code: '704',
    digits_after_decimal_separator: 0
  },
  {
    code: 'VUV',
    name: 'Vanuatu vatu',
    symbol: 'Vt',
    numeric_code: '548',
    digits_after_decimal_separator: 0
  },
  {
    code: 'WST',
    name: 'Samoan tālā',
    symbol: 'T',
    numeric_code: '882'
  },
  {
    code: 'XAF',
    name: 'Central African CFA franc',
    symbol: 'Fr',
    numeric_code: '950',
    digits_after_decimal_separator: 0
  },
  {
    code: 'XCD',
    name: 'East Caribbean dollar',
    symbol: '$',
    numeric_code: '951'
  },
  {
    code: 'XOF',
    name: 'West African CFA franc',
    symbol: 'Fr',
    numeric_code: '952',
    digits_after_decimal_separator: 0
  },
  {
    code: 'XPF',
    name: 'CFP franc',
    symbol: 'Fr',
    numeric_code: '953',
    digits_after_decimal_separator: 0
  },
  {
    code: 'YER',
    name: 'Yemeni rial',
    symbol: '﷼',
    numeric_code: '886'
  },
  {
    code: 'ZAR',
    name: 'Rand',
    symbol: 'R',
    numeric_code: '710'
  },
  {
    code: 'ZMW',
    name: 'Zambian kwacha',
    symbol: 'ZK',
    numeric_code: '967'
  }
];

export const convertKeyToTitle = (key, baseArray) => {
  const filteredObject = _find(baseArray, function (singleObject) {
    return singleObject?.key === key;
  });
  return filteredObject?.title;
};

export const convertOrganisationLabelForVertical = (id, baseArray) => {
  const filteredObject = _find(baseArray, function (singleObject) {
    return singleObject?.id === id;
  });
  return filteredObject?.label;
};

export const convertToOrganisationUser = organisationUser => {
  return organisationUser.map(singleOrganisationUser => {
    return {
      userName: singleOrganisationUser?.user_set?.username,
      email: singleOrganisationUser?.user_set?.email,
      isDigitalMarketer: singleOrganisationUser?.is_digital_marketer,
      isAccountManager: singleOrganisationUser?.is_account_manager
    };
  });
};

export const convertToManagerData = data => {
  let managersData = {};
  data &&
    data.forEach(singleData => {
      if (singleData?.is_digital_marketer) {
        managersData = {
          ...managersData,
          digitalMarketer: singleData?.user_set?.email
        };
      } else {
        managersData = { ...managersData };
      }
      if (singleData?.is_account_manager) {
        managersData = {
          ...managersData,
          accountManager: singleData?.user_set?.email
        };
      } else {
        managersData = { ...managersData };
      }
    });

  return managersData;
};

const ACCOUNT_MANAGER_TEXT = 'account_manager';
const DIGITAL_MARKETER_TEXT = 'digital_marketer';

const convertManagerDataToObject = (managerData, type) => {
  return {
    email: managerData,
    is_account_manager: type === ACCOUNT_MANAGER_TEXT ? true : false,
    is_digital_marketer: type === DIGITAL_MARKETER_TEXT ? true : false
  };
};

export const createAMDMArray = (accountManager, digitalMarketer) => {
  let updatedAMDMData = [];
  if (accountManager && digitalMarketer) {
    updatedAMDMData = [
      convertManagerDataToObject(accountManager, ACCOUNT_MANAGER_TEXT),
      convertManagerDataToObject(digitalMarketer, DIGITAL_MARKETER_TEXT)
    ];
  } else if (accountManager) {
    updatedAMDMData = [convertManagerDataToObject(accountManager, ACCOUNT_MANAGER_TEXT)];
  } else {
    updatedAMDMData = [convertManagerDataToObject(digitalMarketer, DIGITAL_MARKETER_TEXT)];
  }
  return updatedAMDMData;
};
export const getDynamicMarkers = markersData => {
  const markers = [];

  _isArray(markersData) &&
    markersData.forEach(item => {
      item.date &&
        markers.push({
          vline: 'true',
          linePosition: '0',
          label: item.displayLabel,
          date: moment(item.date).format('MMM DD YYYY'),
          labelPosition: item.labelPosition,
          labelHAlign: item.labelAlign,
          labelVAlign: 'top',
          color: '#000080',
          alpha: '50',
          dashed: '1',
          dashLen: '5',
          dashGap: '3',
          showLabelBorder: '0'
        });
    });

  return markers;
};

const getTimeDimension = (metricCode, metricMappingList) => {
  const singleMetricList = (metricMappingList && metricMappingList.find(item => item.code === metricCode)) || {};
  return singleMetricList?.query_date_column;
};

const getAggregationType = (metricCode, metricList) => {
  const metricArray = metricList.list.filter(singleMetric => singleMetric.code === metricCode) || {};
  return {
    [metricCode]: metricArray?.[0]?.aggregation_type
  };
};

const getMetricLabel = (metricCode, metricMappingList) => {
  const singleMetricList = (metricMappingList && metricMappingList.find(item => item.code === metricCode)) || {};
  return singleMetricList?.label || metricCode;
};

const getIntegrations = (metricCode, metricMappingList) => {
  const singleMetricList = (metricMappingList && metricMappingList.find(item => item.code === metricCode)) || [];
  return singleMetricList?.properties?.integrations || [];
};

const getDimensionForTimeDimension = (mappingData, metricMappingList) => {
  return getTimeDimension(mappingData?.metric, metricMappingList) || mappingData?.time_dimension;
};

export const getDynamicChartQuery = dynamicQueryProp => {
  const { mappingData, metricMappingList, metricList } = dynamicQueryProp;
  const data = [];

  try {
    const dynamicallyGeneratedFilter = [
      {
        values: mappingData?.dimenstion_value || [],
        operator: 'contains',
        dimension: mappingData?.dimension || 'overall'
      }
    ];

    const chartData = {
      type: 'card_line_single_metric_affected',
      query: [
        {
          filters: !_isEmpty(mappingData?.filters) ? mappingData.filters : dynamicallyGeneratedFilter,
          measures: [mappingData.metric],
          timezone: 'Asia/Kolkata',
          dimensions: [],
          timeDimensions: [
            {
              dateRange: mappingData?.dateRange || [
                moment(moment().subtract(30, 'd')).format('YYYY-MM-DD'),
                moment(moment()).format('YYYY-MM-DD')
              ],
              dimension: getDimensionForTimeDimension(mappingData, metricMappingList),
              granularity: mappingData?.granularity || 'day'
            }
          ],
          aggregationTypes: getAggregationType(mappingData?.metric, metricList) || {}
        }
      ],
      integrations: getIntegrations(mappingData?.metric, metricMappingList) || [],
      info: '',
      title: getMetricLabel(mappingData.metric, metricMappingList) || mappingData.metric
    };

    chartData.query.map(singleQueryData => {
      return singleQueryData.timeDimensions.map(singleTimeDimensions => {
        return [
          (singleTimeDimensions.dateRange[0] = moment().subtract(30, 'days').format('YYYY-MM-DD')),
          (singleTimeDimensions.dateRange[1] = moment().format('YYYY-MM-DD'))
        ];
      });
    });

    data.push(chartData);
  } catch (error) {
    console.log(error);
  }
  return data;
};

export const getDataFromArray = (array, key) => {
  if (_isEmpty(array)) return [];

  const typeList =
    array.map(singleArray => {
      return singleArray[key];
    }) || [];

  return _uniq(typeList);
};

export const fetchRecommendationTemplateArray = (templates, recommendationTemplateType) => {
  const checkTemplateType = singleTemplate => {
    return singleTemplate?.recommendation_types === recommendationTemplateType;
  };
  const filteredTemplates = templates.filter(checkTemplateType);
  return (
    filteredTemplates.map(singleFilteredTemplate => {
      return singleFilteredTemplate?.template_name || '';
    }) || []
  );
};

export const getRecommendationTemplateJSX = (templates, recommendationTemplateType) => {
  const filteredRecommendationTemplates = fetchRecommendationTemplateArray(templates, recommendationTemplateType);
  const recommendationTemplateJSXArray =
    filteredRecommendationTemplates &&
    filteredRecommendationTemplates?.map(singleFilteredRecommendationTemplates => {
      return (
        <Select.Option key={singleFilteredRecommendationTemplates} value={singleFilteredRecommendationTemplates}>
          {singleFilteredRecommendationTemplates}
        </Select.Option>
      );
    });
  return recommendationTemplateJSXArray || [];
};

export const isMetricAndDimensionAvailable = recommendationGroup => {
  return recommendationGroup?.some(item => _isEmpty(item?.metric && item?.dimension)) || false;
};
// ---- check length of unpublished insights
export const checkAnyUnpublishedInsightLinked = insightAndRecommendationMappingSet => {
  const check =
    (!_isEmpty(insightAndRecommendationMappingSet) &&
      insightAndRecommendationMappingSet.filter(
        singleInsightRecommendationMappingItem =>
          !singleInsightRecommendationMappingItem?.insight_set?.state?.includes('published')
      ).length) ||
    0;
  return check > 0;
};

export const fetchTitleFromUrl = pathName => {
  switch (pathName) {
    case '/recommendations':
      return 'Recommendation';
    case '/dashboard':
      return 'Dashboard';
    case '/insights':
      return 'Insights';
    case '/metadata':
      return 'Metadata';
    case '/templates':
      return 'Recommendation Templates';
    case '/spotlight':
      return 'Focused Insights';
    case pathName?.includes('/insights/'):
      return 'Insights Detail';
    case '/inviteuser':
      return 'Invite user on GP';
    case '/notification':
      return 'Notification';
    case '/dss-metadata':
      return 'DSS Metadata'
    default:
      return '';
  }
};

export const checkDatByTodayDate = date => {
  return date ? moment(date).isBefore(moment().format('YYYY-MM-DD')) : false;
};

export const calculateCounts = (stateValue, apiValue) => {
  let finalStateValue = stateValue?.length;
  if (_includes(stateValue, 'all')) {
    finalStateValue = apiValue?.length;
  }
  return !_isEmpty(apiValue)
    ? '[' + finalStateValue + ' / ' + apiValue?.length + ']'
    : '[' + finalStateValue + ' / ' + 0 + ']';
};
