import React, { useState, Suspense, lazy } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import moment from 'moment';
import { Col, Row, Tag, Typography, Tooltip, Space, Button, Modal, Empty, Spin } from 'antd';
import RejectModal from '../../../components/RejectModal';
import {
  DeleteFilled,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  StopOutlined,
  ThunderboltTwoTone
} from '@ant-design/icons';
import { formatDateWithMoment, monthlyRecoPublishMaxLimit } from 'utilityFunctions/utilityFunctions';
import {
  updateRecommendationAction,
  getMonthluPublishedCount,
  deleteRecommendationAction,
  clearLinkedInsight
} from '../../../redux/RecommendationThunk/action';
import { isEmpty as _isEmpty } from 'lodash-es';
import './RecommendationItem.scss';

const UpdateRecommendation = lazy(() =>
  import(/* webpackChunkName: 'updateRecommendation' */ '../UpdateRecommendation/UpdateRecommendation')
);
const { Paragraph, Text } = Typography;
const { confirm } = Modal;

const RecommendationItem = props => {
  const { item, activeTab } = props;
  const dispatch = useDispatch();

  const [editRecommModal, setEditRecommModal] = useState(false);
  const [viewRecommModal, setViewRecommModal] = useState(false);
  const [rejectRecommModal, setRejectRecommModal] = useState(false);

  // -- get data from store
  const monthly_published_count = useSelector(state => state.Recommendations.publish_limit);
  const metaData = useSelector(state => state?.MetaData?.organisationData);
  const userRoles = useSelector(state => state.User?.userRoles);
  const metricList = useSelector(state => state.Recommendations.metric_list);

  const monthlyPublishLimit = metaData?.recommendation_publish_monthly_limit || 5;
  const currentPublishedLimit = monthly_published_count?.published_recommendation_in_current_month || 0;

  // -- convert code to metric lable
  const convertMetricCodeToLabel = metricCode => {
    const filteredMetric = metricList.list.filter(singleMetric => singleMetric.code === metricCode);
    let filteredMetricValue = '';
    if (filteredMetric && filteredMetric[0] && filteredMetric[0].label) {
      filteredMetricValue = filteredMetric[0].label;
    }
    return filteredMetricValue || '';
  };

  // -- Publish button tooltip title
  const getTooltipTitle = (monthlyPublishLimit, currentPublishedLimit) => {
    return (
      `${currentPublishedLimit}  /   ${
        monthlyPublishLimit === monthlyRecoPublishMaxLimit ? 'NA' : monthlyPublishLimit
      } recommendations published for this month.` || ''
    );
  };

  // --- render submit button
  const renderApproveButton = id => {
    return (
      <Button
        type='primary'
        onClick={() => showConfirmApprove(id)}
        // --- Remove below comment is need to show disable button if recomm publish limit reached.
        // disabled={monthlyPublishLimit === currentPublishedLimit}
        disabled={!userRoles?.canApproveReco}
      >
        Approve & Publish
      </Button>
    );
  };
  // --- Submit Recommendation
  const showConfirmSubmit = id => {
    confirm({
      title: 'Do you Want to submit these recommendation for approval?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        const recommObject = {
          admin_status: 'pending_approval',
          end_user_status: '',
          submitted_at: moment()
        };
        dispatch(updateRecommendationAction(id, recommObject, activeTab));
      },
      onCancel() {}
    });
  };

  // --- Approve and publish Recommendation
  const showConfirmApprove = id => {
    confirm({
      title: 'Do you Want to approve and publish this recommendation?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        const recommObject = {
          admin_status: 'published',
          end_user_status: 'to_do',
          published_at: moment()
        };
        dispatch(updateRecommendationAction(id, recommObject, activeTab));

        // --- get monthly limit call
        dispatch(getMonthluPublishedCount());
      },
      onCancel() {}
    });
  };

  // --- Delete Recommendation
  const showConfirmDelete = id => {
    confirm({
      title: 'Do you Want to delete this recommendation?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(deleteRecommendationAction(id, activeTab));
      },
      onCancel() {}
    });
  };

  // --- Reject Recommendation
  const rejectRecommendation = (id, comment) => {
    const recommObject = {
      admin_status: 'rejected_by_superadmin',
      admin_reject_feedback: comment
    };
    dispatch(updateRecommendationAction(id, recommObject, activeTab));
    setRejectRecommModal(false);
  };

  const adminStatusForDraft = ['draft', 'suggested_by_aarya'];

  // --- Check recommendation is expiring today
  const isRecommendationExpiringToday = recommDetails => {
    const todaysDate = moment().format('YYYY-MM-DD');
    const expiryDate =
      recommDetails?.expiry_date && moment(recommDetails?.expiry_date).subtract(1, 'd').format('YYYY-MM-DD');

    if (_isEmpty(recommDetails?.expiry_date) || !moment(todaysDate).isSame(expiryDate)) return null;

    return <div className='expiringTag'>Expiring today</div>;
  };

  return (
    <>
      <div className='c-recommendationItem'>
        {item && !_isEmpty(item) && (
          <>
            <div className='clearfix'>
              {item?.is_ai_generated &&
                item?.admin_status === 'suggested_by_aarya' &&
                isRecommendationExpiringToday(item)}
              <Row>
                <Col md={16}>
                  <span className='recommTitle'>
                    <Space>
                      <span>{item?.title}</span>
                      {item?.admin_status === 'published' && <Tag color='gold'>{item.end_user_status}</Tag>}
                      {item?.admin_status === 'rejected_by_superadmin' && <Tag color='gold'>{item.admin_status}</Tag>}
                    </Space>

                    {item.is_ai_generated && (
                      <Tooltip title={'AI Generated Recommendation'}>
                        <ThunderboltTwoTone />
                      </Tooltip>
                    )}
                  </span>
                </Col>
                <Col md={8} className='text-right'>
                  {/* ----- For Draft Status ----- */}
                  {adminStatusForDraft.includes(item?.admin_status) && (
                    <Space align='end'>
                      <Tooltip title={'Preview'}>
                        <Button className='ant-btn button__cancel' onClick={() => setViewRecommModal(true)}>
                          <EyeOutlined />
                        </Button>
                      </Tooltip>
                      <Tooltip title={'Edit'}>
                        <Button className='ant-btn button__cancel' onClick={() => setEditRecommModal(true)}>
                          <EditOutlined />
                        </Button>
                      </Tooltip>
                      {item?.admin_status === 'suggested_by_aarya' && (
                        <Tooltip title={userRoles?.canRejectReco ? 'Discard' : 'Don`t have discard access'}>
                          <Button
                            type='primary'
                            onClick={() => props.onDiscard({ recommendation: item })}
                            className='ant-btn button__delete'
                            disabled={!userRoles?.canRejectReco}
                          >
                            <StopOutlined />
                          </Button>
                        </Tooltip>
                      )}
                      {item?.admin_status !== 'suggested_by_aarya' && (
                        <Tooltip title={userRoles?.canDeleteReco ? 'Delete' : 'Don`t have delete access'}>
                          <Button
                            type='primary'
                            onClick={() => showConfirmDelete(item.id)}
                            className='ant-btn button__delete'
                            disabled={!userRoles?.canDeleteReco}
                          >
                            <DeleteFilled />
                          </Button>
                        </Tooltip>
                      )}
                      <Tooltip title={'Submit'}>
                        <Button type='primary' onClick={() => showConfirmSubmit(item.id)}>
                          Submit
                        </Button>
                      </Tooltip>
                    </Space>
                  )}
                  {/* ----- For Draft Status End ----- */}

                  {/* ----- For Pending approval Status ----- */}
                  {item?.admin_status === 'pending_approval' && (
                    <Space>
                      <Tooltip title={'Preview'}>
                        <Button className='ant-btn button__cancel' onClick={() => setViewRecommModal(true)}>
                          <EyeOutlined />
                        </Button>
                      </Tooltip>
                      <Tooltip title={userRoles?.canRejectReco ? 'Reject' : 'Don`t have reject access'}>
                        <Button
                          type='primary'
                          onClick={() => setRejectRecommModal(true)}
                          className='ant-btn button__delete'
                          disabled={!userRoles?.canRejectReco}
                        >
                          <StopOutlined />
                        </Button>
                      </Tooltip>

                      {monthlyPublishLimit <= currentPublishedLimit ? (
                        <Tooltip
                          title={
                            userRoles?.canApproveReco
                              ? getTooltipTitle(monthlyPublishLimit, currentPublishedLimit)
                              : 'Don`t have approve access'
                          }
                        >
                          {renderApproveButton(item.id)}
                        </Tooltip>
                      ) : (
                        <Tooltip title={userRoles?.canApproveReco ? 'Approve' : 'Don`t have approve access'}>
                          {renderApproveButton(item.id)}
                        </Tooltip>
                      )}
                    </Space>
                  )}
                  {/* ----- For Pending approval Status End ----- */}

                  {/* ----- For Published Status ----- */}
                  {(item?.admin_status === 'published' || item?.admin_status === 'rejected_by_superadmin') && (
                    <Space>
                      {item.end_user_status !== 'done' && (
                        <Tooltip title={'Edit'}>
                          <Button className='ant-btn button__cancel' onClick={() => setEditRecommModal(true)}>
                            <EditOutlined />
                          </Button>
                        </Tooltip>
                      )}
                      <Tooltip title={'Preview'}>
                        <Button className='ant-btn button__cancel' onClick={() => setViewRecommModal(true)}>
                          <EyeOutlined />
                        </Button>
                      </Tooltip>
                      <Tooltip title={userRoles?.canDeleteReco ? 'Delete' : 'Don`t have delete access'}>
                        <Button
                          type='primary'
                          onClick={() => showConfirmDelete(item.id)}
                          className='ant-btn button__delete'
                          disabled={!userRoles?.canDeleteReco}
                        >
                          <DeleteFilled />
                        </Button>
                      </Tooltip>
                    </Space>
                  )}
                  {/* ----- For Published Status End ----- */}
                </Col>
              </Row>
            </div>

            <div className='clearfix mt-10'>
              {item?.end_user_status === 'ignore' && (
                <div className='infoList' style={{ margin: '6px 0 0' }}>
                  <ul>
                    <li>
                      <span className='dt'>
                        Ignore feedback:
                        <Text type='danger' style={{ marginLeft: '6px' }}>
                          {item?.end_user_delete_feedback}
                        </Text>
                      </span>
                    </li>
                  </ul>
                </div>
              )}
              {item?.admin_status === 'rejected_by_superadmin' && (
                <div className='infoList' style={{ margin: '6px 0 0' }}>
                  <ul>
                    <li>
                      <span className='dt'>
                        Superadmin Reject feedback:
                        <Text type='danger' style={{ marginLeft: '6px' }}>
                          {item?.admin_reject_feedback}
                        </Text>
                      </span>
                    </li>
                  </ul>
                </div>
              )}
            </div>

            <div className='clearfix'>
              <div className='infoList mb-10'>
                <ul>
                  {!_isEmpty(item?.metrics) && (
                    <li>
                      <span className='dt'>
                        Metric Tags: &nbsp;
                        {item?.metrics.map((tag, index) => {
                          return (
                            <Tag color='blue' key={index}>
                              {convertMetricCodeToLabel(tag) || tag}
                            </Tag>
                          );
                        })}
                      </span>
                    </li>
                  )}
                  {item?.recommendation_types?.length > 0 && (
                    <li>
                      <span className='dt'>
                        Recommendation type: &nbsp;
                        {item?.recommendation_types?.map((tag, index) => (
                          <Tag color='blue' key={index}>
                            {tag}
                          </Tag>
                        ))}
                      </span>
                    </li>
                  )}
                </ul>
              </div>

              <div className='infoList'>
                <ul>
                  <li>
                    <span className='dt isPriority st-major'>{item.priority}</span>
                  </li>

                  {item?.admin_status &&
                    ['pending_approval', 'published'].includes(item?.admin_status) &&
                    item?.end_user_status !== 'ignore' &&
                    item?.submitted_at && (
                      <li>
                        <span className='dt'>
                          {/* parameters for formatDateWithMoment  = date, moment date format */}
                          <Tag>Submitted date: {formatDateWithMoment(item.submitted_at)}</Tag>
                        </span>
                      </li>
                    )}
                  {item?.admin_status === 'published' && item?.end_user_status !== 'ignore' && item?.published_at && (
                    <li>
                      <span className='dt'>
                        <Tag>Published date: {formatDateWithMoment(item.published_at)}</Tag>
                      </span>
                    </li>
                  )}
                  {item?.due_date && (
                    <li>
                      <span className='dt'>
                        <Tag>Suggested due date: {formatDateWithMoment(item.due_date)}</Tag>
                      </span>
                    </li>
                  )}
                  {item?.expected_result_date && (
                    <li>
                      <span className='dt'>
                        <Tag>Expected result date: {formatDateWithMoment(item.expected_result_date)}</Tag>
                      </span>
                    </li>
                  )}
                </ul>
              </div>
              <div className='infoList'>
                <button onClick={() => setViewRecommModal(true)} className='linkButton'>
                  Click on preview option to know more details
                </button>
              </div>
            </div>
          </>
        )}

        {item && _isEmpty(item) && <Empty />}
      </div>

      {/* ----- Update Recomm */}
      <Modal
        title='Edit Recommendation'
        visible={editRecommModal}
        onCancel={() => {
          setEditRecommModal(false);
          dispatch(clearLinkedInsight());
        }}
        width='90%'
        style={{ top: 20 }}
        footer={false}
        destroyOnClose={true}
      >
        <Suspense
          fallback={
            <div className='c-spinnerContainer'>
              <Spin />
            </div>
          }
        >
          <UpdateRecommendation
            setEditRecommModal={setEditRecommModal}
            recommendationId={item?.id}
            activeTab={activeTab}
          />
        </Suspense>
      </Modal>
      {/* ----- Update Recomm End */}

      {/* ----- View Recomm */}
      <Modal
        title='Recommendation Details'
        visible={viewRecommModal}
        onCancel={() => {
          setViewRecommModal(false);
          dispatch(clearLinkedInsight());
        }}
        width='90%'
        style={{ top: 20 }}
        footer={false}
        destroyOnClose={true}
      >
        <Suspense
          fallback={
            <div className='c-spinnerContainer'>
              <Spin />
            </div>
          }
        >
          <UpdateRecommendation
            setEditRecommModal={setEditRecommModal}
            recommendationId={item?.id}
            readOnly={true}
            activeTab={activeTab}
          />
        </Suspense>
      </Modal>
      {/* ----- View Recomm End */}

      <RejectModal
        title='Reject Recommendation ?'
        centered
        visible={rejectRecommModal}
        onReject={rejectRecommendation}
        onCancel={() => setRejectRecommModal(false)}
        destroyOnClose={true}
        itemId={item?.id}
        placeholder='Let the user know why...'
      ></RejectModal>
    </>
  );
};

export default compose(withRouter)(RecommendationItem);
