import { all, put, call, takeEvery } from 'redux-saga/effects';
import { message } from 'antd';

import actions from './actions';
import authActions from 'redux/User/actions';
import Api from './api';

const integration = new Api('polaris/integration');

function* handleError(error) {
  if (error.status === 401) {
    yield put({
      type: authActions.LOGOUT
    });
  }
  if (error.response) {
    if (error.response.data && error.response.data.detail) {
      message.error(error.response.data.detail);
    } else {
      message.error('Something went wrong. Please try again after some time.');
    }
  }
}

export function* getBaseVisualisations() {
  try {
    var baseVisualisations = yield call(integration.getBaseVisualisations);
    yield put({
      type: actions.SET_STATE,
      payload: {
        baseVisualisations
      }
    });
  } catch (error) {
    yield call(handleError, error);
  }
}

export default function* rootSaga() {
  yield all([getBaseVisualisations(), takeEvery(actions.GET_BASE_VISUALISATIONS, getBaseVisualisations)]);
}
