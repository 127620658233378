const insightAction = {
  SET_INSIGHT_DATA_RESPONSE: 'insight/SET_INSIGHT_DATA_RESPONSE',
  SET_INSIGHT_DATA: 'insights/SET_INSIGHTS_DATA',
  GET_FILTERED_DATA: 'insights/GET_FILTERED_DATA',
  GET_SPECIFIC_ACTION: 'insights/GET_SPECIFIC_ACTION',
  GET_INSIGHT_BY_DATE_ACTION: 'insights/GET_INSIGHT_BY_DATE_ACTION',
  CREATE_INSIGHT_ACTION: 'insights/CREATE_INSIGHT_ACTION',
  UPDATE_INSIGHT_ACTION: 'insights/UPDATE_INSIGHT_ACTION',
  PUBLISH_INSIGHT_ACTION: 'insights/PUBLISH_INSIGHT_ACTION',
  PUBLISH_MULTIPLE_INSIGHT_ACTION: 'insights/PUBLISH_MULTIPLE_INSIGHT_ACTION',
  DELETE_INSIGHT_ACTION: 'insights/DELETE_INSIGHT_ACTION',
  DELETE_MULTIPLE_INSIGHT_ACTION: 'insights/DELETE_MULTIPLE_INSIGHT_ACTION',
  SET_INSIGHT_API_SUCCESS: 'insights/SET_INSIGHT_API_SUCCESS',
  SET_INSIGHT_API_SUCCESS_REDUCER: 'insights/SET_INSIGHT_API_SUCCESS_REDUCER',
  LINK_INSIGHT: 'insights/LINK_INSIGHT',
  READ_MULTIPLE_INSIGHTS: 'insights/READ_MULTIPLE_INSIGHTS',
  GET_TIME_CONFIGS: 'insights/GET_TIME_CONFIGS',
  GET_TIME_CONFIGS_NEW: 'insights/GET_TIME_CONFIGS_NEW'
};

export default insightAction;
