import { metadataConstants } from './metadataConstant';
const initialState = {
  organisationData: {},
  managersData: {},
  organisationUser: {},
  organisationBroas: {},
  dssConstantMetadata: {},
  dssVariableMetadata: {}
};

export default function MetaData(state = initialState, action) {
  switch (action.type) {
    // --- get Organisation userss
    case metadataConstants.GET_ORGANISATION_USER_REQUEST:
      return {
        ...state,
        organisationUser: { organisation_user_fetching_status: 'fetching' }
      };
    case metadataConstants.GET_ORGANISATION_USER_SUCCESS:
      return {
        ...state,
        organisationUser: { organisation_user_fetching_status: 'success', data: action.payload }
      };
    case metadataConstants.GET_ORGANISATION_USER_ERROR:
      return {
        ...state,
        organisationUser: { organisation_user_fetching_status: 'fail' }
      };
    // --- get AMDM By organisation
    case metadataConstants.GET_AMDM_BY_ORGANISATION_REQUEST:
      return {
        ...state,
        managersData: { amdm_fetching_status: 'fetching' }
      };
    case metadataConstants.GET_AMDM_BY_ORGANISATION_SUCCESS:
      return {
        ...state,
        managersData: { amdm_fetching_status: 'success', data: action.payload }
      };
    case metadataConstants.GET_AMDM_BY_ORGANISATION_ERROR:
      return {
        ...state,
        managersData: { amdm_fetching_status: 'fail' }
      };
    // --- get single Organisation
    case metadataConstants.GET_SINGLE_ORGANISATION_RECORDS_REQUEST:
      return {
        ...state,
        single_organisation_fetching_status: 'fetching'
      };
    case metadataConstants.GET_SINGLE_ORGANISATION_RECORDS_SUCCESS:
      return {
        ...state,
        single_organisation_fetching_status: 'success',
        organisationData: action.payload
      };
    case metadataConstants.GET_SINGLE_ORGANISATION_RECORDS_ERROR:
      return {
        ...state,
        single_organisation_fetching_status: 'fail'
      };
    // --- get Organisation BROAS
    case metadataConstants.GET_ORGANISATION_BROAS_REQUEST:
      return {
        ...state,
        organisationBroas: { organisation_broas_fetching_status: 'fetching' }
      };
    case metadataConstants.GET_ORGANISATION_BROAS_SUCCESS:
      return {
        ...state,
        organisationBroas: { organisation_broas_fetching_status: 'success', data: action.payload }
      };
    case metadataConstants.GET_ORGANISATION_BROAS_ERROR:
      return {
        ...state,
        organisationBroas: { organisation_broas_fetching_status: 'fail' }
      };
    // --- Update Organisation
    case metadataConstants.UPDATE_ORGANISATION_REQUEST:
      return {
        ...state,
        single_organisation_fetching_status: 'fetching'
      };
    case metadataConstants.UPDATE_ORGANISATION_SUCCESS:
      return {
        ...state,
        single_organisation_fetching_status: 'success',
        organisationData: { ...action.payload }
      };
    case metadataConstants.UPDATE_ORGANISATION_ERROR:
      return {
        ...state,
        single_organisation_fetching_status: 'fail'
      };

    // -- Get DSS constants Metadata
    case metadataConstants.GET_CONSTANT_DSS_METADATA_REQUEST:
      return {
        ...state,
        dssConstantMetadata: {
          dss_constants_metadata_fetching_status: 'fetching'
        }
      };
    case metadataConstants.GET_CONSTANT_DSS_METADATA_SUCCESS:
      return {
        ...state,
        dssConstantMetadata: {
          dss_constants_metadata_fetching_status: 'success',
          ...action.payload
        }
      };
    case metadataConstants.GET_CONSTANT_DSS_METADATA_ERROR:
      return {
        ...state,
        dssConstantMetadata: {
          dss_constants_metadata_fetching_status: 'fail'
        }
      };

    // -- Get DSS variable Metadata
    case metadataConstants.GET_VARIABLE_DSS_METADATA_REQUEST:
      return {
        ...state,
        dssVariableMetadata: {
          dss_variables_metadata_fetching_status: 'fetching'
        }
      };
    case metadataConstants.GET_VARIABLE_DSS_METADATA_SUCCESS:
      return {
        ...state,
        dssVariableMetadata: {
          dss_variables_metadata_fetching_status: 'success',
          ...action.payload
        }
      };
    case metadataConstants.GET_VARIABLE_DSS_METADATA_ERROR:
      return {
        ...state,
        dssVariableMetadata: {
          dss_variables_metadata_fetching_status: 'fail'
        }
      };

    default:
      return state;
  }
}
