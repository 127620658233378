import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
  PlusCircleOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { Button, List, message, Modal, Row, Col, Spin, Tabs, Tag, Tooltip } from 'antd';
import DiscardRecommendation from 'components/DiscardRecommendation/DiscardRecommendation';
import { groupBy as _groupBy, isEmpty as _isEmpty } from 'lodash-es';
import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { publishedChildTabsInitialState } from 'utilityFunctions/InitialStates';
import { monthlyRecoPublishMaxLimit } from 'utilityFunctions/utilityFunctions';
import { getBaseVisualisations } from '../../redux/IntegrationThunk/action';

import {
  clearDimensionMapping,
  clearLinkedInsight,
  discardSuggestedRecommendation,
  getMetricGroupMappingList,
  getMetricList,
  getMonthluPublishedCount,
  getRecommendationForSelectedTab,
  getRejectReasons,
  setMetricGroupMappingList
} from '../../redux/RecommendationThunk/action';
import { actions as TRACKER_ACTION, track } from '../../redux/tracker';
import RecommendationItem from './RecommendationItem/RecommendationItem';
import { getSingleOrganisationRecord } from '../../redux/MetaData/metadataAction';
import './Recommendations.scss';

const CreateRecommendation = lazy(() =>
  import(/* webpackChunkName: 'createRecommendation' */ './CreateRecommendation/CreateRecommendation')
);

const { TabPane } = Tabs;

const Recommendations = props => {
  const dispatch = useDispatch();
  const [showAddRecommModal, setShowAddRecommModal] = useState(false);
  const [linkedInsights, setLinkedInsights] = useState([]);
  const [discardReasons, setDiscardReasons] = useState([]);
  const [isDiscardClicked, setIsDiscardClicked] = useState(false);
  const [activeTab, setActiveTab] = useState('suggested_by_aarya');
  const discardedRecommendation = useRef(null);

  // -- get data from store
  const recommendationList = useSelector(state => state.Recommendations.list);
  const user = useSelector(state => state.User.userData);
  const metaData = useSelector(state => state?.MetaData?.organisationData);
  const monthly_published_count = useSelector(state => state.Recommendations.publish_limit);
  const userRoles = useSelector(state => state.User?.userRoles);
  const metricMappingList = useSelector(state => state.Recommendations.metric_Group_mapping_list);
  const organisationId = useSelector(state => state.User.userData?.organisationId);

  // --- function to display tag with monthly published count
  const getMonthlyPublishedStatus = () => {
    const monthlyPublishLimit = metaData?.recommendation_publish_monthly_limit || 5;
    const currentPublishedLimit = monthly_published_count?.published_recommendation_in_current_month || 0;
    return (
      <Tag color={`${monthlyPublishLimit <= currentPublishedLimit ? 'red' : 'blue'}`}>
        <strong style={{ marginRight: '5px' }}>{currentPublishedLimit}</strong>/
        <strong style={{ margin: '0 5px' }}>
          {monthlyPublishLimit === monthlyRecoPublishMaxLimit ? 'NA' : monthlyPublishLimit}
        </strong>
        Recommendations published this month.
      </Tag>
    );
  };

  // --- Recommendation list based on status
  const getRecommListBasedOnStatus = (recommendationList, adminStatus, clientStatus) => {
    const list = [...recommendationList?.data];
    let filteredRecomList = [];

    if (!_isEmpty(adminStatus) && !_isEmpty(clientStatus)) {
      filteredRecomList = list.filter(
        item => item.end_user_status === clientStatus && item.admin_status === adminStatus
      );
    } else if (!_isEmpty(clientStatus) && _isEmpty(adminStatus)) {
      filteredRecomList = list.filter(item => item.end_user_status === clientStatus);
    } else if (_isEmpty(clientStatus) && !_isEmpty(adminStatus)) {
      filteredRecomList = list.filter(item => item.admin_status === adminStatus);
    } else {
      filteredRecomList = list;
    }

    return filteredRecomList || [];
  };

  const handleDiscard = ({ recommendation }) => {
    setIsDiscardClicked(true);
    discardedRecommendation.current = { recommendation, state: {} };
  };
  // --- Render published child catagory tabs
  const renderPublishedChildTabs = (recommendationList, childInitialState) => {
    return (
      <Tabs defaultActiveKey='0'>
        {childInitialState &&
          childInitialState.map((recommItem, index) => {
            return (
              <TabPane tab={recommItem?.title} key={index}>
                <List
                  itemLayout='vertical'
                  size='large'
                  pagination={{
                    pageSize: 5,
                    hideOnSinglePage: true
                  }}
                  dataSource={getRecommListBasedOnStatus(
                    recommendationList,
                    recommItem?.admin_status,
                    recommItem?.end_user_status
                  )}
                  renderItem={item => <RecommendationItem item={item} key={item.id} activeTab={activeTab} />}
                />
              </TabPane>
            );
          })}
      </Tabs>
    );
  };

  const onDiscardOk = () => {
    const recommendationRef = discardedRecommendation;
    const selectedReason = recommendationRef.current?.state?.reason || '';
    const feedback = recommendationRef.current?.state?.feedback || '';
    recommendationRef.current.recommendation.is_discarded = true;
    recommendationRef.current.recommendation.admin_discard_feedback = { [selectedReason]: feedback };
    discardSuggestedRecommendation(discardedRecommendation.current?.recommendation)
      .then(() => {
        message.success('Recommendation discarded successfully');
        setIsDiscardClicked(false);
        dispatch(getRecommendationForSelectedTab(activeTab));
      })
      .catch(error => {
        console.error(error);
        message.error('Error while discarding recommendation');
        setIsDiscardClicked(false);
      });
  };

  const onDiscardCancel = () => {
    setIsDiscardClicked(false);
    Modal.destroyAll();
  };

  // --- get all recoms
  useEffect(() => {
    dispatch(getSingleOrganisationRecord(organisationId));
    track(TRACKER_ACTION.recommendationListView, {});
  }, [user, dispatch, organisationId]);

  // --- get Monthly Published Count
  useEffect(() => {
    dispatch(getMonthluPublishedCount());
  }, [recommendationList, user, dispatch]);

  // --- Link insights
  useEffect(() => {
    if (props?.location?.isOpenModal && props?.location?.insightprops) {
      setLinkedInsights(props.location.insightprops);
      setShowAddRecommModal(props.location.isOpenModal);
    }
  }, [props.location]);

  useEffect(() => {
    dispatch(getBaseVisualisations());
    dispatch(getMetricList());
    dispatch(getMetricGroupMappingList());
    return () => {
      dispatch(clearDimensionMapping());
    };
  }, [dispatch]);

  useEffect(() => {
    getRejectReasons().then(reasons => {
      setDiscardReasons(reasons);
    });
  }, []);

  useEffect(() => {
    let data = {};
    if (metricMappingList?.data_fetching_status === 'success' && !_isEmpty(metricMappingList.list)) {
      data = _groupBy(metricMappingList.list, groupObject => {
        return groupObject.metric_set.code;
      });
    }
    dispatch(setMetricGroupMappingList(data));
  }, [dispatch, metricMappingList]);

  useEffect(() => {
    dispatch(getRecommendationForSelectedTab(activeTab));
    track(TRACKER_ACTION.recommendationListView, {});
  }, [activeTab, dispatch]);

  const onTabChange = activeKey => {
    setActiveTab(activeKey);
  };

  return (
    <>
      <Helmet title='Recommendations' />
      <div className='c-pageWrapper'>
        <div className='pageWrapper_header'>
          <div className='container-fluid'>
            <Row type='flex' align='middle'>
              <Col md={12}>{getMonthlyPublishedStatus()}</Col>
              <Col md={12} className='text-right'>
                <Tooltip
                  placement='top'
                  title={userRoles?.canCreateReco ? 'Create recommendation' : 'Don`t have create access'}
                >
                  <Button
                    type='primary'
                    key='recommendation'
                    onClick={() => setShowAddRecommModal(true)}
                    disabled={!userRoles?.canCreateReco}
                  >
                    <PlusOutlined />
                    Add Recommendation
                  </Button>
                </Tooltip>
              </Col>
            </Row>
          </div>
        </div>
        <div className='pageWrapper_body'>
          <div className='container-fluid'>
            <div className='c-insightContainer'>
              {/* ---- Recomm  Fetching */}
              {recommendationList?.recommendation_fetching_status === 'fetching' && <Spin />}
              {/* ---- Recomm  Fetching End */}

              {/* ---- Recomm  Fetching */}
              {recommendationList?.recommendation_fetching_status === 'success' && (
                <Tabs
                  defaultActiveKey='suggested_by_aarya'
                  type='card'
                  size='small'
                  animated={true}
                  tabBarExtraContent={getMonthlyPublishedStatus()}
                  onChange={onTabChange}
                  activeKey={activeTab}
                >
                  <TabPane
                    tab={
                      <>
                        <PlusCircleOutlined />
                        Suggested Recommendations
                      </>
                    }
                    key='suggested_by_aarya'
                  >
                    <List
                      itemLayout='vertical'
                      size='large'
                      pagination={{
                        onChange: page => {},
                        pageSize: 5,
                        hideOnSinglePage: true
                      }}
                      dataSource={recommendationList?.data}
                      renderItem={item => (
                        <RecommendationItem item={item} key={item.id} onDiscard={handleDiscard} activeTab={activeTab} />
                      )}
                    />
                  </TabPane>
                  <TabPane
                    tab={
                      <>
                        <PlusCircleOutlined />
                        Draft
                      </>
                    }
                    key='draft'
                  >
                    <List
                      itemLayout='vertical'
                      size='large'
                      pagination={{
                        onChange: page => {},
                        pageSize: 5,
                        hideOnSinglePage: true
                      }}
                      dataSource={recommendationList?.data}
                      renderItem={item => <RecommendationItem item={item} key={item.id} activeTab={activeTab} />}
                    />
                  </TabPane>
                  <TabPane
                    tab={
                      <>
                        <InfoCircleOutlined />
                        Pending Approval
                      </>
                    }
                    key='pending_approval'
                  >
                    <List
                      itemLayout='vertical'
                      size='large'
                      pagination={{
                        onChange: page => {},
                        pageSize: 5,
                        hideOnSinglePage: true
                      }}
                      dataSource={recommendationList?.data}
                      renderItem={item => <RecommendationItem item={item} key={item.id} activeTab={activeTab} />}
                    />
                  </TabPane>
                  <TabPane
                    tab={
                      <>
                        <CheckCircleOutlined />
                        Published
                      </>
                    }
                    key='published'
                  >
                    {renderPublishedChildTabs(recommendationList, publishedChildTabsInitialState)}
                  </TabPane>
                  <TabPane
                    tab={
                      <>
                        <CloseCircleOutlined />
                        Rejected By SuperAdmin
                      </>
                    }
                    key='rejected_by_superadmin'
                  >
                    <List
                      itemLayout='vertical'
                      size='large'
                      pagination={{
                        onChange: page => {},
                        pageSize: 5,
                        hideOnSinglePage: true
                      }}
                      dataSource={recommendationList?.data}
                      renderItem={item => <RecommendationItem item={item} key={item.id} activeTab={activeTab} />}
                    />
                  </TabPane>
                </Tabs>
              )}
              {/* ---- Recomm  Fetching End */}
            </div>
            {/* ----- Create new Recomm */}
            <Modal
              title='Add new recommendation'
              visible={showAddRecommModal}
              onCancel={() => {
                dispatch(clearLinkedInsight());
                setShowAddRecommModal(false);
                Modal.destroyAll();
              }}
              afterClose={() => setLinkedInsights([])}
              width='90%'
              style={{ top: 20 }}
              footer={false}
              destroyOnClose={true}
            >
              <Suspense
                fallback={
                  <div className='c-spinnerContainer'>
                    <Spin />
                  </div>
                }
              >
                <CreateRecommendation
                  linkedInsights={linkedInsights}
                  setLinkedInsights={setLinkedInsights}
                  setShowAddRecommModal={setShowAddRecommModal}
                  history={props.history}
                  activeTab={activeTab}
                />
              </Suspense>
            </Modal>

            {/* TODO: Lazy load discard */}
            <Modal
              title='Discard a Recommendation'
              visible={isDiscardClicked}
              destroyOnClose={true}
              onOk={onDiscardOk}
              onCancel={onDiscardCancel}
            >
              <Suspense
                fallback={
                  <div className='c-spinnerContainer'>
                    <Spin />
                  </div>
                }
              >
                <DiscardRecommendation reasons={discardReasons} ref={discardedRecommendation} />
              </Suspense>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

export default compose(withRouter)(Recommendations);
