import { message } from 'antd';
import Api from './recommendationTemplateApi';
import { recommendationTemplateConstants } from './recommendationTemplateConstant';

const recommendationTemplate = new Api('aarya');

export const getRecommendationTemplate = () => {
  return async dispatch => {
    try {
      dispatch({ type: recommendationTemplateConstants.GET_RECOMMENDATION_TEMPLATE_REQUEST });
      const { data } = await recommendationTemplate.getRecommendationTemplate();

      dispatch({
        type: recommendationTemplateConstants.GET_RECOMMENDATION_TEMPLATE_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: recommendationTemplateConstants.GET_RECOMMENDATION_TEMPLATE_ERROR
      });
    }
  };
};

export const getSingleRecommendationTemplate = recommendationTemplateId => {
  return async dispatch => {
    try {
      dispatch({ type: recommendationTemplateConstants.GET_SINGLE_RECOMMENDATION_TEMPLATE_REQUEST });
      const { data } = await recommendationTemplate.getSingleRecommendationTemplate(recommendationTemplateId);
      dispatch({
        type: recommendationTemplateConstants.GET_SINGLE_RECOMMENDATION_TEMPLATE_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: recommendationTemplateConstants.GET_SINGLE_RECOMMENDATION_TEMPLATE_ERROR
      });
    }
  };
};

// -- Create recommendation template
export const createRecommendationTemplate = recommendationTemplateData => {
  return async dispatch => {
    dispatch({ type: recommendationTemplateConstants.CREATE_RECOMMENDATION_TEMPLATE_REQUEST });
    try {
      await recommendationTemplate.createRecommendationTemplate(recommendationTemplateData);
      const { data } = await recommendationTemplate.getRecommendationTemplate();
      dispatch({
        type: recommendationTemplateConstants.CREATE_RECOMMENDATION_TEMPLATE_SUCCESS,
        payload: data
      });
      message.success('Recommendation Template Created Successfully');
    } catch (error) {
      dispatch({
        type: recommendationTemplateConstants.CREATE_RECOMMENDATION_TEMPLATE_ERROR
      });
    }
  };
};

// -- update recommendation template
export const updateRecommendationTemplate = (recommendationTemplateData, recommendationId) => {
  return async dispatch => {
    dispatch({ type: recommendationTemplateConstants.UPDATE_RECOMMENDATION_TEMPLATE_REQUEST });
    try {
      await recommendationTemplate.updateRecommendationTemplate(recommendationTemplateData, recommendationId);
      const { data } = await recommendationTemplate.getRecommendationTemplate();
      dispatch({
        type: recommendationTemplateConstants.UPDATE_RECOMMENDATION_TEMPLATE_SUCCESS,
        payload: data
      });
      message.success('Recommendation Template Updated Successfully');
    } catch (error) {
      dispatch({
        type: recommendationTemplateConstants.UPDATE_RECOMMENDATION_TEMPLATE_ERROR
      });
    }
  };
};

// -- delete recommendation template
export const deleteRecommendationTemplate = recommendationId => {
  return async dispatch => {
    dispatch({ type: recommendationTemplateConstants.DELETE_RECOMMENDATION_TEMPLATE_REQUEST });
    try {
      await recommendationTemplate.deleteRecommendationTemplate(recommendationId);
      const { data } = await recommendationTemplate.getRecommendationTemplate();
      dispatch({
        type: recommendationTemplateConstants.GET_RECOMMENDATION_TEMPLATE_SUCCESS,
        payload: data
      });
      message.success('Recommendation template Deleted successfully');
    } catch (error) {
      dispatch({
        type: recommendationTemplateConstants.DELETE_RECOMMENDATION_TEMPLATE_ERROR
      });
    }
  };
};

// -- clear recommendation template
export const clearRecommendationTemplate = () => {
  return async dispatch => {
    try {
      dispatch({
        type: recommendationTemplateConstants.CLEAR_RECOMMENDATION_TEMPLATE
      });
    } catch (error) {
      return false;
    }
  };
};
