import Api from '../api';

export default class RevenueCalculator extends Api {
  getIncrementalityChecker = async () => {
    try {
      const data = await this.get(`/incrementality/checker/`);
      return data;
    } catch (error) {
      return error;
    }
  };

  getPredictedRevenue = async formObject => {
    const stringifiedFormObject = JSON.stringify(formObject);
    try {
      const data = await this.get(`incrementality/predictor?features=${stringifiedFormObject}`);
      return data;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
}
