import { recommendationTemplateConstants } from './recommendationTemplateConstant';
const initialState = {
  templates: {},
  singleRecommendationTemplate: {}
};

export default function RecommendationTemplate(state = initialState, action) {
  switch (action.type) {
    // --- get recommendation template
    case recommendationTemplateConstants.GET_RECOMMENDATION_TEMPLATE_REQUEST:
      return {
        ...state,
        templates: { recommendation_template_fetching_status: 'fetching' }
      };
    case recommendationTemplateConstants.GET_RECOMMENDATION_TEMPLATE_SUCCESS:
      return {
        ...state,
        templates: { recommendation_template_fetching_status: 'success', data: action.payload }
      };
    case recommendationTemplateConstants.GET_RECOMMENDATION_TEMPLATE_ERROR:
      return {
        ...state,
        templates: { recommendation_template_fetching_status: 'fail' }
      };

    // --- get single Recommendation template
    case recommendationTemplateConstants.GET_SINGLE_RECOMMENDATION_TEMPLATE_REQUEST:
      return {
        ...state,
        singleRecommendationTemplate: { single_recommendation_template_fetching_status: 'fetching' }
      };
    case recommendationTemplateConstants.GET_SINGLE_RECOMMENDATION_TEMPLATE_SUCCESS:
      return {
        ...state,

        singleRecommendationTemplate: {
          templateData: action.payload,
          single_recommendation_template_fetching_status: 'success'
        }
      };
    case recommendationTemplateConstants.GET_SINGLE_RECOMMENDATION_TEMPLATE_ERROR:
      return {
        ...state,
        singleRecommendationTemplate: { single_recommendation_template_fetching_status: 'fail' }
      };

    // --- create  Recommendation template
    case recommendationTemplateConstants.CREATE_RECOMMENDATION_TEMPLATE_REQUEST:
      return {
        ...state,
        templates: { create_recommendation_template_fetching_status: 'fetching' }
      };
    case recommendationTemplateConstants.CREATE_RECOMMENDATION_TEMPLATE_SUCCESS:
      return {
        ...state,
        templates: {
          data: [...action.payload],
          create_recommendation_template_fetching_status: 'success'
        }
      };
    case recommendationTemplateConstants.CREATE_RECOMMENDATION_TEMPLATE_ERROR:
      return {
        ...state,
        templates: { create_recommendation_template_fetching_status: 'fail' }
      };

    // --- update  Recommendation template
    case recommendationTemplateConstants.UPDATE_RECOMMENDATION_TEMPLATE_REQUEST:
      return {
        ...state,
        templates: { update_recommendation_template_fetching_status: 'fetching' }
      };
    case recommendationTemplateConstants.UPDATE_RECOMMENDATION_TEMPLATE_SUCCESS:
      return {
        ...state,
        templates: {
          data: [...action.payload],
          update_recommendation_template_fetching_status: 'success'
        }
      };
    case recommendationTemplateConstants.UPDATE_RECOMMENDATION_TEMPLATE_ERROR:
      return {
        ...state,
        templates: { update_recommendation_template_fetching_status: 'fail' }
      };

    case recommendationTemplateConstants.CLEAR_RECOMMENDATION_TEMPLATE:
      return {
        ...state,
        singleRecommendationTemplate: {}
      };
    default:
      return state;
  }
}
