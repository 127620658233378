import { dashboardConstants } from './constants';

const initialState = {
  all_stores_stats_table: {},
  recently_onboarded_store: {},
  all_stores_summary_cards: {
    list: []
  },
  all_store_todo: {},
  top_bottom_stores: {
    top: {},
    bottom: {}
  },
  userCreateStatus: {},
  business_unit: {}
};

export default function Dashboard(state = initialState, action) {
  switch (action.type) {
    // --- Get All store summery data
    case dashboardConstants.GET_DASHBOARD_STAT_REQUEST:
      return {
        ...state,
        all_stores_stats_table: { data_fetching_status: 'fetching' }
      };
    case dashboardConstants.GET_DASHBOARD_STAT_SUCCESS:
      return {
        ...state,
        all_stores_stats_table: { data_fetching_status: 'success', store_wise_summary: [...action.payload] }
      };
    case dashboardConstants.GET_DASHBOARD_STAT_ERROR:
      return {
        ...state,
        all_stores_stats_table: { data_fetching_status: 'fail' }
      };
    case dashboardConstants.GET_RECENT_ONBOARDED_STORES:
      return {
        ...state,
        recently_onboarded_store: { data_fetching_status: 'success', ...action.payload }
      };

    // --- GetTodo
    case dashboardConstants.GET_DASHBOARD_TODO_REQUEST:
      return {
        ...state,
        all_store_todo: { data_fetching_status: 'fetching' }
      };
    case dashboardConstants.GET_DASHBOARD_TODO_SUCCESS:
      return {
        ...state,
        all_store_todo: { data_fetching_status: 'success', ...action.payload }
      };
    case dashboardConstants.GET_DASHBOARD_TODO_ERROR:
      return {
        ...state,
        all_store_todo: { data_fetching_status: 'fail' }
      };

    // --- Get All top
    case dashboardConstants.GET_TOP_STORES_REQUEST:
      return {
        ...state,
        top_bottom_stores: { ...state.top_bottom_stores, top: { data_fetching_status: 'fetching' } }
      };
    case dashboardConstants.GET_TOP_STORES_SUCCESS:
      return {
        ...state,
        top_bottom_stores: { ...state.top_bottom_stores, top: { data_fetching_status: 'success', ...action.payload } }
      };
    case dashboardConstants.GET_TOP_STORES_ERROR:
      return {
        ...state,
        top_bottom_stores: { ...state.top_bottom_stores, top: { data_fetching_status: 'fail' } }
      };

    // --- Get All Bottom
    case dashboardConstants.GET_BOTTOM_STORES_REQUEST:
      return {
        ...state,
        top_bottom_stores: { ...state.top_bottom_stores, bottom: { data_fetching_status: 'fetching' } }
      };
    case dashboardConstants.GET_BOTTOM_STORES_SUCCESS:
      return {
        ...state,
        top_bottom_stores: {
          ...state.top_bottom_stores,
          bottom: { data_fetching_status: 'success', ...action.payload }
        }
      };
    case dashboardConstants.GET_BOTTOM_STORES_ERROR:
      return {
        ...state,
        top_bottom_stores: { ...state.top_bottom_stores, bottom: { data_fetching_status: 'fail' } }
      };

    // --- Get All Reports
    case dashboardConstants.GET_STORES_REPORTS_REQUEST:
      return {
        ...state,
        stores_reports: { ...state.stores_reports, reports: { data_fetching_status: 'fetching' } }
      };
    case dashboardConstants.GET_STORES_REPORTS_SUCCESS:
      return {
        ...state,
        stores_reports: {
          ...state.stores_reports,
          reports: { data_fetching_status: 'success', ...action.payload }
        }
      };
    case dashboardConstants.GET_STORES_REPORTS_ERROR:
      return {
        ...state,
        stores_reports: { ...state.stores_reports, reports: { data_fetching_status: 'fail' } }
      };

    // --- BU list
    case dashboardConstants.GET_BUSINESS_UNIT_REQUEST:
      return {
        ...state,
        business_unit: { data_fetching_status: 'fetching' }
      };
    case dashboardConstants.GET_BUSINESS_UNIT_SUCCESS:
      return {
        ...state,
        business_unit: { data_fetching_status: 'success', ...action.payload }
      };
    case dashboardConstants.GET_BUSINESS_UNIT_ERROR:
      return {
        ...state,
        business_unit: { data_fetching_status: 'fail' }
      };

    // --- selected BU list
    case dashboardConstants.SET_SELECTED_BUSINESS_UNIT:
      return {
        ...state,
        business_unit: { ...state.business_unit, selected: action.payload }
      };

    // --- Create User
    case dashboardConstants.CREATE_NEW_USER_REQUEST:
      return {
        ...state,
        userCreateStatus: { create_user_status: 'fetching' }
      };
    case dashboardConstants.CREATE_NEW_USER_SUCCESS:
      return {
        ...state,
        userCreateStatus: { create_user_status: 'success' }
      };
    case dashboardConstants.CREATE_NEW_USER_ERROR:
      return {
        ...state,
        userCreateStatus: { create_user_status: 'fail' }
      };
    case dashboardConstants.CREATE_NEW_USER_CLEAR:
      return {
        ...state,
        userCreateStatus: {}
      };

    // --- All cards data
    case dashboardConstants.GET_SUMMARY_REQUEST:
      return {
        ...state,
        all_stores_summary_cards: {
          data_fetching_status: 'fetching',
          ...state.all_stores_summary_cards
        }
      };
    case dashboardConstants.GET_SUMMARY_SUCCESS:
      return {
        ...state,
        all_stores_summary_cards: {
          data_fetching_status: 'success',
          list: [...action.payload]
        }
      };
    case dashboardConstants.GET_SUMMARY_ERROR:
      return {
        ...state,
        all_stores_summary_cards: {
          data_fetching_status: 'fail',
          ...state.all_stores_summary_cards
        }
      };

    // --- Clear dashboard
    case dashboardConstants.CLEAR_DASHBOARD_STAT:
      return {
        ...state,
        all_stores_stats_table: {},
        all_stores_summary_cards: {
          list: []
        },
        all_store_todo: {},
        top_bottom_stores: {
          top: {},
          bottom: {}
        }
      };

    // --- Clear dashboard
    case dashboardConstants.CLEAR_BUSINESS_UNITS:
      return {
        ...state,
        business_unit: {}
      };

    default:
      return state;
  }
}
