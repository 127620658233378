import { all, put, call, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { message } from 'antd';
import { isEmpty as _isEmpty } from 'lodash-es';
import { track, actions as TRACKER_ACTION } from 'redux/tracker';

import Api from './api';
import actions from './actions';
import integrationActions from 'redux/Integration/actions';
import { USER_ROLES } from '../../utilityFunctions/InitialStates';

const auth = new Api('aarya/auth');
const auth2 = new Api('polaris/auth');
const org = new Api('polaris/organisation');

export function* login(action) {
  yield put({
    type: actions.SET_DATA,
    payload: {
      loading: true
    }
  });
  var { email, password } = action.payload;
  try {
    var userData = yield call(auth.login, email, password);
    yield put({
      type: actions.LOGIN_SUCCESS,
      payload: {
        userData
      }
    });
    track(TRACKER_ACTION.login, userData);
  } catch (error) {
    if (error.response && error.response.data && error.response.data.detail) {
      yield put({
        type: actions.LOGIN_FAIL,
        payload: {
          errorMessage: error.response.data.detail
        }
      });
    } else {
      yield put({
        type: actions.LOGIN_FAIL,
        payload: {
          errorMessage: 'Could not log you in. Please check Email or Password.'
        }
      });
    }
  }
}

export function* switchOrg(action) {
  yield put({
    type: actions.TOGGLE_LOADING
  });
  yield put({
    type: actions.SET_DATA,
    payload: {
      loading: true
    }
  });

  try {
    var userData = yield call(org.switchOrg, action.payload.id);
    yield put({
      type: actions.LOGIN_SUCCESS,
      payload: {
        userData
      }
    });
    yield track(TRACKER_ACTION.orgChange, userData);
    window.location.reload();
  } catch (error) {
    yield put(push('/'));
    yield message.error(error.response.data.detail);
  } finally {
    yield put({
      type: actions.SET_DATA,
      payload: {
        loading: false
      }
    });
  }
}

export function* getOrgs() {
  try {
    var orgList = yield call(org.getOrgs);
    yield put({
      type: actions.SET_DATA,
      payload: {
        organisations: orgList
      }
    });
  } catch (error) {
    console.log(error);
  }
}

const setUserPermissions = userType => {
  if (userType?.orbNotificationManager) return USER_ROLES.NOTIFICATION_MANAGER;
  else if (userType?.orbSuperAdmin) return USER_ROLES.SUPER_ADMIN;
  else if (userType?.orbAdmin) return USER_ROLES.ADMIN;
  else if (!userType?.orbAdmin && !userType?.orbSuperAdmin) return USER_ROLES.USER;
  else return USER_ROLES.DEFAULT;
};

export function* loginSuccess(action) {
  var { userData } = action.payload;
  const userRoles = userData && setUserPermissions(userData);

  yield put({
    type: actions.SET_DATA,
    payload: {
      userData,
      loading: false,
      authorized: true,
      loginError: null,
      userRoles
    }
  });

  yield put({
    type: actions.GET_ORGS
  });
  yield put({ type: integrationActions.GET_BASE_VISUALISATIONS });
}

export function* loginFail(action) {
  var { errorMessage } = action.payload;
  yield put({
    type: actions.SET_DATA,
    payload: {
      userData: null,
      authToken: null,
      loading: false,
      authorized: false,
      loginError: errorMessage
    }
  });
}

export function* logout(action) {
  try {
    if (!_isEmpty(action?.payload?.user)) {
      yield auth2.logout();
    }
    yield put({
      type: actions.SET_DATA,
      payload: {
        userData: null,
        authorized: false,
        loading: false,
        authToken: null,
        loginError: null
      }
    });
    if (action.payload) track(TRACKER_ACTION.logout, action.payload.user);
    yield auth2.afterlogout();
    yield put(push('/system/login'));
  } catch (error) {
    message.error('Failed to Logout, Please try again!');
  }
}

export function* CHECK_LOGIN() {
  yield put({
    type: actions.SET_DATA,
    payload: {
      loading: true
    }
  });
  var authorized = yield auth.checkLogin();
  if (authorized) {
    var userData = auth.getUserFromStorage();
    yield put({
      type: actions.LOGIN_SUCCESS,
      payload: {
        userData
      }
    });
  } else {
    yield put({
      type: actions.LOGIN_FAIL,
      payload: {
        errorMessage: ''
      }
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, login),
    takeEvery(actions.LOGIN_FAIL, loginFail),
    takeEvery(actions.LOGIN_SUCCESS, loginSuccess),
    takeEvery(actions.LOGOUT, logout),
    takeEvery(actions.GET_ORGS, getOrgs),
    takeEvery(actions.SWITCH_ORG, switchOrg),
    CHECK_LOGIN() // run once on app load to fetch menu data
  ]);
}
