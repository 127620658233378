import React from 'react';
import { Helmet } from 'react-helmet';

class System404 extends React.Component {
  render() {
    return (
      <div>
        <Helmet title='Page 404' />
        Page Not Found
      </div>
    );
  }
}

export default System404;
