import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import Dashboard from './Dashboard/reducer';
import Notification from './InsightNotificationThunk/thunkReducer';
import Insights from './Insights/reducers';
import Integration from './Integration/reducers';
import IntegrationsNew from './IntegrationThunk/reducer';
import MetaData from './MetaData/metadataReducer';
import RecommendationTemplate from './RecommendationTemplate/recommendationTemplateReducer';
import Recommendations from './RecommendationThunk/reducer';
import User from './User/reducers';
import InsightThunkReducer from './InsightsThunk/reducer';
import { spotlightReducer } from 'pages/Spotlight/spotlight.reducer';
import NotificationReducer from './Notification/NotificationReducer';
import revenueCalculatorReducer from './RevenueCalculator/RevenueCalculatorReducer';
export default history =>
  combineReducers({
    router: connectRouter(history),
    User,
    Integration,
    Insights,
    Notification,
    Recommendations,
    IntegrationsNew,
    Dashboard,
    MetaData,
    RecommendationTemplate,
    InsightThunkReducer,
    Spotlight: spotlightReducer,
    NotificationReducer,
    revenueCalculator: revenueCalculatorReducer
  });
