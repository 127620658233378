const insightThunkAction = {
  GET_ALERT_MESSAGE_REQUEST: 'insights/GET_ALERT_MESSAGE_REQUEST',
  GET_ALERT_MESSAGE_SUCCESS: 'insights/GET_ALERT_MESSAGE_SUCCESS',
  GET_ALERT_MESSAGE_ERROR: 'insights/GET_ALERT_MESSAGE_ERROR',

  GET_METRICS_LIST_REQUEST: 'insights/GET_METRICS_LIST_REQUEST',
  GET_METRICS_LIST_SUCCESS: 'insights/GET_METRICS_LIST_SUCCESS',
  GET_METRICS_LIST_ERROR: 'insights/GET_METRICS_LIST_ERROR',

  GET_DIMENSION_LIST_REQUEST: 'insights/GET_DIMENSION_LIST_REQUEST',
  GET_DIMENSION_LIST_SUCCESS: 'insights/GET_DIMENSION_LIST_SUCCESS',
  GET_DIMENSION_LIST_ERROR: 'insights/GET_DIMENSION_LIST_ERROR',

  GET_INSIGHT_ALERT_MESSAGE_CLOSABLE: 'insights/GET_INSIGHT_ALERT_MESSAGE_CLOSABLE'
};

export default insightThunkAction;
