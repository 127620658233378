export const insightsConstants = {
  GET_INSIGHTS_BY_ID_REQUEST: 'insight/GET_INSIGHTS_BY_ID_REQUEST',
  GET_INSIGHTS_BY_ID_SUCCESS: 'insight/GET_INSIGHTS_BY_ID_SUCCESS',
  GET_INSIGHTS_BY_ID_ERROR: 'insight/GET_INSIGHTS_BY_ID_ERROR',

  GET_CHANNEL_REQUEST: 'insight/GET_CHANNEL_REQUEST',
  GET_CHANNEL_SUCCESS: 'insight/GET_CHANNEL_SUCCESS',
  GET_CHANNEL_ERROR: 'insight/GET_CHANNEL_ERROR',

  GET_SUB_CHANNEL_REQUEST: 'insight/GET_SUB_CHANNEL_REQUEST',
  GET_SUB_CHANNEL_SUCCESS: 'insight/GET_SUB_CHANNEL_SUCCESS',
  GET_SUB_CHANNEL_ERROR: 'insight/GET_SUB_CHANNEL_ERROR',

  CLEAR_SINGLE_INSIGHTS: 'insight/CLEAR_SINGLE_INSIGHTS'
};
