import { integrationsConstants } from './constants';

const initialState = {
  baseVisualisations: {}
};

export default function integrationsNew(state = initialState, action) {
  switch (action.type) {
    // --- Get Integrations
    case integrationsConstants.GET_INTEGRATIONS_REQUEST:
      return {
        ...state,
        baseVisualisations: { ...state.baseVisualisations, integration_fetching_status: 'fetching' }
      };
    case integrationsConstants.GET_INTEGRATIONS_SUCCESS:
      return {
        ...state,
        baseVisualisations: {
          ...state.baseVisualisations,
          integration_fetching_status: 'success',
          ...action.payload
        }
      };
    case integrationsConstants.GET_INTEGRATIONS_ERROR:
      return {
        ...state,
        baseVisualisations: {
          ...state.baseVisualisations,
          integration_fetching_status: 'fail'
        }
      };
    default:
      return state;
  }
}
