import { insightsConstants } from './constants';

const initialState = {
  insights: {
    singleInsight: {
      loading: true,
      error: false
    }
  },
  channel: {},
  subChannel: {}
};

const InsightThunkReducer = (state = initialState, action) => {
  switch (action.type) {
    // Get insights by id
    case insightsConstants.GET_INSIGHTS_BY_ID_REQUEST:
      return {
        ...state,
        insights: {
          singleInsight: {
            loading: true,
            error: false
          }
        }
      };

    case insightsConstants.GET_INSIGHTS_BY_ID_SUCCESS:
      return {
        ...state,
        insights: {
          singleInsight: {
            ...action.payload,
            loading: false,
            error: false
          }
        }
      };

    case insightsConstants.GET_INSIGHTS_BY_ID_ERROR:
      return {
        ...state,
        insights: {
          singleInsight: {
            loading: false,
            error: true
          }
        }
      };

    // Get channel
    case insightsConstants.GET_CHANNEL_REQUEST:
      return {
        ...state,
        channel: {}
      };

    case insightsConstants.GET_CHANNEL_SUCCESS:
      return {
        ...state,
        channel: {
          ...action.payload
        }
      };

    case insightsConstants.GET_CHANNEL_ERROR:
      return {
        ...state,
        channel: {}
      };

    // Get sub channel
    case insightsConstants.GET_SUB_CHANNEL_REQUEST:
      return {
        ...state,
        subChannel: {}
      };

    case insightsConstants.GET_SUB_CHANNEL_SUCCESS:
      return {
        ...state,
        subChannel: {
          ...action.payload
        }
      };

    case insightsConstants.GET_SUB_CHANNEL_ERROR:
      return {
        ...state,
        subChannel: {}
      };

    case insightsConstants.CLEAR_SINGLE_INSIGHTS:
      return {
        ...state,
        insights: {
          singleInsight: {
            loading: false,
            error: false
          }
        }
      };

    default:
      return state;
  }
};

export default InsightThunkReducer;
