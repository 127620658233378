import React from 'react';
import { Result } from 'antd';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { isEmpty as _isEmpty } from 'lodash';
import { ReactComponent as NoInsights } from '../../assets/images/search_data.svg';
import { ReactComponent as ErrorInInsights } from '../../assets/images/no-data-found.svg';
import './SMResult.scss';

const SMResult = props => {
  const { type, message, size } = props;

  if (!_isEmpty(type)) {
    // --- Loader type :: Loading
    if (type === 'loader') {
      return (
        <div className={`c-notifiContainer ${size ? size : ''}`}>
          <Result
            icon={<Loading3QuartersOutlined className='notifi_spinner' spin />}
            title={!_isEmpty(message) && <span className='notifi_message'>{message}</span>}
            {...props}
          />
        </div>
      );
    }

    // --- Loader type :: Blank / Empty data
    if (type === 'empty') {
      return (
        <div className={`c-notifiContainer container-type-empty ${size ? size : ''}`}>
          <Result
            icon={<NoInsights className='notifi_icon' />}
            title={!_isEmpty(message) && <span className='notifi_message'>{message}</span>}
            {...props}
          />
        </div>
      );
    }

    // --- Loader type :: error
    if (type === 'error') {
      return (
        <div className={`c-notifiContainer ${size ? size : ''}`}>
          <Result
            icon={<ErrorInInsights className='notifi_icon' />}
            title={!_isEmpty(message) && <span className='notifi_message'>{message}</span>}
            {...props}
          />
        </div>
      );
    }
  }

  //   --- Else Return Spinner
  return <Result icon={<Loading3QuartersOutlined className='spinner' spin />} />;
};

export default SMResult;
