import moment from 'moment';
import { getCompareRangeFromDate } from './utils';

export default {
  currentRanges: {
    Today: [moment(), moment()],
    'Last 7 Days': [moment().subtract(1, 'week'), moment()],
    'This Week': [moment().startOf('week'), moment().endOf('week')],
    'Last 30 Days': [moment().subtract(1, 'months'), moment().subtract(1, 'd')],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last 1 Year': [moment().subtract(1, 'years'), moment().subtract(1, 'd')],
    'This Year': [moment().startOf('year'), moment().endOf('year')]
  },
  compareRanges(dateRange) {
    return {
      Today: [moment(), moment()],
      'Last Week': [moment().startOf('week').subtract(1, 'week'), moment().endOf('week').subtract(1, 'week')],
      'Last Month': [moment().startOf('month').subtract(1, 'months'), moment().endOf('month').subtract(1, 'months')],
      'Last Year': [moment().startOf('year').subtract(1, 'years'), moment().endOf('year').subtract(1, 'years')],
      Previous: getCompareRangeFromDate(dateRange)
    };
  }
};
