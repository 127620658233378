import { Button, Checkbox, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { isEmpty as _isEmpty, includes as _includes, find as _find } from 'lodash-es';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createNotification,
  getOrganisationList,
  getOrganisationVertical,
  getUserFromOrganisation,
  updateNotification
} from '../../redux/Notification/NotificationAction';
import { ADD_CONFIG, EDIT_CONFIG, VIEW_CONFIG, calculateCounts } from '../../utilityFunctions/utilityFunctions';
import SMResult from 'components/SMResult/SMResult';

const formItemLayout = {
  labelCol: {
    span: 24
  },
  wrapperCol: {
    span: 22
  }
};
const PRIORITY = [
  { key: 'high', value: 'High' },
  { key: 'medium', value: 'Medium' },
  { key: 'low', value: 'Low' }
];
const MODULE = [
  { value: 'insights', label: 'Insights', route: '/insights' },
  { value: 'recommendations', label: 'Recommendation', route: '/recommendations' },
  { value: 'reports', label: 'Reports', route: '/report' },
  { value: 'dataflow', label: 'Data Flow', route: '/dashboard' },
  { value: 'segments', label: 'Segments', route: '/segments' }
];
const USER_TYPE = [
  {
    key: 'internal',
    value: 'Internal'
  },
  {
    key: 'external',
    value: 'External'
  },
  {
    key: 'all',
    value: 'Both'
  }
];

const Crud = props => {
  const { singleNotificationData, type, closeModal } = props;
  const [form] = Form.useForm();
  const [storeType, setStoreTypes] = useState([]);
  const [userType, setUserType] = useState();
  const [stores, setStores] = useState([]);
  const [users, setUsers] = useState([]);
  const [usersFlag, setUsersFlag] = useState([]);
  const [storesFlag, setStoresFlag] = useState([]);
  const [formInitialValue, setFormInitialValue] = useState({});
  const [navigateTo, setNavigateTo] = useState();
  const [isNavigate, setIsNavigate] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const dispatch = useDispatch();

  const STORE_TYPES = useSelector(state => state?.NotificationReducer?.organisationVertical);
  const STORES = useSelector(state => state?.NotificationReducer?.organisationList?.data);
  const USERS = useSelector(state => state?.NotificationReducer?.usersFromOrganisation?.data);

  const onSubmit = values => {
    const finalScheduleDate = moment(values?.scheduled_at).utc().format();
    if (type === EDIT_CONFIG) {
      const updatedData = {
        ...values,
        is_active: isActive,
        is_navigate: isNavigate,
        users: users,
        stores: stores,
        scheduled_at: finalScheduleDate
      };
      dispatch(updateNotification(updatedData, singleNotificationData?.id));
    } else {
      const resultData = {
        ...values,
        is_active: isActive,
        is_navigate: isNavigate,
        users: users,
        stores: stores,
        scheduled_at: finalScheduleDate
      };
      dispatch(createNotification(resultData));
    }
    closeModal(false);
  };

  const onChangeStoreType = value => {
    dispatch(getOrganisationList(value));
    setStoreTypes(value);
    setStores([]);
    form.setFieldsValue({
      stores: []
    });
  };

  const onChangeUserType = value => {
    if (type === EDIT_CONFIG) {
      dispatch(getUserFromOrganisation(stores, value));
      setUsers([]);
      setUsersFlag([]);
    } else {
      let captureStoreValue = [];
      const allStore = STORES.map(singleData => singleData?.organisation_id);
      if (_includes(stores, 'all')) {
        captureStoreValue = allStore || [];
        setStores(allStore);
      } else {
        captureStoreValue = stores || [];
        setStores(stores);
      }
      dispatch(getUserFromOrganisation(captureStoreValue, value));
    }
    setUsers([]);
    setUsersFlag([]);
    setUserType(value);
  };

  const onModuleChange = value => {
    const result = _find(MODULE, singleModule => singleModule?.value === value);
    setNavigateTo(result?.route);
  };

  const onStoreChange = storeValue => {
    if (type === EDIT_CONFIG) {
      if (_includes(storeValue, 'all')) {
        const allStore = STORES.map(singleData => singleData?.organisation_id);
        dispatch(getUserFromOrganisation(allStore, singleNotificationData?.user_type));
        setStores(allStore);
      } else {
        dispatch(getUserFromOrganisation(storeValue, singleNotificationData?.user_type));
        setStores(storeValue);
      }
      setStoresFlag(storeValue);
      form.setFieldsValue({
        users: []
      });
      setUsers([]);
      setUsersFlag([]);
    } else {
      if (!_isEmpty(storeValue) && !_isEmpty(userType) && userType !== undefined) {
        dispatch(getUserFromOrganisation(storeValue, userType));
      }
      setStoresFlag(storeValue);
      setStores(storeValue);
      form.setFieldsValue({
        users: []
      });
      setUsers([]);
      setUsersFlag([]);
    }
  };

  const onUsersChange = value => {
    if (_includes(value, 'all')) {
      const allUsers = USERS.map(singleData => singleData?.id);
      setUsers(allUsers);
      setUsersFlag(value);
    } else {
      setUsers(value);
      setUsersFlag(value);
    }
  };

  useEffect(() => {
    setFormInitialValue({
      store_types: singleNotificationData?.store_types,
      stores: singleNotificationData?.stores,
      user_type: singleNotificationData?.user_type,
      users: singleNotificationData?.users,
      priority: singleNotificationData?.priority,
      title: singleNotificationData?.title,
      description: singleNotificationData?.description,
      is_active: singleNotificationData?.is_active,
      module: singleNotificationData?.module,
      scheduled_at: moment(singleNotificationData?.scheduled_at),
      is_navigate: singleNotificationData?.is_navigate,
      navigate_to: singleNotificationData?.navigate_to
    });
  }, [singleNotificationData]);

  useEffect(() => {
    form.setFieldsValue({
      navigate_to: navigateTo
    });
  }, [form, navigateTo]);

  useEffect(() => {
    if (type === EDIT_CONFIG || type === VIEW_CONFIG) {
      setUsers(singleNotificationData?.users);
      setStores(singleNotificationData?.stores);
    }
  }, [singleNotificationData, type]);

  useEffect(() => {
    form.setFieldsValue({
      users: []
    });
  }, [form, userType]);

  useEffect(() => {
    dispatch(getOrganisationVertical());
    if ((type === VIEW_CONFIG || type === EDIT_CONFIG) && !_isEmpty(singleNotificationData)) {
      setIsActive(singleNotificationData?.is_active);
      dispatch(getOrganisationList(singleNotificationData?.store_types));
      dispatch(getUserFromOrganisation(singleNotificationData?.stores, singleNotificationData?.user_type));
    }
    setIsNavigate(singleNotificationData?.is_navigate);
  }, [dispatch, singleNotificationData, type]);

  useEffect(() => {
    form.setFieldsValue(formInitialValue);
  }, [formInitialValue, form]);

  if (type !== ADD_CONFIG && _isEmpty(singleNotificationData)) {
    return <SMResult type='loader' message='Loading data' />;
  }

  return (
    <Form
      {...formItemLayout}
      form={form}
      layout={formItemLayout}
      onFinish={onSubmit}
      initialValues={type === EDIT_CONFIG || type === VIEW_CONFIG ? formInitialValue : {}}
    >
      <Row align='top' gutter={24}>
        <Col md={24}>
          <div className='c-formElement'>
            <Form.Item
              name={'store_types'}
              label='Store Type'
              rules={[
                {
                  required: true,
                  message: 'Please Select Store Type'
                },
                () => ({
                  validator(_, value) {
                    if (!value || value?.length <= 50) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Ensure this field has no more than 50 characters.'));
                  }
                })
              ]}
            >
              <Select
                status={STORE_TYPES?.organisation_vertical_fetching_status === 'error' ? 'error' : ''}
                allowClear
                mode='multiple'
                onChange={storeValue => onChangeStoreType(storeValue)}
                disabled={type === VIEW_CONFIG}
                value={storeType}
                placeholder='Select a Store type'
                showSearch
                name='store_types'
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
              >
                <Select.Option disabled={!_isEmpty(storeType) && !storeType.includes('all')} value={'all'} key={'all'}>
                  All
                </Select.Option>
                {!_isEmpty(STORE_TYPES?.data) &&
                  STORE_TYPES?.data?.map(singleStoreType => {
                    return (
                      <Select.Option
                        disabled={storeType.includes('all')}
                        value={singleStoreType?.id}
                        key={singleStoreType?.id}
                      >
                        {singleStoreType?.label}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </div>
        </Col>
      </Row>

      <Row align='top' gutter={24}>
        <Col md={24}>
          <div className='c-formElement'>
            <Form.Item
              name={'stores'}
              label={`Stores - ${calculateCounts(stores, STORES)}`}
              rules={[
                {
                  required: true,
                  message: 'Please Select Store/Stores'
                }
              ]}
            >
              <Select
                allowClear
                mode='multiple'
                disabled={(type === VIEW_CONFIG || type === ADD_CONFIG) && !storeType.length ? true : false}
                showSearch
                name='stores'
                placeholder='Select a Stores'
                onChange={storeValue => onStoreChange(storeValue)}
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
              >
                <Select.Option
                  disabled={!_isEmpty(storesFlag) && !storesFlag.includes('all')}
                  value={'all'}
                  key={'all'}
                >
                  All
                </Select.Option>
                {!_isEmpty(STORES) &&
                  STORES?.map(singleStore => {
                    return (
                      <Select.Option
                        disabled={storesFlag.includes('all')}
                        value={singleStore?.organisation_id}
                        key={singleStore?.organisation_id}
                      >
                        {singleStore?.organisation_set?.name}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </div>
        </Col>
      </Row>

      <Row align='top' gutter={24}>
        <Col md={24}>
          <div className='c-formElement'>
            <Form.Item
              name={'user_type'}
              label='User Type'
              rules={[
                {
                  required: true,
                  message: 'Please Select User Type'
                },
                () => ({
                  validator(_, value) {
                    if (!value || value?.length <= 20) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Ensure this field has no more than 20 characters.'));
                  }
                })
              ]}
            >
              <Select
                disabled={
                  (type === VIEW_CONFIG && stores?.length) || (type === ADD_CONFIG && !stores?.length) ? true : false
                }
                name='user_type'
                placeholder='Select a User type'
                value={userType}
                onChange={userValue => onChangeUserType(userValue)}
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
              >
                {!_isEmpty(USER_TYPE) &&
                  USER_TYPE?.map(singleUserType => {
                    return (
                      <Select.Option value={singleUserType?.key} key={singleUserType?.key}>
                        {singleUserType?.value}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </div>
        </Col>
      </Row>

      <Row align='top' gutter={24}>
        <Col md={24}>
          <div className='c-formElement'>
            <Form.Item
              name={'users'}
              label={`Users - ${calculateCounts(users, USERS)}`}
              rules={[
                {
                  required: true,
                  message: 'Please Select Users'
                }
              ]}
            >
              <Select
                allowClear
                mode='multiple'
                disabled={(type === VIEW_CONFIG || type === ADD_CONFIG) && !userType ? true : false}
                showSearch
                placeholder='Select a Users'
                name='users'
                onChange={value => onUsersChange(value)}
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
              >
                <Select.Option disabled={!_isEmpty(usersFlag) && !usersFlag.includes('all')} value={'all'} key={'all'}>
                  All
                </Select.Option>
                {!_isEmpty(USERS) &&
                  USERS?.map(singleUser => {
                    return (
                      <Select.Option disabled={usersFlag.includes('all')} value={singleUser?.id} key={singleUser?.id}>
                        {singleUser?.username}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </div>
        </Col>
      </Row>

      <Row align='top' gutter={24}>
        <Col md={24}>
          <div className='c-formElement'>
            <Form.Item
              name={'priority'}
              label='Priority'
              rules={[
                {
                  required: true,
                  message: 'Please Select Priority'
                },
                () => ({
                  validator(_, value) {
                    if (!value || value?.length <= 50) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Ensure this field has no more than 50 characters.'));
                  }
                })
              ]}
            >
              <Select placeholder='Select a priority' disabled={type === VIEW_CONFIG} name='priority'>
                {!_isEmpty(PRIORITY) &&
                  PRIORITY?.map(singlePriority => {
                    return (
                      <Select.Option value={singlePriority?.key} key={singlePriority?.key}>
                        {singlePriority?.value}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </div>
        </Col>
      </Row>

      <Row align='top' gutter={24}>
        <Col md={24}>
          <div className='c-formElement'>
            <Form.Item
              name={'title'}
              label='Title'
              rules={[
                {
                  required: true,
                  message: 'Please Enter Title'
                },
                () => ({
                  validator(_, value) {
                    if (!value || value?.length <= 255) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Ensure this field has no more than 255 characters.'));
                  }
                })
              ]}
            >
              <Input placeholder='Enter a title' disabled={type === VIEW_CONFIG} name='title' />
            </Form.Item>
          </div>
        </Col>
      </Row>

      <Row align='top' gutter={24}>
        <Col md={24}>
          <div className='c-formElement'>
            <Form.Item
              name={'description'}
              label='Description'
              rules={[
                {
                  required: true,
                  message: 'Please Enter Description'
                },
                () => ({
                  validator(_, value) {
                    if (!value || value?.length <= 1000) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Ensure this field has no more than 1000 characters.'));
                  }
                })
              ]}
            >
              <Input.TextArea placeholder='Enter a description' disabled={type === VIEW_CONFIG} name='description' />
            </Form.Item>
          </div>
        </Col>
      </Row>

      <Row align='top' gutter={24}>
        <Col md={12}>
          <div className='c-formElement'>
            <Form.Item
              name={'module'}
              label='Module'
              rules={[
                {
                  required: true,
                  message: 'Please Select Module'
                },
                () => ({
                  validator(_, value) {
                    if (!value || value?.length <= 50) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Ensure this field has no more than 50 characters.'));
                  }
                })
              ]}
            >
              <Select
                placeholder='Select a module'
                disabled={type === VIEW_CONFIG}
                showSearch
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                name='module'
                onChange={value => onModuleChange(value)}
              >
                {!_isEmpty(MODULE) &&
                  MODULE?.map(singleModule => {
                    return (
                      <Select.Option value={singleModule?.value} key={singleModule?.value}>
                        {singleModule?.label}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </div>
        </Col>
        <Col md={12}>
          <div className='c-formElement'>
            <Form.Item
              name={'scheduled_at'}
              label='Scheduled At (IST)'
              rules={[
                {
                  required: true,
                  message: 'Please Enter Scheduled At'
                }
              ]}
            >
              <DatePicker
                disabled={type === VIEW_CONFIG}
                showTime={{ format: 'HH:mm:ss' }}
                name='scheduled_at'
                format={'YYYY-MM-DD HH:mm:ss'}
                style={{ width: '100%' }}
                disabledDate={current => !current || current.isSameOrBefore(moment().subtract(1, 'days'))}
              />
            </Form.Item>
          </div>
        </Col>
      </Row>

      <Row align='top' gutter={24}>
        <Col md={4}>
          <div className='c-formElement'>
            <Form.Item name={'is_active'} label='Is Active'>
              <Checkbox
                disabled={type === VIEW_CONFIG}
                name='is_active'
                checked={isActive}
                onChange={value => setIsActive(!isActive)}
              />
            </Form.Item>
          </div>
        </Col>
        <Col md={4}>
          <div className='c-formElement'>
            <Form.Item name={'is_navigate'} label='Is Navigate' valuePropName='checked'>
              <Checkbox
                disabled={type === VIEW_CONFIG}
                checked={isNavigate}
                onChange={value => setIsNavigate(!isNavigate)}
                name='is_navigate'
              />
            </Form.Item>
          </div>
        </Col>
        {isNavigate && (
          <Col md={16}>
            <div className='c-formElement'>
              <Form.Item
                name={'navigate_to'}
                label='Navigate To'
                rules={[
                  {
                    required: true,
                    message: 'Please Enter Navigate To'
                  },
                  () => ({
                    validator(_, value) {
                      if (!value || value?.length <= 2000) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Ensure this field has no more than 2000 characters.'));
                    }
                  })
                ]}
              >
                <Input placeholder='Enter Navigation path' disabled name='navigate_to' />
              </Form.Item>
            </div>
          </Col>
        )}
      </Row>

      <Form.Item className='center'>
        {type !== EDIT_CONFIG && type !== VIEW_CONFIG && (
          <Button type='primary' htmlType='submit' style={{ marginRight: '50px', marginTop: '10px' }}>
            Create Notification
          </Button>
        )}
        {type === EDIT_CONFIG && (
          <Button type='primary' htmlType='submit' style={{ marginRight: '50px', marginTop: '10px' }}>
            Update Notification
          </Button>
        )}

        <Button htmlType='button' onClick={() => closeModal(false)}>
          Close
        </Button>
      </Form.Item>
    </Form>
  );
};
export default Crud;
