import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Select, Button } from 'antd';
import { Link } from 'react-router-dom';
import UserActions from 'redux/User/actions';
import grassLogo from '../../../assets/images/graas_white.svg';
import './RevenueCalculator.scss';

const RevenueCalculator = () => {
  const { organisations, userData } = useSelector(state => state.User);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const switchOrg = id =>
    dispatch({
      type: UserActions.SWITCH_ORG,
      payload: {
        id
      }
    });

  return (
    <div className='container'>
      <div className='header'>
        <div className='logo'>
          <img src={grassLogo} alt='logo' />
        </div>
        <h1 className='heading'>Incrementality Calculator</h1>
      </div>
      <div className='form-component'>
        <p className='text'>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Perferendis repellendus illo maiores voluptates
          tempore! Provident, obcaecati! Optio, veritatis quidem autem ipsa minima obcaecati illum ipsam, dolorum
          dolores similique voluptate quos?
        </p>
        <Form className='form-container' form={form} initialValues={{ select_store: userData.organisationId }}>
          <Form.Item label='select store' name='select_store'>
            <Select
              label='select store'
              name='select_store'
              onSelect={id => switchOrg(id)}
              style={{ color: '#ffffff' }}
              dropdownClassName='select-store-dropdown'
            >
              {organisations &&
                organisations.map(option => {
                  return (
                    <Select.Option key={option.id} value={option.id}>
                      {option.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Form>

        <Link to={`/revenue-calculator/${userData.organisationId}`}>
          <Button type='link' shape='round' size='large' className='btn-next'>
            Next
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default RevenueCalculator;
