import React from 'react';
import { Modal, Input } from 'antd';

const { TextArea } = Input;

export default class RejectModal extends React.Component {
  state = {
    rejectFeedback: ''
  };

  rejectRecommendation = () => {
    const { onReject, itemId } = this.props;
    if (typeof onReject === 'function') {
      onReject(itemId, this.state.rejectFeedback);
    }
    this.setState({
      rejectFeedback: ''
    });
  };

  updateFeedback = e => this.setState({ rejectFeedback: e.target.value });

  render() {
    const { placeholder } = this.props;
    return (
      <Modal {...this.props} onOk={this.rejectRecommendation}>
        <TextArea
          value={this.state.rejectFeedback}
          onChange={this.updateFeedback}
          placeholder={placeholder}
          autoSize={{ minRows: 4, maxRows: 5 }}
        />
      </Modal>
    );
  }
}
