import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateOrganisation } from '../../../redux/MetaData/metadataAction';
import { isEmpty as _isEmpty } from 'lodash';
import { INDUSTRY, MARKETING_OWNERS, PLATFORM, SIZE, TYPE, CURRENCY } from '../../../utilityFunctions/utilityFunctions';
import '../Form.scss';
import { PlusOutlined } from '@ant-design/icons';

const OrganisationForm = props => {
  const { setOrganisationToggleForm, singleMetaData } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const organisationId = useSelector(state => state.User?.userData?.organisationId);
  const [validOrderStatus, setValidOrderStatus] = useState({
    list: !_isEmpty(singleMetaData?.valid_order_status) ? singleMetaData.valid_order_status : ['Other'],
    name: ''
  });
  const [sourceChannel, setSourceChannel] = useState({
    list: !_isEmpty(singleMetaData?.valid_source_channels) ? singleMetaData.valid_source_channels : ['Other'],
    name: ''
  });

  const formInitialValue = {
    store_code: singleMetaData?.store_code,
    name: singleMetaData?.name,
    industry: singleMetaData?.industry,
    size: singleMetaData?.size,
    type: singleMetaData?.type,
    platform: singleMetaData?.platform,
    vertical: singleMetaData?.vertical,
    marketing_owners: singleMetaData?.marketing_owners,
    go_live_date: !_isEmpty(singleMetaData?.go_live_date) ? moment(singleMetaData?.go_live_date) : '',
    currency: singleMetaData?.currency,
    recommendation_publish_monthly_limit: singleMetaData?.recommendation_publish_monthly_limit,
    valid_order_status: singleMetaData?.valid_order_status,
    valid_source_channels: singleMetaData?.valid_source_channels
  };
  const formItemLayout = {
    labelCol: {
      span: 24
    },
    wrapperCol: {
      span: 22
    }
  };
  const onUpdate = values => {
    const updatedData = {
      store_code: values?.store_code,
      name: values?.name,
      industry: values?.industry,
      size: values?.size,
      type: values?.type,
      platform: values?.platform,
      vertical: values?.vertical,
      marketing_owners: values?.marketing_owners,
      go_live_date: moment(moment(values?.go_live_date).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')),
      currency: values?.currency,
      recommendation_publish_monthly_limit: values?.recommendation_publish_monthly_limit,
      is_test: false,
      is_active: true,
      valid_order_status: values?.valid_order_status,
      valid_source_channels: values?.valid_source_channels
    };
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to update this organisation detail ?',
      okText: 'YES',
      cancelText: 'CANCEL',
      onOk() {
        dispatch(updateOrganisation(updatedData, organisationId));
        setOrganisationToggleForm(false);
      }
    });
  };
  return (
    <Form onFinish={onUpdate} {...formItemLayout} form={form} layout={formItemLayout} initialValues={formInitialValue}>
      <Row gutter={24}>
        <Col md={12}>
          <div className='c-formElement'>
            <Form.Item name={'store_code'} label='Store Code'>
              <Input disabled name='store_code' />
            </Form.Item>
          </div>
        </Col>

        <Col md={12}>
          <div className='c-formElement'>
            <Form.Item
              name={'name'}
              label='Store Name'
              rules={[
                {
                  required: true,
                  message: 'Please Enter Store Name'
                },
                () => ({
                  validator(_, value) {
                    if (!value || value.length <= 255) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Ensure this field has no more than 255 characters.'));
                  }
                })
              ]}
            >
              <Input name='name' />
            </Form.Item>
          </div>
        </Col>
      </Row>

      <Row align='top' gutter={24}>
        <Col md={12}>
          <div className='c-formElement'>
            <Form.Item
              name={'industry'}
              label='Industry'
              rules={[
                {
                  required: true,
                  message: 'Please Select Industry'
                },
                () => ({
                  validator(_, value) {
                    if (!value || value.length <= 255) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Ensure this field has no more than 255 characters.'));
                  }
                })
              ]}
            >
              <Select showSearch name='industry' placeholder='Select Industry'>
                {INDUSTRY &&
                  INDUSTRY?.map((singleSize, index) => {
                    return (
                      <Select.Option key={index} value={singleSize?.key}>
                        {singleSize?.title}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </div>
        </Col>

        <Col md={12}>
          <div className='c-formElement'>
            <Form.Item
              name={'size'}
              label='Size'
              rules={[
                {
                  required: true,
                  message: 'Please Select Size'
                },
                () => ({
                  validator(_, value) {
                    if (!value || value.length <= 50) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Ensure this field has no more than 50 characters.'));
                  }
                })
              ]}
            >
              <Select showSearch name='size' placeholder='Select Organisation Size'>
                {SIZE &&
                  SIZE?.map((singleSize, index) => {
                    return (
                      <Select.Option key={index} value={singleSize?.key}>
                        {singleSize?.title}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </div>
        </Col>
      </Row>

      <Row align='top' gutter={24}>
        <Col md={12}>
          <div className='c-formElement'>
            <Form.Item
              name={'type'}
              label='Type'
              rules={[
                {
                  required: true,
                  message: 'Please Select Type'
                },
                () => ({
                  validator(_, value) {
                    if (!value || value.length <= 50) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Ensure this field has no more than 50 characters.'));
                  }
                })
              ]}
            >
              <Select showSearch name='type' placeholder='Select Organisation Type'>
                {TYPE &&
                  TYPE?.map((singleType, index) => {
                    return (
                      <Select.Option key={index} value={singleType?.key}>
                        {singleType?.title}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </div>
        </Col>

        <Col md={12}>
          <div className='c-formElement'>
            <Form.Item
              name={'platform'}
              label='Platform'
              rules={[
                () => ({
                  validator(_, value) {
                    if (!value || value.length <= 50) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Ensure this field has no more than 50 characters.'));
                  }
                })
              ]}
            >
              <Select disabled showSearch name='platform' placeholder='Select Organisation Platform'>
                {PLATFORM &&
                  PLATFORM?.map((singlePlatform, index) => {
                    return (
                      <Select.Option key={index} value={singlePlatform?.key}>
                        {singlePlatform?.title}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </div>
        </Col>
      </Row>

      <Row align='top' gutter={24}>
        <Col md={12}>
          <div className='c-formElement'>
            <Form.Item
              name={'vertical'}
              label='Vertical'
              rules={[
                {
                  required: true,
                  message: 'Please Select Vertical'
                },
                () => ({
                  validator(_, value) {
                    if (!value || value.length <= 50) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Ensure this field has no more than 50 characters.'));
                  }
                })
              ]}
            >
              <Select showSearch name='vertical' placeholder='Select Organisation Vertical'>
                {props?.verticalData &&
                  props?.verticalData?.map((singleVertical, index) => {
                    return (
                      <Select.Option key={index} value={singleVertical?.id}>
                        {singleVertical?.label}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </div>
        </Col>

        <Col md={12}>
          <div className='c-formElement'>
            <Form.Item
              name={'marketing_owners'}
              label='Marketing Owners'
              rules={[
                {
                  required: true,
                  message: 'Please Select Marketing Owners'
                },
                () => ({
                  validator(_, value) {
                    if (!value || value.length <= 50) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Ensure this field has no more than 50 characters.'));
                  }
                })
              ]}
            >
              <Select showSearch name='marketing_owners' placeholder='Select Organisation Marketing Owner'>
                {MARKETING_OWNERS &&
                  MARKETING_OWNERS?.map((singleMarketingOwner, index) => {
                    return (
                      <Select.Option key={index} value={singleMarketingOwner?.key}>
                        {singleMarketingOwner?.title}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </div>
        </Col>
      </Row>

      <Row align='top' gutter={24}>
        <Col md={8}>
          <div className='c-formElement'>
            <Form.Item
              name={'go_live_date'}
              label='Go Live Date'
              rules={[
                {
                  required: true,
                  message: 'Please Enter Go Live Date'
                }
              ]}
            >
              <DatePicker name='go_live_date' style={{ width: '100%' }} />
            </Form.Item>
          </div>
        </Col>

        <Col md={8}>
          <div className='c-formElement'>
            <Form.Item
              name={'currency'}
              label='Currency'
              rules={[
                {
                  required: true,
                  message: 'Please Select Currency'
                },
                () => ({
                  validator(_, value) {
                    if (!value || value.length <= 55) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Ensure this field has no more than 55 characters.'));
                  }
                })
              ]}
            >
              <Select disabled showSearch name='currency' placeholder='Select Currency for Organisation'>
                {CURRENCY &&
                  CURRENCY?.map((singleCurrency, index) => {
                    return (
                      <Select.Option key={index} value={singleCurrency?.code}>
                        {singleCurrency?.name} - {singleCurrency.symbol}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </div>
        </Col>

        <Col md={8}>
          <div className='c-formElement'>
            <Form.Item
              name={'recommendation_publish_monthly_limit'}
              label='Recommendation Publish Monthly Limit'
              rules={[
                {
                  required: true,
                  message: 'Please Enter Recommendation Publish monthly Limit'
                },
                () => ({
                  validator(_, value) {
                    if (!value || value <= 2147483647) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        `Ensure this field has no more than 2147483647
                         characters.`
                      )
                    );
                  }
                })
              ]}
            >
              <InputNumber min={1} name='recommendation_publish_monthly_limit' style={{ width: '90%' }} />
            </Form.Item>
          </div>
        </Col>
      </Row>

      <Row align='top' gutter={24}>
        <Col md={12}>
          <div className='c-formElement'>
            <Form.Item
              name={'valid_order_status'}
              label='Order Status'
              rules={[
                {
                  required: true,
                  message: 'Please Enter Order Status'
                }
              ]}
            >
              <Select
                placeholder='Select Order Status'
                style={{ width: '100%' }}
                mode='multiple'
                dropdownRender={menu => (
                  <>
                    {menu}
                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                      <Input
                        style={{ flex: 'auto' }}
                        value={validOrderStatus?.name}
                        onChange={e => setValidOrderStatus({ ...validOrderStatus, name: e.target.value })}
                      />
                      <Button
                        style={{
                          display: 'block',
                          cursor: 'pointer'
                        }}
                        type='link'
                        onClick={() =>
                          setValidOrderStatus({
                            list: [...validOrderStatus?.list, validOrderStatus?.name || `New item`],
                            name: ''
                          })
                        }
                      >
                        <PlusOutlined /> Add item
                      </Button>
                    </div>
                  </>
                )}
              >
                {validOrderStatus?.list.map((item, index) => (
                  <Select.Option key={index} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </Col>

        {singleMetaData?.platform === 'shopify' && (
          <Col md={12}>
            <div className='c-formElement'>
              <Form.Item
                name={'valid_source_channels'}
                label='Source Channel'
                rules={[
                  {
                    required: true,
                    message: 'Please Enter Source Channel'
                  }
                ]}
              >
                <Select
                  placeholder='Select Source Channel'
                  style={{ width: '100%' }}
                  mode='multiple'
                  dropdownRender={menu => (
                    <>
                      {menu}
                      <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                        <Input
                          style={{ flex: 'auto' }}
                          value={sourceChannel?.name}
                          onChange={e => setSourceChannel({ ...sourceChannel, name: e.target.value })}
                        />
                        <Button
                          style={{
                            display: 'block',
                            cursor: 'pointer'
                          }}
                          type='link'
                          onClick={() =>
                            setSourceChannel({
                              list: [...sourceChannel?.list, sourceChannel?.name || `New item`],
                              name: ''
                            })
                          }
                        >
                          <PlusOutlined /> Add item
                        </Button>
                      </div>
                    </>
                  )}
                >
                  {sourceChannel?.list.map((item, index) => (
                    <Select.Option key={index} value={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </Col>
        )}
      </Row>
      <Form.Item className='center'>
        <Button type='primary' htmlType='submit' style={{ marginRight: '10px', marginTop: '10px' }}>
          Update Organisation
        </Button>
        <Button htmlType='button' onClick={() => setOrganisationToggleForm(false)}>
          Close
        </Button>
      </Form.Item>
    </Form>
  );
};
export default OrganisationForm;
