import moment from 'moment';

export function stringToMoment(rangeStrings) {
  return rangeStrings ? [moment(rangeStrings[0]), moment(rangeStrings[1])] : null;
}

export function getCompareRangeFromDate(rangeStrings) {
  let diff = moment(rangeStrings[1]).diff(moment(rangeStrings[0]));
  return [
    moment(rangeStrings[0]).subtract(diff).subtract(1, 'd'),
    moment(rangeStrings[1]).subtract(diff).subtract(1, 'd')
  ];
}

export function momentToString(dates) {
  return dates ? [dates[0].format('YYYY-MM-DD'), dates[1].format('YYYY-MM-DD')] : null;
}
