import { Button, Col, Form, Input, Row, Modal, Select } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { createDssMetadataVariables } from '../../../redux/MetaData/metadataAction';
import React from 'react';
import '../Form.scss';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

const DSSMetadataCreateVariableForm = props => {
  const { setDssMetadataCreateVariableToggleForm, dateForMonthPicker, setAddFormDisable } = props;
  const [form] = Form.useForm();
  const userData = useSelector(state => state.User?.userData);
  const dispatch = useDispatch();

  const formItemLayout = {
    labelCol: {
      span: 24
    },
    wrapperCol: {
      span: 22
    }
  };

  const onCreate = values => {
    var finalVariableData = {
      ...values,
      updated_at: moment(moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'))._i,
      updated_by: userData?.username,
      is_active: true,
      created_at: moment(moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'))._i,
      create_by: userData?.username,
      report_date: moment(dateForMonthPicker).startOf('month').format('YYYY-MM-DD')
    };

    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to Create Variable Metadata ?',
      okText: 'YES',
      cancelText: 'CANCEL',
      onOk() {
        dispatch(
          createDssMetadataVariables(
            finalVariableData,
            moment(dateForMonthPicker).startOf('month').format('YYYY-MM-DD')
          )
        );
        setDssMetadataCreateVariableToggleForm(false);
        setAddFormDisable(false);
      }
    });
  };

  return (
    <Form layout={formItemLayout} {...formItemLayout} form={form} onFinish={onCreate} {...formItemLayout}>
      <Row align='top' gutter={24}>
        <Col md={12}>
          <div className='c-formElement'>
            <Form.Item
              name={'net_mtd_gmv'}
              label='Net MTD GMV'
              rules={[
                {
                  required: true,
                  message: 'Please Enter Net MTD GMV'
                }
              ]}
            >
              <Input name='net_mtd_gmv' type='number' />
            </Form.Item>
          </div>
        </Col>

        <Col md={12}>
          <div className='c-formElement'>
            <Form.Item
              name={'actual_ad_spends'}
              label='Actual Ad Spends'
              rules={[
                {
                  required: true,
                  message: 'Please Enter Actual Ad Spends'
                }
              ]}
            >
              <Input name='actual_ad_spends' type='number' />
            </Form.Item>
          </div>
        </Col>
      </Row>

      <Row align='top' gutter={24}>
        <Col md={12}>
          <div className='c-formElement'>
            <Form.Item
              name={'actual_rev_share_billing'}
              label='Actual Rev Share Billing'
              rules={[
                {
                  required: true,
                  message: 'Please Enter Actual Rev Share Billing'
                }
              ]}
            >
              <Input name='actual_rev_share_billing' type='number' />
            </Form.Item>
          </div>
        </Col>

        <Col md={12}>
          <div className='c-formElement'>
            <Form.Item
              name={'actual_gmv'}
              label='Actual GMV'
              rules={[
                {
                  required: true,
                  message: 'Please Enter Actual GMV'
                }
              ]}
            >
              <Input name='actual_gmv' type='number' />
            </Form.Item>
          </div>
        </Col>
      </Row>

      <Row align='top' gutter={24}>
        <Col md={12}>
          <div className='c-formElement'>
            <Form.Item
              name={'spend_target'}
              label='Spend Target'
              rules={[
                {
                  required: true,
                  message: 'Please Enter Spend target'
                }
              ]}
            >
              <Input name='spend_target' type='number' />
            </Form.Item>
          </div>
        </Col>

        <Col md={12}>
          <div className='c-formElement'>
            <Form.Item
              name={'gross_gmv_target'}
              label='Gross GMV Target'
              rules={[
                {
                  required: true,
                  message: 'Please Enter Gross GMV Target'
                }
              ]}
            >
              <Input name='gross_gmv_target' type='number' />
            </Form.Item>
          </div>
        </Col>
      </Row>

      <Row align='top' gutter={24}>
        <Col md={12}>
          <div className='c-formElement'>
            <Form.Item
              name={'spends_via_stz'}
              label='Spends Via STZ'
              rules={[
                {
                  required: true,
                  message: 'Please Enter Spends via STZ'
                }
              ]}
            >
              <Select showSearch name='spends_via_stz' placeholder='Select Options'>
                <Select.Option key={'1'} value={'Yes'}>
                  {'Yes'}
                </Select.Option>
                <Select.Option key={'2'} value={'No'}>
                  {'No'}
                </Select.Option>
              </Select>
            </Form.Item>
          </div>
        </Col>

        <Col md={12}>
          <div className='c-formElement'>
            <Form.Item
              name={'multiplier'}
              label='Multiplier (%)'
              rules={[
                {
                  required: true,
                  message: 'Please Enter Multiplier'
                }
              ]}
            >
              <Input name='multiplier' type='number' />
            </Form.Item>
          </div>
        </Col>
      </Row>

      <Row align='top' gutter={24}>
        <Col md={12}>
          <div className='c-formElement'>
            <Form.Item
              name={'rev_share'}
              label='Rev Share (%)'
              rules={[
                {
                  required: true,
                  message: 'Please Enter Rev Share'
                }
              ]}
            >
              <Input name='rev_share' type='number' />
            </Form.Item>
          </div>
        </Col>

        <Col md={12}>
          <div className='c-formElement'>
            <Form.Item
              name={'fixed_fee'}
              label='Fixed Fee'
              rules={[
                {
                  required: true,
                  message: 'Please Enter Fixed Fee'
                }
              ]}
            >
              <Input name='fixed_fee' type='number' />
            </Form.Item>
          </div>
        </Col>
      </Row>

      <Row align='top' gutter={24}>
        <Col md={12}>
          <div className='c-formElement'>
            <Form.Item
              name={'cred_spends'}
              label='CRED Spends'
              rules={[
                {
                  required: true,
                  message: 'Please Enter CRED Spends'
                }
              ]}
            >
              <Input name='cred_spends' type='number' />
            </Form.Item>
          </div>
        </Col>

        <Col md={12}>
          <div className='c-formElement'>
            <Form.Item
              name={'min_billing'}
              label='Min Billing'
              rules={[
                {
                  required: true,
                  message: 'Please Enter Min Billing'
                }
              ]}
            >
              <Input name='min_billing' type='number' />
            </Form.Item>
          </div>
        </Col>
      </Row>

      <Row align='top' gutter={24}>
        <Col md={12}>
          <div className='c-formElement'>
            <Form.Item
              name={'gpay_spends'}
              label='GPAY Spends'
              rules={[
                {
                  required: true,
                  message: 'Please Enter GPAY Spends'
                }
              ]}
            >
              <Input name='gpay_spends' type='number' />
            </Form.Item>
          </div>
        </Col>

        <Col md={12}>
          <div className='c-formElement'>
            <Form.Item
              name={'mail_spends'}
              label='Mail Spends'
              rules={[
                {
                  required: true,
                  message: 'Please Enter Mail Spends'
                }
              ]}
            >
              <Input name='mail_spends' type='number' />
            </Form.Item>
          </div>
        </Col>
      </Row>

      <Row align='top' gutter={24}>
        <Col md={12}>
          <div className='c-formElement'>
            <Form.Item
              name={'whatsapp_spends'}
              label='Whatsapp Spends'
              rules={[
                {
                  required: true,
                  message: 'Please Enter Whatsapp Spends'
                }
              ]}
            >
              <Input name='whatsapp_spends' type='number' />
            </Form.Item>
          </div>
        </Col>

        <Col md={12}>
          <div className='c-formElement'>
            <Form.Item
              name={'other_spends'}
              label='Other Spends'
              rules={[
                {
                  required: true,
                  message: 'Please Enter Other Spends'
                }
              ]}
            >
              <Input name='other_spends' type='number' />
            </Form.Item>
          </div>
        </Col>
      </Row>

      <Row align='top' gutter={24}>
        <Col md={12}>
          <div className='c-formElement'>
            <Form.Item
              name={'additional_billing'}
              label='Additional Billing'
              rules={[
                {
                  required: true,
                  message: 'Please Enter Additional Billing'
                }
              ]}
            >
              <Input name='additional_billing' type='number' />
            </Form.Item>
          </div>
        </Col>

        <Col md={12}>
          <div className='c-formElement'>
            <Form.Item
              name={'whatsapp_biling'}
              label='Whatsapp Billing'
              rules={[
                {
                  required: true,
                  message: 'Please Enter Whatsapp Billing'
                }
              ]}
            >
              <Input name='whatsapp_biling' type='number' />
            </Form.Item>
          </div>
        </Col>
      </Row>

      <Row align='top' gutter={24}>
        <Col md={12}>
          <div className='c-formElement'>
            <Form.Item
              name={'actual_total_billing'}
              label='Actual Total Billing'
              rules={[
                {
                  required: true,
                  message: 'Please Enter Actual Total Billing'
                }
              ]}
            >
              <Input name='actual_total_billing' type='number' />
            </Form.Item>
          </div>
        </Col>

        <Col md={12}>
          <div className='c-formElement'>
            <Form.Item
              name={'billing_target'}
              label='Billing Target'
              rules={[
                {
                  required: true,
                  message: 'Please Enter Billing Target'
                }
              ]}
            >
              <Input name='billing_target' type='number' />
            </Form.Item>
          </div>
        </Col>
      </Row>

      <Row align='top' gutter={24}>
        <Col md={12}>
          <div className='c-formElement'>
            <Form.Item
              name={'sipl_target'}
              label='SIPL Target'
              rules={[
                {
                  required: true,
                  message: 'Please Enter SIPL Target'
                }
              ]}
            >
              <Input name='sipl_target' type='number' />
            </Form.Item>
          </div>
        </Col>
        <Col md={12}>
          <div className='c-formElement'>
            <Form.Item
              name={'team_lead_name'}
              label='Team Lead Name'
              rules={[
                {
                  required: true,
                  message: 'Please Enter Team Lead Name'
                }
              ]}
            >
              <Input name='team_lead_name' />
            </Form.Item>
          </div>
        </Col>
      </Row>

      <Row align='top' gutter={24}>
        <Col md={12}>
          <div className='c-formElement'>
            <Form.Item
              name={'account_lead'}
              label='A/C Lead'
              rules={[
                {
                  required: true,
                  message: 'Please Enter Account Lead'
                }
              ]}
            >
              <Input name='account_lead' />
            </Form.Item>
          </div>
        </Col>

        <Col md={12}>
          <div className='c-formElement'>
            <Form.Item
              name={'store_status'}
              label='Store Status'
              rules={[
                {
                  required: true,
                  message: 'Please Select Store Status'
                }
              ]}
            >
              <Select showSearch name='store_status' placeholder='Select Store Status'>
                <Select.Option key={'1'} value={'New'}>
                  {'New'}
                </Select.Option>
                <Select.Option key={'2'} value={'Existing'}>
                  {'Existing'}
                </Select.Option>
                <Select.Option key={'3'} value={'Closed'}>
                  {'Closed'}
                </Select.Option>
                <Select.Option key={'4'} value={'Inactive'}>
                  {'Inactive'}
                </Select.Option>
              </Select>
            </Form.Item>
          </div>
        </Col>
      </Row>

      <Row align='top' gutter={24}>
        <Col md={12}>
          <div className='c-formElement'>
            <Form.Item
              name={'business_model'}
              label='Business Model'
              rules={[
                {
                  required: true,
                  message: 'Please Select Business Model'
                }
              ]}
            >
              <Select showSearch name='business_model' placeholder='Select Business Model'>
                <Select.Option key={'1'} value={'KickStarter'}>
                  {'KickStarter'}
                </Select.Option>
                <Select.Option key={'2'} value={'Accelerator'}>
                  {'Accelerator'}
                </Select.Option>
                <Select.Option key={'3'} value={'Agency'}>
                  {'Agency'}
                </Select.Option>
                <Select.Option key={'4'} value={'GP Only'}>
                  {'GP Only'}
                </Select.Option>
                <Select.Option key={'5'} value={'Private Beta'}>
                  {'Private Beta'}
                </Select.Option>
              </Select>
            </Form.Item>
          </div>
        </Col>
      </Row>

      <Form.Item className='center'>
        <Button type='primary' htmlType='submit' style={{ marginRight: '10px', marginTop: '10px' }}>
          Add DSS Metadata Variables
        </Button>
        <Button
          htmlType='button'
          onClick={() => {
            setDssMetadataCreateVariableToggleForm(false);
            setAddFormDisable(false);
          }}
        >
          Close
        </Button>
      </Form.Item>
    </Form>
  );
};

export default DSSMetadataCreateVariableForm;
