import Api from '../api';

export default class Integrations extends Api {
  getBaseVisualisations = () =>
    new Promise(async (resolve, reject) => {
      try {
        var { data } = await this.get(`/visualisations/`);
        var baseVisualisations = this.formatBaseVisualisationResponse(data);
        resolve(baseVisualisations);
      } catch (error) {
        reject(error);
      }
    });

  /**
   * TODO : Make More generic by making map.
   */
  formatBaseVisualisationResponse(visualisations) {
    return visualisations.map(visualisation => {
      var visType = {
        title: visualisation.name,
        id: visualisation.id,
        key: visualisation.code,
        type: visualisation.type, 
        query: visualisation.query
      };
      var visList = [];
      if (visualisation.base_visualisation_set && visualisation.base_visualisation_set.length) {
        visList = visualisation.base_visualisation_set.map(vis => {
          return {
            title: vis.name,
            id: vis.id,
            key: vis.code,
            type: vis.type,
            query: vis.query,
            integrations: vis.properties.integrations ? vis.properties.integrations : [],
            isSlow: vis.slow_query,
            time_granularity: vis.properties.time_resolution_availability
          };
        });
      }
      visType.children = visList;
      return visType;
    });
  }
}
