import { ArrowLeftOutlined } from '@ant-design/icons';
import { isEmpty as _isEmpty } from 'lodash-es';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import InsightDetails from '../../components/InsightDetails/InsightDetails';
import SMResult from '../../components/SMResult/SMResult';
import { clearSingleInsight, getInsightById } from '../../redux/InsightsThunk/action';
import './InsightDetailWrapper.scss';

const InsightDetailWrapper = props => {
  const { insightId, hideBackButton } = props;
  const dispatch = useDispatch();
  const {
    loading: singleInsightLoading,
    error: singleInsightError,
    results
  } = useSelector(state => state?.InsightThunkReducer?.insights?.singleInsight);
  const singleInsight = results?.[0];
  const { id } = useParams();
  const singleInsightId = id || insightId;

  useEffect(() => {
    dispatch(getInsightById(singleInsightId));
    return () => {
      dispatch(clearSingleInsight());
    };
  }, [dispatch, singleInsightId]);

  if (singleInsightLoading) return <SMResult type='loader' message='Loading Insight detail' />;

  if (singleInsightError) return <SMResult type='empty' message='No Data Available' />;

  return (
    <>
      <div className='c-pageWrapper p0'>
        <div className='pageWrapper_body'>
          <div className='container-fluid'>
            {!hideBackButton && (
              <div className='clearfix mt-3'>
                <Link to='/spotlight' className='backButton'>
                  <ArrowLeftOutlined />
                  Back
                </Link>
              </div>
            )}
            {!_isEmpty(singleInsight) && <InsightDetails insightDetails={singleInsight}></InsightDetails>}
          </div>
        </div>
      </div>
    </>
  );
};

export default InsightDetailWrapper;
