import { Button, Col, Form, Input, InputNumber, Row, Select, Spin } from 'antd';
import { isEmpty as _isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  createRecommendationTemplate,
  updateRecommendationTemplate
} from '../../redux/RecommendationTemplate/recommendationTemplateAction';
import { ADD_CONFIG, EDIT_CONFIG, templateTypeList, VIEW_CONFIG } from '../../utilityFunctions/utilityFunctions';
const Crud = props => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [recommendationType, setRecommendationType] = useState();
  const [flag, setFlag] = useState(false);
  const { singleRecommendationTemplateData, type, closeModal, templates } = props;

  const formItemLayout = {
    labelCol: {
      span: 24
    },
    wrapperCol: {
      span: 22
    }
  };
  const formInitialValue = {
    recommendation_types: singleRecommendationTemplateData?.recommendation_types,
    template_name: singleRecommendationTemplateData?.template_name,
    title: singleRecommendationTemplateData?.title,
    description: singleRecommendationTemplateData?.description,
    analysis_detail: singleRecommendationTemplateData?.analysis_detail,
    priority: singleRecommendationTemplateData?.priority,
    due_date_days: singleRecommendationTemplateData?.due_date_days,
    expected_result_date_days: singleRecommendationTemplateData?.expected_result_date_days
  };

  const onSubmit = values => {
    if (type === EDIT_CONFIG) {
      dispatch(updateRecommendationTemplate(values, singleRecommendationTemplateData?.id));
      closeModal(false);
    } else {
      dispatch(createRecommendationTemplate(values));
      closeModal(false);
    }
  };
  const onChangeRecommendationType = value => {
    setRecommendationType(value);
    setFlag(true);
    if (flag) {
      form.setFieldsValue({
        template_name: '',
        title: '',
        description: '',
        analysis_detail: '',
        priority: '',
        due_date_days: '',
        expected_result_date_days: ''
      });
    }
  };

  if (type !== 'ADD' && _isEmpty(singleRecommendationTemplateData)) {
    return <Spin />;
  }
  return (
    <Form
      {...formItemLayout}
      form={form}
      layout={formItemLayout}
      onFinish={onSubmit}
      initialValues={type === EDIT_CONFIG || type === VIEW_CONFIG ? formInitialValue : {}}
    >
      <Row align='top' gutter={24}>
        <Col md={24}>
          <div className='c-formElement'>
            <Form.Item
              name={'recommendation_types'}
              label='Recommendation Type'
              rules={[
                {
                  required: true,
                  message: 'Please Select Recommendation Type'
                },
                () => ({
                  validator(_, value) {
                    if (!value || value?.length <= 50) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Ensure this field has no more than 50 characters.'));
                  }
                })
              ]}
            >
              <Select
                onChange={value => onChangeRecommendationType(value)}
                disabled={type === VIEW_CONFIG || type === EDIT_CONFIG ? true : false}
                showSearch
                name='recommendation_types'
              >
                {!_isEmpty(templateTypeList) &&
                  templateTypeList?.map(singleType => {
                    return (
                      <Select.Option value={singleType?.key} key={singleType?.key}>
                        {singleType?.value}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </div>
        </Col>
      </Row>
      <Row align='top' gutter={24}>
        <Col md={24}>
          <div className='c-formElement'>
            <Form.Item
              name={'template_name'}
              label='Template Name'
              rules={[
                {
                  required: true,
                  message: 'Please Enter Template Name'
                },
                () => ({
                  validator(_, value) {
                    if (!value || value?.length <= 100) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Ensure this field has no more than 100 characters.'));
                  }
                }),
                () => ({
                  validator(_, value) {
                    const filteredTemplate =
                      !_isEmpty(templates) &&
                      templates?.find(
                        singleTemplate =>
                          singleTemplate?.recommendation_types === recommendationType &&
                          singleTemplate?.template_name === value?.trim()
                      );
                    if (!value || _isEmpty(filteredTemplate)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Please use another Template name'));
                  }
                })
              ]}
            >
              <Input
                disabled={
                  (type === VIEW_CONFIG || type === EDIT_CONFIG || type === ADD_CONFIG) && !recommendationType
                    ? true
                    : false
                }
                name='template_name'
              />
            </Form.Item>
          </div>
        </Col>
      </Row>

      <Row align='top' gutter={24}>
        <Col md={24}>
          <div className='c-formElement'>
            <Form.Item
              name={'title'}
              label='Title'
              rules={[
                {
                  required: true,
                  message: 'Please Enter Title'
                },
                () => ({
                  validator(_, value) {
                    if (!value || value?.length <= 100) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Ensure this field has no more than 100 characters.'));
                  }
                })
              ]}
            >
              <Input disabled={type === VIEW_CONFIG ? true : false} name='title' />
            </Form.Item>
          </div>
        </Col>
      </Row>

      <Row align='top' gutter={24}>
        <Col md={24}>
          <div className='c-formElement'>
            <Form.Item
              name={'description'}
              label='Description'
              rules={[
                {
                  required: true,
                  message: 'Please Enter Description'
                },
                () => ({
                  validator(_, value) {
                    if (!value || value?.length <= 2000) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Ensure this field has no more than 2000 characters.'));
                  }
                })
              ]}
            >
              <Input.TextArea disabled={type === VIEW_CONFIG ? true : false} name='description' />
            </Form.Item>
          </div>
        </Col>
      </Row>

      <Row align='top' gutter={24}>
        <Col md={24}>
          <div className='c-formElement'>
            <Form.Item
              name={'analysis_detail'}
              label='Analysis Description'
              rules={[
                () => ({
                  validator(_, value) {
                    if (!value || value?.length <= 2000) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Ensure this field has no more than 2000 characters.'));
                  }
                })
              ]}
            >
              <Input.TextArea disabled={type === VIEW_CONFIG ? true : false} name='analysis_detail' />
            </Form.Item>
          </div>
        </Col>
      </Row>

      <Row align='top' gutter={24}>
        <Col md={8}>
          <div className='c-formElement'>
            <Form.Item
              name={'priority'}
              label='Priority'
              rules={[
                {
                  required: true,
                  message: 'Please Select Priority'
                },
                () => ({
                  validator(_, value) {
                    if (!value || value?.length <= 50) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Ensure this field has no more than 50 characters.'));
                  }
                })
              ]}
            >
              <Select
                disabled={type === VIEW_CONFIG ? true : false}
                showSearch
                name='priority'
                placeholder='Select Select Priority'
              >
                <Select.Option value='critical'>Critical</Select.Option>
                <Select.Option value='major'>Major</Select.Option>
                <Select.Option value='minor'>Minor</Select.Option>
              </Select>
            </Form.Item>
          </div>
        </Col>
        <Col md={8}>
          <div className='c-formElement'>
            <Form.Item
              name={'due_date_days'}
              label='Due Date Days'
              rules={[
                {
                  required: true,
                  message: 'Please enter Due Date Days'
                },
                () => ({
                  validator(_, value) {
                    if (!value || value <= 10) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Ensure this field has no more than 10 characters.'));
                  }
                })
              ]}
            >
              <InputNumber
                disabled={type === VIEW_CONFIG ? true : false}
                name='due_date_days'
                min={0}
                style={{ width: '90%' }}
              />
            </Form.Item>
          </div>
        </Col>
        <Col md={8}>
          <div className='c-formElement'>
            <Form.Item
              name={'expected_result_date_days'}
              label='Expected Result Date Days'
              rules={[
                {
                  required: true,
                  message: 'Please enter Expected Result Date Days'
                },
                () => ({
                  validator(_, value) {
                    if (!value || value <= 10) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Ensure this field has no more than 10 characters.'));
                  }
                })
              ]}
            >
              <InputNumber
                disabled={type === VIEW_CONFIG ? true : false}
                name='expected_result_date_days'
                min={0}
                style={{ width: '80%' }}
              />
            </Form.Item>
          </div>
        </Col>
      </Row>

      <Form.Item className='center'>
        {type !== EDIT_CONFIG && type !== VIEW_CONFIG && (
          <Button type='primary' htmlType='submit' style={{ marginRight: '50px', marginTop: '10px' }}>
            Create Template
          </Button>
        )}
        {type === EDIT_CONFIG && (
          <Button type='primary' htmlType='submit' style={{ marginRight: '50px', marginTop: '10px' }}>
            Update Template
          </Button>
        )}

        <Button htmlType='button' onClick={() => closeModal(false)}>
          Close
        </Button>
      </Form.Item>
    </Form>
  );
};
export default Crud;
