export const metadataConstants = {
  GET_SINGLE_ORGANISATION_RECORDS_REQUEST: 'Metadata/GET_SINGLE_ORGANISATION_RECORDS_REQUEST',
  GET_SINGLE_ORGANISATION_RECORDS_SUCCESS: 'Metadata/GET_SINGLE_ORGANISATION_RECORDS_SUCCESS',
  GET_SINGLE_ORGANISATION_RECORDS_ERROR: 'Metadata/GET_SINGLE_ORGANISATION_RECORDS_ERROR',

  GET_AMDM_BY_ORGANISATION_REQUEST: 'Metadata/GET_AMDM_BY_ORGANISATION_REQUEST',
  GET_AMDM_BY_ORGANISATION_SUCCESS: 'Metadata/GET_AMDM_BY_ORGANISATION_SUCCESS',
  GET_AMDM_BY_ORGANISATION_ERROR: 'Metadata/GET_AMDM_BY_ORGANISATION_ERROR',

  GET_ORGANISATION_USER_REQUEST: 'Metadata/GET_ORGANISATION_USER_REQUEST',
  GET_ORGANISATION_USER_SUCCESS: 'Metadata/GET_ORGANISATION_USER_SUCCESS',
  GET_ORGANISATION_USER_ERROR: 'Metadata/GET_ORGANISATION_USER_ERROR',

  GET_ORGANISATION_BROAS_REQUEST: 'Metadata/GET_ORGANISATION_BROAS_REQUEST',
  GET_ORGANISATION_BROAS_SUCCESS: 'Metadata/GET_ORGANISATION_BROAS_SUCCESS',
  GET_ORGANISATION_BROAS_ERROR: 'Metadata/GET_ORGANISATION_BROAS_ERROR',

  GET_CONSTANT_DSS_METADATA_REQUEST: 'Metadata/GET_CONSTANT_DSS_METADATA_REQUEST',
  GET_CONSTANT_DSS_METADATA_SUCCESS: 'Metadata/GET_CONSTANT_DSS_METADATA_SUCCESS',
  GET_CONSTANT_DSS_METADATA_ERROR: 'Metadata/GET_CONSTANT_DSS_METADATA_ERROR',

  GET_VARIABLE_DSS_METADATA_REQUEST: 'Metadata/GET_VARIABLE_DSS_METADATA_REQUEST',
  GET_VARIABLE_DSS_METADATA_SUCCESS: 'Metadata/GET_VARIABLE_DSS_METADATA_SUCCESS',
  GET_VARIABLE_DSS_METADATA_ERROR: 'Metadata/GET_VARIABLE_DSS_METADATA_ERROR',

  UPDATE_ORGANISATION_REQUEST: 'Metadata/UPDATE_ORGANISATION_REQUEST',
  UPDATE_ORGANISATION_SUCCESS: 'Metadata/UPDATE_ORGANISATION_SUCCESS',
  UPDATE_ORGANISATION_ERROR: 'Metadata/UPDATE_ORGANISATION_ERROR',

  UPDATE_AMDM_REQUEST: 'Metadata/UPDATE_AMDM_REQUEST',
  UPDATE_AMDM_SUCCESS: 'Metadata/UPDATE_AMDM_SUCCESS',
  UPDATE_AMDM_ERROR: 'Metadata/UPDATE_AMDM_ERROR',

  UPDATE_ORGANISATION_BROAS_REQUEST: 'Metadata/UPDATE_ORGANISATION_BROAS_REQUEST',
  UPDATE_ORGANISATION_BROAS_SUCCESS: 'Metadata/UPDATE_ORGANISATION_BROAS_SUCCESS',
  UPDATE_ORGANISATION_BROAS_ERROR: 'Metadata/UPDATE_ORGANISATION_BROAS_ERROR',

  UPDATE_DSS_METADATA_VARIABLES_REQUEST: 'Metadata/UPDATE_DSS_METADATA_VARIABLES_REQUEST',
  UPDATE_DSS_METADATA_VARIABLES_SUCCESS: 'Metadata/UPDATE_DSS_METADATA_VARIABLES_SUCCESS',
  UPDATE_DSS_METADATA_VARIABLES_ERROR: 'Metadata/UPDATE_DSS_METADATA_VARIABLES_ERROR',

  CREATE_DSS_METADATA_VARIABLES_REQUEST: 'Metadata/CREATE_DSS_METADATA_VARIABLES_REQUEST',
  CREATE_DSS_METADATA_VARIABLES_SUCCESS: 'Metadata/CREATE_DSS_METADATA_VARIABLES_SUCCESS',
  CREATE_DSS_METADATA_VARIABLES_ERROR: 'Metadata/CREATE_DSS_METADATA_VARIABLES_ERROR',

  UPDATE_DSS_METADATA_CONSTANTS_REQUEST: 'Metadata/UPDATE_DSS_METADATA_CONSTANTS_REQUEST',
  UPDATE_DSS_METADATA_CONSTANTS_SUCCESS: 'Metadata/UPDATE_DSS_METADATA_CONSTANTS_SUCCESS',
  UPDATE_DSS_METADATA_CONSTANTS_ERROR: 'Metadata/UPDATE_DSS_METADATA_CONSTANTS_ERROR'
};
