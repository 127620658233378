import { RevenueCalculatorConstants } from './RevenueCalculatorConstant';
import Api from './RevenueCalculatorApi';

const RevenueCalculator = new Api('polaris');

// --- Get RevenueCalculatorData
export const getIncrementalityChecker = () => {
  return async dispatch => {
    try {
      dispatch({ type: RevenueCalculatorConstants.GET_REVENUECALCULATOR_DATA_REQUEST });
      const { data } = await RevenueCalculator.getIncrementalityChecker();
      dispatch({ type: RevenueCalculatorConstants.GET_REVENUECALCULATOR_DATA_SUCCESS, payload: data });
    } catch (err) {
      dispatch({ type: RevenueCalculatorConstants.GET_REVENUE_CALCULATOR_DATA_ERROR });
    }
  };
};

// --- Get predicted revenue
export const getIncrementalityPredictor = obj => {
  return async dispatch => {
    try {
      dispatch({ type: RevenueCalculatorConstants.GET_PREDICTED_REVENUE_REQUEST });
      const { data } = await RevenueCalculator.getPredictedRevenue(obj);
      dispatch({ type: RevenueCalculatorConstants.GET_PREDICTED_REVENUE_SUCCESS, payload: data });
    } catch (err) {
      dispatch({ type: RevenueCalculatorConstants.GET_PREDICTED_REVENUE_ERROR });
    }
  };
};
