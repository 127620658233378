export const recommendationTemplateConstants = {
  GET_RECOMMENDATION_TEMPLATE_REQUEST: 'RecommendationTemplate/GET_RECOMMENDATION_TEMPLATE_REQUEST',
  GET_RECOMMENDATION_TEMPLATE_SUCCESS: 'RecommendationTemplate/GET_RECOMMENDATION_TEMPLATE_SUCCESS',
  GET_RECOMMENDATION_TEMPLATE_ERROR: 'RecommendationTemplate/GET_RECOMMENDATION_TEMPLATE_ERROR',

  GET_SINGLE_RECOMMENDATION_TEMPLATE_REQUEST: 'RecommendationTemplate/GET_SINGLE_RECOMMENDATION_TEMPLATE_REQUEST',
  GET_SINGLE_RECOMMENDATION_TEMPLATE_SUCCESS: 'RecommendationTemplate/GET_SINGLE_RECOMMENDATION_TEMPLATE_SUCCESS',
  GET_SINGLE_RECOMMENDATION_TEMPLATE_ERROR: 'RecommendationTemplate/GET_SINGLE_RECOMMENDATION_TEMPLATE_ERROR',

  CREATE_RECOMMENDATION_TEMPLATE_REQUEST: 'RecommendationTemplate/CREATE_RECOMMENDATION_TEMPLATE_REQUEST',
  CREATE_RECOMMENDATION_TEMPLATE_SUCCESS: 'RecommendationTemplate/CREATE_RECOMMENDATION_TEMPLATE_SUCCESS',
  CREATE_RECOMMENDATION_TEMPLATE_ERROR: 'RecommendationTemplate/CREATE_RECOMMENDATION_TEMPLATE_ERROR',

  UPDATE_RECOMMENDATION_TEMPLATE_REQUEST: 'RecommendationTemplate/UPDATE_RECOMMENDATION_TEMPLATE_REQUEST',
  UPDATE_RECOMMENDATION_TEMPLATE_SUCCESS: 'RecommendationTemplate/UPDATE_RECOMMENDATION_TEMPLATE_SUCCESS',
  UPDATE_RECOMMENDATION_TEMPLATE_ERROR: 'RecommendationTemplate/UPDATE_RECOMMENDATION_TEMPLATE_ERROR',

  DELETE_RECOMMENDATION_TEMPLATE_REQUEST: 'RecommendationTemplate/DELETE_RECOMMENDATION_TEMPLATE_REQUEST',
  DELETE_RECOMMENDATION_TEMPLATE_SUCCESS: 'RecommendationTemplate/DELETE_RECOMMENDATION_TEMPLATE_SUCCESS',
  DELETE_RECOMMENDATION_TEMPLATE_ERROR: 'RecommendationTemplate/DELETE_RECOMMENDATION_TEMPLATE_ERROR',

  CLEAR_RECOMMENDATION_TEMPLATE: 'RecommendationTemplate/CLEAR_RECOMMENDATION_TEMPLATE'
};
