import { Descriptions } from 'antd';
import moment from 'moment';
import React from 'react';
import {
  convertKeyToTitle,
  MARKETING_OWNERS,
  PLATFORM,
  SIZE,
  TYPE,
  convertOrganisationLabelForVertical
} from '../../../utilityFunctions/utilityFunctions';
import { isEmpty as _isEmpty } from 'lodash';
import SMResult from 'components/SMResult/SMResult';

const OrganisationDetails = props => {
  const { metaData } = props;
  if (metaData?.single_organisation_fetching_status === 'fetching') {
    return <SMResult type='loader' message='Loading Organisation Detail' />;
  }

  if (metaData?.single_organisation_fetching_status === 'fail') {
    return <SMResult type='error' message='Unable to Fetch Organisation Detail Data' />;
  }

  return (
    <Descriptions bordered>
      <Descriptions.Item label='Store Name'>{metaData?.organisationData?.name}</Descriptions.Item>
      <Descriptions.Item label='Store Code' span={6}>
        {metaData?.organisationData?.store_code}
      </Descriptions.Item>

      <Descriptions.Item label='Industry'>{metaData?.organisationData?.industry}</Descriptions.Item>
      <Descriptions.Item label='Size' span={6}>
        {convertKeyToTitle(metaData?.organisationData?.size, SIZE)}
      </Descriptions.Item>

      <Descriptions.Item label='Platform'>
        {convertKeyToTitle(metaData?.organisationData?.platform, PLATFORM)}
      </Descriptions.Item>
      <Descriptions.Item label='Currency' span={6}>
        {metaData?.organisationData?.currency}
      </Descriptions.Item>

      <Descriptions.Item label='Go live date'>
        {!_isEmpty(metaData?.organisationData?.go_live_date)
          ? moment(metaData?.organisationData?.go_live_date).format('DD-MMM-YYYY')
          : ''}
      </Descriptions.Item>
      <Descriptions.Item label='Marketing owner' span={6}>
        {convertKeyToTitle(metaData?.organisationData?.marketing_owners, MARKETING_OWNERS)}
      </Descriptions.Item>

      <Descriptions.Item label='Type'>{convertKeyToTitle(metaData?.organisationData?.type, TYPE)}</Descriptions.Item>
      <Descriptions.Item label='Vertical' span={2}>
        {convertOrganisationLabelForVertical(metaData?.organisationData?.vertical, props?.verticalData)}
      </Descriptions.Item>

      <Descriptions.Item label='Recommendation Publish Monthly Limit'>
        {metaData?.organisationData?.recommendation_publish_monthly_limit}
      </Descriptions.Item>

      <Descriptions.Item label='Order status' span={2}>
        {!_isEmpty(metaData?.organisationData?.valid_order_status) &&
          metaData?.organisationData?.valid_order_status?.map((singleOrderStatus, index) => {
            return metaData?.organisationData?.valid_order_status?.length - 1 === index
              ? singleOrderStatus
              : singleOrderStatus + ',';
          })}
      </Descriptions.Item>
      {metaData?.organisationData?.platform === 'shopify' && (
        <Descriptions.Item label='Source Channels'>
          {!_isEmpty(metaData?.organisationData?.valid_source_channels) &&
            metaData?.organisationData?.valid_source_channels?.map((singleOrderStatus, index) => {
              return metaData?.organisationData?.valid_source_channels?.length - 1 === index
                ? singleOrderStatus
                : singleOrderStatus + ',';
            })}
        </Descriptions.Item>
      )}
    </Descriptions>
  );
};
export default OrganisationDetails;
