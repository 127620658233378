import { ArrowDownOutlined, ArrowUpOutlined, RetweetOutlined } from '@ant-design/icons';
import { Col, Descriptions, Empty, Row, Tag, Tooltip, Typography } from 'antd';
import { isArray as _isArray, isEmpty as _isEmpty } from 'lodash-es';
import React from 'react';
import { isoToDate } from '../../utilityFunctions/utilityFunctions';
import CommonTable from '../CommonTable/CommonTable';

const { Title } = Typography;
const InsightDetails = ({ insightDetails }) => {
  const {
    priority,
    state,
    is_reported,
    title,
    description,
    other_details,
    created_at,
    updated_at,
    result_chart_data,
    metric_label,
    dimension_label,
    action_values
  } = insightDetails;
  const filteredResultChartData =
    _isArray(result_chart_data) && result_chart_data.filter(singleChartData => singleChartData?.metric_name);
  const crossMetricLabel = other_details?.cross_metric_key;
  const outliersTableColumns = [
    {
      title: `${dimension_label}`,
      dataIndex: 'dimension_1_value',
      key: 'dimension_1_value'
    },
    {
      title: `${metric_label}`,
      dataIndex: 'fact_value',
      key: 'fact_value'
    }
  ];

  // Set tag color based on priority
  const setPriorityColor = priority => {
    switch (priority?.toLowerCase()) {
      case 'blocker':
        return '#f5222d';
      case 'critical':
        return '#fa8c16';
      case 'major':
        return '#faad14';
      case 'low':
        return '#a0d911';
      default:
        return '#999999';
    }
  };

  // -- function to create header row
  const createTableHeaderRow = () => {
    if (_isEmpty(filteredResultChartData)) {
      return;
    }
    const headerRow = [
      {
        key: 'rowHeading',
        dataIndex: 'rowHeading',
        width: 100,
        title: '',
        fixed: 'left'
      }
    ];
    const newArrayWithNewHeaderData = filteredResultChartData?.map(insight => {
      return {
        key: insight?.metric_name,
        dataIndex: insight?.metric_name,
        title: <span className='tableHeader'>{insight?.metric_name}</span>,
        width: 100
      };
    });
    return [...headerRow, ...newArrayWithNewHeaderData];
  };

  const updatedHeadCell = createTableHeaderRow();
  // -- function to create raw data view
  const createTableBodyRows = () => {
    let rowSummary = {};
    let rowCompareSummary = {};
    let newRowPercentValues = {};
    let objectForPercentValues = {};
    if (_isEmpty(filteredResultChartData)) {
      return;
    }
    rowSummary = { rowHeading: <span className='tableHeader'>Current</span> };
    filteredResultChartData.forEach(insight => {
      rowSummary[insight?.metric_name] = insight?.current_value?.toFixed(2);
      objectForPercentValues[insight?.metric_name] = {
        ...objectForPercentValues[insight?.metric_name],
        current: insight?.current_value?.toFixed(2)
      };
    });

    rowCompareSummary = { rowHeading: <span className='tableHeader'>Previous</span> };
    filteredResultChartData.forEach(insight => {
      rowCompareSummary[insight?.metric_name] = insight?.past_value?.toFixed(2);
      objectForPercentValues[insight?.metric_name] = {
        ...objectForPercentValues[insight?.metric_name],
        previous: insight?.past_value?.toFixed(2)
      };
    });

    newRowPercentValues = { rowHeading: <span className='tableHeader'>Change (%)</span> };
    filteredResultChartData.forEach(insight => {
      const notANumberCheck = parseFloat(insight?.deviation);
      newRowPercentValues[insight?.metric_name] = (
        <span className={`rawData_percentValue ${!insight?.deviation ? '' : insight?.deviation < 0 ? 'down' : 'up'}`}>
          {notANumberCheck ? insight?.deviation?.toFixed(2)?.replace('-', '') : 0} %
          {!insight?.deviation ? (
            ''
          ) : insight?.deviation < 0 ? (
            <ArrowDownOutlined style={{ marginLeft: '10px' }} />
          ) : (
            <ArrowUpOutlined style={{ marginLeft: '10px' }} />
          )}
        </span>
      );
    });

    return [rowSummary, rowCompareSummary, newRowPercentValues];
  };
  const rowCollection = createTableBodyRows();

  const comparisonRuleColumn = [
    {
      title: 'Metric',
      dataIndex: 'metric',
      key: 'metric'
    },
    {
      title: 'Dimension',
      dataIndex: 'dimension',
      key: 'dimension'
    },
    {
      title: 'Dimension Value',
      dataIndex: 'dimension value',
      key: 'dimension value'
    },
    {
      title: 'Actual Value',
      dataIndex: 'actual value',
      key: 'actual value'
    }
  ];

  const comparisonRuleData = action_values?.action_comp_table;
  const crossMetricData = other_details?.cross_metric_data;

  const crossMetricColumn = [
    {
      title: 'Dimension Value',
      dataIndex: 'Dimension Value',
      key: 'Dimension Value'
    },
    {
      title: `${crossMetricLabel}`,
      dataIndex: 'fact_value',
      key: 'fact_value'
    }
  ];

  const inventoryRuleColumn = [
    {
      title: 'Product Name',
      dataIndex: 'Product Name',
      key: 'Product Name'
    },
    {
      title: 'SKU',
      dataIndex: 'SKU',
      key: 'SKU'
    },
    {
      title: 'Last 30 Days Revenue',
      dataIndex: 'Last 30 Days Revenue',
      key: 'Last 30 Days Revenue'
    },
    {
      title: 'Current Inventory Count',
      dataIndex: 'Current Inventory Count',
      key: 'Current Inventory Count'
    }
  ];

  const inventoryRuleData = action_values?.action_inventory_raw_data;

  // create raw table title
  const rawTableTitle = () => {
    const startDateOFDateRange = new Date(filteredResultChartData[0]?.date_range[0])?.toDateString()?.slice(4);
    const endDateOFDateRange = new Date(filteredResultChartData[0]?.date_range[1])?.toDateString()?.slice(4);
    const startDateOFCompareRange = new Date(filteredResultChartData[0]?.compare_range[0])?.toDateString()?.slice(4);
    const endDateOFCompareRange = new Date(filteredResultChartData[0]?.compare_range[1])?.toDateString()?.slice(4);
    const dimensionName = filteredResultChartData[0].dimension_name;
    const dimensionValue = filteredResultChartData[0].dimension_value;
    return (
      <>
        <Typography color='textSecondary' style={{ padding: '15px 0px 20px 0px', fontSize: 14 }}>
          <span style={{ color: '#7e7c7c', fontWeight: 'bold' }}>
            {startDateOFCompareRange}- {endDateOFCompareRange}
            <RetweetOutlined />
          </span>
          {startDateOFDateRange}- {endDateOFDateRange}
        </Typography>

        <Title level={5} style={{ margin: '0 0 10px' }}>
          {dimensionName} : {dimensionValue}
        </Title>
      </>
    );
  };

  // create cross dimension comparison table title
  const crossDimensionComparisonTableTitle = () => {
    return (
      <Title level={5} style={{ margin: '0 0 10px' }}>
        Cross Dimension Comparison
      </Title>
    );
  };

  // create inventory table title
  const inventoryTableTitle = () => {
    return (
      <Title level={5} style={{ margin: '0 0 10px' }}>
        Raw Data
      </Title>
    );
  };

  // create cross metric table title
  const crossMetricTableTitle = () => {
    return (
      <Title level={5} style={{ margin: '0 0 10px' }}>
        Cross Metric Data
      </Title>
    );
  };

  return (
    <>
      {!_isEmpty(insightDetails) && (
        <>
          <div className='tagList mb-10'>
            <Row>
              {priority && (
                <Col span={8}>
                  <Tag color={setPriorityColor(priority)}>Priority: {priority}</Tag>
                </Col>
              )}

              {!!other_details?.revenue_error_percent && (
                <Col span={8} style={{ textAlign: 'center' }}>
                  <Tooltip title='Difference in the revenue captured by tracker and backend'>
                    <Tag color='orange'>
                      Tracker Data Error: {other_details?.revenue_error_percent?.toFixed(2)?.replace('-', '')} %
                    </Tag>
                  </Tooltip>
                </Col>
              )}

              <Col span={8} className='text-right'>
                {state?.includes('published') && <Tag color='green'>Published</Tag>}
                {is_reported && <Tag color='red'>Reported</Tag>}
              </Col>
            </Row>
          </div>

          <Descriptions bordered size='small'>
            <Descriptions.Item label='Title' span={3}>
              <span>{title}</span>
            </Descriptions.Item>
            {description && (
              <Descriptions.Item label='Description' span={3}>
                {description}
              </Descriptions.Item>
            )}

            {other_details?.deviation && (
              <Descriptions.Item label='Deviation' span={3}>
                {parseFloat(other_details?.deviation)?.toFixed(2)}%
              </Descriptions.Item>
            )}

            {other_details?.previous_value && (
              <Descriptions.Item label='Past Value'>{other_details?.previous_value?.toFixed(2)}</Descriptions.Item>
            )}

            {other_details?.current_value && (
              <Descriptions.Item label='Actual Value' span={2}>
                {other_details?.current_value?.toFixed(2)}
              </Descriptions.Item>
            )}

            <Descriptions.Item label='Created At'>{isoToDate(created_at)}</Descriptions.Item>
            <Descriptions.Item label='Updated At'>{isoToDate(updated_at)}</Descriptions.Item>
          </Descriptions>

          {!_isEmpty(filteredResultChartData) && (
            <CommonTable
              type='rawTable'
              renderTitle={rawTableTitle}
              dataSource={rowCollection}
              columns={updatedHeadCell}
            />
          )}
          {!_isEmpty(other_details?.outliers) && (
            <CommonTable type='outliers' dataSource={other_details?.outliers} columns={outliersTableColumns} />
          )}
          {!_isEmpty(comparisonRuleData) && (
            <CommonTable
              type='crossDimensionComparison'
              renderTitle={crossDimensionComparisonTableTitle}
              dataSource={comparisonRuleData}
              columns={comparisonRuleColumn}
            />
          )}
          {!_isEmpty(inventoryRuleData) && (
            <CommonTable
              type='inventory'
              renderTitle={inventoryTableTitle}
              dataSource={inventoryRuleData}
              columns={inventoryRuleColumn}
            />
          )}
          {!_isEmpty(crossMetricData) && (
            <CommonTable
              type='crossMetric'
              renderTitle={crossMetricTableTitle}
              dataSource={crossMetricData}
              columns={crossMetricColumn}
            />
          )}
        </>
      )}
      {_isEmpty(insightDetails) && <Empty />}
    </>
  );
};

export default InsightDetails;
