import Api from '../api';

export default class Insights extends Api {
  getInsightAction = () => this.get(`/`);

  getInsightTimeConfigs = async () => {
    try {
      const { data } = await this.get(`/configs/`);
      return data;
    } catch (error) {
      return error;
    }
  };

  getSpecificInsightAction = async id => {
    try {
      var { data } = await this.get(`/${id}/`);
      return data;
    } catch (error) {
      return error;
    }
  };

  getInsightsByDateAction = async date => {
    try {
      var { data } = await this.get(`/revamp/?start_date=${date.start_date}&end_date=${date.end_date}`);
      return data;
    } catch (error) {
      return {
        hasError: true,
        error: error.response
      };
    }
  };

  updateInsightAction = async (updateData, insightId) => {
    try {
      const { data } = await this.patch(`/${insightId}/`, updateData);
      return data;
    } catch (error) {
      return {
        hasError: true,
        error: error.response
      };
    }
  };

  createInsightAction = async insightObject => {
    try {
      const { data } = await this.post(`/`, insightObject);
      return data;
    } catch (error) {
      return {
        hasError: true,
        error: error.response
      };
    }
  };

  // deleteInsightAction = (actionId, date) => {
  //   new Promise(async (resolve, reject) => {
  //     try {
  //       const { data } = await this.delete(`/${actionId}/`);
  //       resolve(data);
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });
  // };

  // problem in above method ---------
  // async await by default returns promis.
  // in above example async await is wrapped inside another promis which expect double .then method.

  deleteInsightAction = async actionId => {
    try {
      const { data } = await this.patch(`/${actionId}/`, { is_deleted: true });
      return data;
    } catch (error) {
      return error;
    }
  };
}
