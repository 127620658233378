import { recommendationsConstants } from './constants';

const initialState = {
  metric_list: {
    list: []
  },
  metric_Group_mapping_list: {
    list: []
  },
  metric_mapping_list: {
    list: {}
  },
  list: {},
  selected_item: {},
  assigning_users: {},
  publish_limit: {},
  linked_insights: { list: [] }
};

export default function Recommendations(state = initialState, action) {
  switch (action.type) {
    // --- Get Metric List
    case recommendationsConstants.GET_METRIC_LIST_REQUEST:
      return {
        ...state,
        metric_list: { ...state.metric_list, data_fetching_status: 'fetching' }
      };
    case recommendationsConstants.GET_METRIC_LIST_SUCCESS:
      return {
        ...state,
        metric_list: {
          ...state.metric_list,
          data_fetching_status: 'success',
          list: [...action.payload]
        }
      };
    case recommendationsConstants.GET_METRIC_LIST_ERROR:
      return {
        ...state,
        metric_list: {
          ...state.metric_list,
          data_fetching_status: 'fail'
        }
      };

    // --- Get Metric List mapping
    case recommendationsConstants.GET_METRICS_GROUP_MAPPING_REQUEST:
      return {
        ...state,
        metric_Group_mapping_list: { ...state.metric_Group_mapping_list, data_fetching_status: 'fetching' }
      };
    case recommendationsConstants.GET_METRICS_GROUP_MAPPING_SUCCESS:
      return {
        ...state,
        metric_Group_mapping_list: {
          ...state.metric_Group_mapping_list,
          data_fetching_status: 'success',
          list: [...action.payload]
        }
      };
    case recommendationsConstants.GET_METRICS_GROUP_MAPPING_ERROR:
      return {
        ...state,
        metric_Group_mapping_list: {
          ...state.metric_Group_mapping_list,
          data_fetching_status: 'fail'
        }
      };

    // --- Set Metric List mapping
    case recommendationsConstants.SET_METRICS_GROUP_MAPPING_REQUEST:
      return {
        ...state,
        metric_mapping_list: { ...state.metric_mapping_list, data_fetching_status: 'fetching' }
      };
    case recommendationsConstants.SET_METRICS_GROUP_MAPPING_SUCCESS:
      return {
        ...state,
        metric_mapping_list: {
          data_fetching_status: 'success',
          list: { ...action.payload }
        }
      };
    case recommendationsConstants.SET_METRICS_GROUP_MAPPING_ERROR:
      return {
        ...state,
        metric_mapping_list: {
          ...state.metric_mapping_list,
          data_fetching_status: 'fail'
        }
      };

    // --- Get Recommendations
    case recommendationsConstants.GET_RECOMMENDATIONS_REQUEST:
      return {
        ...state,
        list: { ...state.list, recommendation_fetching_status: 'fetching' }
      };
    case recommendationsConstants.GET_RECOMMENDATIONS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          recommendation_fetching_status: 'success',
          ...action.payload
        }
      };
    case recommendationsConstants.GET_RECOMMENDATIONS_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          recommendation_fetching_status: 'fail'
        }
      };

    // --- Get Recommendations Published Count
    case recommendationsConstants.GET_RECOMMENDATION_PUBLISHED_COUNT_REQUEST:
      return {
        ...state,
        publish_limit: {
          ...state.publish_limit,
          recommendation_published_count_fetching_status: 'fetching'
        }
      };
    case recommendationsConstants.GET_RECOMMENDATION_PUBLISHED_COUNT_SUCCESS:
      return {
        ...state,
        publish_limit: {
          ...state.publish_limit,
          recommendation_published_count_fetching_status: 'success',
          ...action.payload
        }
      };
    case recommendationsConstants.GET_RECOMMENDATION_PUBLISHED_COUNT_ERROR:
      return {
        ...state,
        publish_limit: {
          ...state.publish_limit,
          recommendation_published_count_fetching_status: 'fail'
        }
      };

    // --- Get Recommendations by ID
    case recommendationsConstants.GET_RECOMMENDATION_BY_ID_REQUEST:
      return {
        ...state,
        selected_item: {
          ...state.selected_item,
          recommendation_by_id_fetching_status: 'fetching'
        }
      };
    case recommendationsConstants.GET_RECOMMENDATION_BY_ID_SUCCESS:
      return {
        ...state,
        selected_item: {
          ...state.selected_item,
          recommendation_by_id_fetching_status: 'success',
          ...action.payload
        }
      };
    case recommendationsConstants.GET_RECOMMENDATION_BY_ID_ERROR:
      return {
        ...state,
        selected_item: {
          ...state.selected_item,
          recommendation_by_id_fetching_status: 'fail'
        }
      };

    // --- Get linked insights of that reco
    case recommendationsConstants.GET_LINKED_INSIGHTS_REQUEST:
      return {
        ...state,
        linked_insights: {
          ...state.linked_insights,
          recommendation_linked_insights_fetching_status: 'fetching'
        }
      };
    case recommendationsConstants.GET_LINKED_INSIGHTS_SUCCESS:
      return {
        ...state,
        linked_insights: {
          ...state.linked_insights,
          recommendation_linked_insights_fetching_status: 'success',
          list: [...action.payload]
        }
      };
    case recommendationsConstants.GET_LINKED_INSIGHTS_ERROR:
      return {
        ...state,
        linked_insights: {
          ...state.linked_insights,
          recommendation_linked_insights_fetching_status: 'fail'
        }
      };

    // --- Clear Recommendation details on unload component
    case recommendationsConstants.CLEAR_SELECTED_item:
      return {
        ...state,
        list: {},
        selected_item: {},
        linked_insights: { list: [] }
      };

    // --- Clear Recommendation details on unload component
    case recommendationsConstants.CLEAR_LINKED_INSIGHTS:
      return {
        ...state,
        linked_insights: { list: [] },
        selected_item: {}
      };

    // --- Clear Recommendation details on unload component
    case recommendationsConstants.CLEAR_DIMENSION_MAPPING:
      return {
        ...state,
        metric_list: {
          list: []
        },
        metric_Group_mapping_list: {
          list: []
        },
        metric_mapping_list: {
          list: {}
        }
      };

    case recommendationsConstants.GET_RECOMMENDATIONS_BY_ACTIVE_TAB_REQUEST:
      return {
        ...state,
        list: { recommendation_fetching_status: 'fetching', data: [] }
      };

    case recommendationsConstants.GET_RECOMMENDATIONS_BY_ACTIVE_TAB_SUCCESS:
      return {
        ...state,
        list: {
          recommendation_fetching_status: 'success',
          data: [...action.payload]
        }
      };

    case recommendationsConstants.GET_RECOMMENDATIONS_BY_ACTIVE_TAB_ERROR:
      return {
        ...state,
        list: {
          recommendation_fetching_status: 'fail'
        }
      };

    // --- Clear Recommendation details on unload component
    case recommendationsConstants.CLEAR_SELECTED_RECOMMENDATION:
      return {
        ...state,
        selected_item: {}
      };
    default:
      return state;
  }
}
