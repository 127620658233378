import React from 'react';
import { Avatar, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { UserOutlined } from '@ant-design/icons';
const RenderAvatar = props => {
  const email = props?.user?.email;
  return (
    <Tooltip placement='left' title={email}>
      <Avatar>{email ? email?.charAt(0)?.toUpperCase() : <UserOutlined />}</Avatar>
    </Tooltip>
  );
};

var mapStateToProps = ({ User }) => ({ user: User.userData });
export default connect(mapStateToProps)(RenderAvatar);
