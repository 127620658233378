import React from 'react';
import { Select } from 'antd';

function Account(props) {
  if (props.options) {
    return (
      <div className='topbar__account__wrapper'>
        <div id='storeSelect_options'>
          <Select
            defaultActiveFirstOption={true}
            defaultValue={props.defaultOrgId}
            className='topbar__account__select topbar__text'
            onSelect={key => props.onSelect(key)}
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            optionFilterProp='children'
            showSearch
          >
            {props.options.map(option => {
              return (
                <Select.Option key={option.id} value={option.id}>
                  {option.name}
                </Select.Option>
              );
            })}
          </Select>
          <div className='hidden_storeList' id='storeSelect_options_list'>
            <ul>
              {props.options.map(option => {
                return (
                  <li key={option.id}>
                    <span>{option.name}</span>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  }
  return null;
}

export default Account;
