export const recommendationsConstants = {
  GET_RECOMMENDATIONS_REQUEST: 'Recommendations/GET_RECOMMENDATIONS_REQUEST',
  GET_RECOMMENDATIONS_SUCCESS: 'Recommendations/GET_RECOMMENDATIONS_SUCCESS',
  GET_RECOMMENDATIONS_ERROR: 'Recommendations/GET_RECOMMENDATIONS_ERROR',

  GET_RECOMMENDATION_BY_ID_REQUEST: 'Recommendations/GET_RECOMMENDATION_BY_ID_REQUEST',
  GET_RECOMMENDATION_BY_ID_SUCCESS: 'Recommendations/GET_RECOMMENDATION_BY_ID_SUCCESS',
  GET_RECOMMENDATION_BY_ID_ERROR: 'Recommendations/GET_RECOMMENDATION_BY_ID_ERROR',

  GET_RECOMMENDATION_PUBLISHED_COUNT_REQUEST: 'Recommendations/GET_RECOMMENDATION_PUBLISHED_COUNT_REQUEST',
  GET_RECOMMENDATION_PUBLISHED_COUNT_SUCCESS: 'Recommendations/GET_RECOMMENDATION_PUBLISHED_COUNT_SUCCESS',
  GET_RECOMMENDATION_PUBLISHED_COUNT_ERROR: 'Recommendations/GET_RECOMMENDATION_PUBLISHED_COUNT_ERROR',

  GET_LINKED_INSIGHTS_REQUEST: 'Recommendations/GET_LINKED_INSIGHTS_REQUEST',
  GET_LINKED_INSIGHTS_SUCCESS: 'Recommendations/GET_LINKED_INSIGHTS_SUCCESS',
  GET_LINKED_INSIGHTS_ERROR: 'Recommendations/GET_LINKED_INSIGHTS_ERROR',

  CREATE_NEW_RECOMMENDATION_REQUEST: 'Recommendations/CREATE_NEW_RECOMMENDATION_REQUEST',
  CREATE_NEW_RECOMMENDATION_SUCCESS: 'Recommendations/CREATE_NEW_RECOMMENDATION_SUCCESS',
  CREATE_NEW_RECOMMENDATION_ERROR: 'Recommendations/CREATE_NEW_RECOMMENDATION_ERROR',

  UPDATE_RECOMMENDATION_REQUEST: 'Recommendations/UPDATE_RECOMMENDATION_REQUEST',
  UPDATE_RECOMMENDATION_SUCCESS: 'Recommendations/UPDATE_RECOMMENDATION_SUCCESS',
  UPDATE_RECOMMENDATION_ERROR: 'Recommendations/UPDATE_RECOMMENDATION_ERROR',

  GET_METRIC_LIST_REQUEST: 'Recommendations/GET_METRIC_LIST_REQUEST',
  GET_METRIC_LIST_SUCCESS: 'Recommendations/GET_METRIC_LIST_SUCCESS',
  GET_METRIC_LIST_ERROR: 'Recommendations/GET_METRIC_LIST_ERROR',

  GET_METRICS_GROUP_MAPPING_REQUEST: 'Recommendations/GET_METRICS_GROUP_MAPPING_REQUEST',
  GET_METRICS_GROUP_MAPPING_SUCCESS: 'Recommendations/GET_METRICS_GROUP_MAPPING_SUCCESS',
  GET_METRICS_GROUP_MAPPING_ERROR: 'Recommendations/GET_METRICS_GROUP_MAPPING_ERROR',

  SET_METRICS_GROUP_MAPPING_REQUEST: 'Recommendations/SET_METRICS_GROUP_MAPPING_REQUEST',
  SET_METRICS_GROUP_MAPPING_SUCCESS: 'Recommendations/SET_METRICS_GROUP_MAPPING_SUCCESS',
  SET_METRICS_GROUP_MAPPING_ERROR: 'Recommendations/SET_METRICS_GROUP_MAPPING_ERROR',

  DELETE_RECOMMENDATION_REQUEST: 'Recommendations/DELETE_RECOMMENDATION_REQUEST',
  DELETE_RECOMMENDATION_SUCCESS: 'Recommendations/DELETE_RECOMMENDATION_SUCCESS',
  DELETE_RECOMMENDATION_ERROR: 'Recommendations/DELETE_RECOMMENDATION_ERROR',

  CLEAR_SELECTED_RECOMMENDATION: 'Recommendations/CLEAR_SELECTED_RECOMMENDATION',
  CLEAR_LINKED_INSIGHTS: 'Recommendations/CLEAR_LINKED_INSIGHTS',
  CLEAR_DIMENSION_MAPPING: 'Recommendations/CLEAR_DIMENSION_MAPPING',

  GET_RECOMMENDATIONS_BY_ACTIVE_TAB_REQUEST: 'Recommendations/GET_RECOMMENDATIONS_BY_ACTIVE_TAB_REQUEST',
  GET_RECOMMENDATIONS_BY_ACTIVE_TAB_SUCCESS: 'Recommendations/GET_RECOMMENDATIONS_BY_ACTIVE_TAB_SUCCESS',
  GET_RECOMMENDATIONS_BY_ACTIVE_TAB_ERROR: 'Recommendations/GET_RECOMMENDATIONS_BY_ACTIVE_TAB_ERROR'
};
