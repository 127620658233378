import { Table } from 'antd';
import React from 'react';
import './CommonTable.scss';
const CommonTable = props => {
  const { type, renderTitle, dataSource, columns } = props;

  const insightsRuleTypes = ['rawTable', 'crossDimensionComparison', 'inventory', 'templates', 'crossMetric'];

  return (
    <div style={{ overflow: 'auto', marginTop: '20px' }}>
      {insightsRuleTypes.includes(type) && renderTitle && renderTitle()}
      <Table
        onHeaderRow={() => {
          return {
            className: type === 'outliers' ? 'columnHead' : ''
          };
        }}
        pagination={insightsRuleTypes.includes(type) ? true : false}
        columns={columns}
        dataSource={dataSource}
        size='small'
        bordered
        style={{ padding: '0' }}
      />
    </div>
  );
};
export default CommonTable;
