import { Descriptions } from 'antd';
import SMResult from 'components/SMResult/SMResult';
import { isEmpty as _isEmpty } from 'lodash';
import React from 'react';

const BROASDetails = props => {
  const { broasData } = props;

  if (broasData?.organisation_broas_fetching_status === 'fetching') {
    return <SMResult type='loader' message='Loading Broas' />;
  }

  if (broasData?.organisation_broas_fetching_status === 'fail') {
    return <SMResult type='error' message='Unable to Fetch Data' />;
  }

  return (
    <>
      <Descriptions bordered>
        {_isEmpty(broasData) || broasData?.data?.status === 'FAILURE' ? (
          <Descriptions.Item label='Broas'>Not Assigned</Descriptions.Item>
        ) : (
          <Descriptions.Item label='Broas'> {broasData?.data?.data?.[0]?.target_roas}</Descriptions.Item>
        )}
      </Descriptions>
    </>
  );
};
export default BROASDetails;
