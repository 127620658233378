import moment from 'moment';
import { nanoid } from 'nanoid';
import { isEmpty as _isEmpty } from 'lodash-es';
export const insightInitialState = {
  title: '',
  description: '',
  metric_label: '',
  dimension_label: '',
  action_channel: '',
  action_sub_channel: '',
  other_details: {
    corr_value: 0,
    previous_value: 0,
    current_value: 0,
    deviation: 0,
    threat_filter: ''
  },
  is_reported: false,
  is_ai_generated: false,
  feedback: {},
  priority: '',
  state: [],
  tags: [],
  time_comparison_category: '',
  recommendations_set: [],
  insight_date: moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
  created_at: moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
};

export const timeCompareStatus = ['yesterday', 'last_year', 'same_week_last_month', 'last_week_aggregate'];
export const publishedStatus = ['published', 'unpublished'];
export const readStatus = ['read', 'unread'];
export const priority = ['high', 'low', 'critical', 'blocker'];

export const timeCompareFilter = [
  { name: 'Daily - Yesterday', value: 'yesterday' },
  { name: 'Daily - Last Month', value: 'last_month' },
  { name: 'Weekly - Last Week', value: 'last_week_aggregate' },
  { name: 'Weekly - Last Month', value: 'same_week_last_month' }
];

export const defaultAppliedFilters = {
  time_comparison_category: ['same_week_last_year', 'same_week_last_month', 'last_week_aggregate'],
  threat_filter: []
};

// --- Total footer row data for table
let emptyTotalInitialState = {};
const timeDranuilityArray = ['today', 'yesterday', 'this_week', 'last_week', 'this_month', 'last_month'];
timeDranuilityArray &&
  timeDranuilityArray.forEach(item => {
    emptyTotalInitialState[item] = {
      sum_insights_published: 0,
      sum_reco_executed: 0,
      sum_reco_published: 0,
      sum_reco_pending_approval: 0
    };
  });
export const summeryTableTotalInitialState = { ...emptyTotalInitialState };

// --- User roles
export const USER_ROLES = {
  NOTIFICATION_MANAGER: {
    isSuperAdmin: false,
    isAdmin: false,
    canAddManualInsight: false,
    canReportInsight: false,
    canPublishInsight: false,
    canDeleteInsight: false,
    canLinkInsightAndReco: false,
    canCreateReco: false,
    canApproveReco: false,
    canRejectReco: false,
    canDeleteReco: false,
    canBroadcastReco: false,
    canAccessAllStores: false,
    canCreateGrowthUser: false,
    canAddTemplates: false,
    canAddNotification: true
  },
  SUPER_ADMIN: {
    isSuperAdmin: true,
    isAdmin: false,
    canAddManualInsight: true,
    canReportInsight: true,
    canPublishInsight: true,
    canDeleteInsight: true,
    canLinkInsightAndReco: true,
    canCreateReco: true,
    canApproveReco: true,
    canRejectReco: true,
    canDeleteReco: true,
    canBroadcastReco: true,
    canAccessAllStores: true,
    canCreateGrowthUser: true,
    canAddTemplates: true,
    canAddNotification: false
  },

  ADMIN: {
    isSuperAdmin: false,
    isAdmin: true,
    canAddManualInsight: true,
    canReportInsight: true,
    canPublishInsight: true,
    canDeleteInsight: true,
    canLinkInsightAndReco: true,
    canCreateReco: true,
    canApproveReco: false,
    canRejectReco: false,
    canDeleteReco: true,
    canBroadcastReco: false,
    canAccessAllStores: false,
    canCreateGrowthUser: false,
    canAddTemplates: false,
    canAddNotification: false
  },

  USER: {
    isSuperAdmin: false,
    isAdmin: false,
    canAddManualInsight: true,
    canReportInsight: true,
    canPublishInsight: true,
    canDeleteInsight: true,
    canLinkInsightAndReco: true,
    canCreateReco: true,
    canApproveReco: false,
    canRejectReco: false,
    canDeleteReco: false,
    canBroadcastReco: false,
    canAccessAllStores: false,
    canCreateGrowthUser: false,
    canAddTemplates: false,
    canAddNotification: false
  },

  DEFAULT: {
    isSuperAdmin: false,
    isAdmin: false,
    canAddManualInsight: false,
    canReportInsight: false,
    canPublishInsight: false,
    canDeleteInsight: false,
    canLinkInsightAndReco: false,
    canCreateReco: false,
    canApproveReco: false,
    canRejectReco: false,
    canDeleteReco: false,
    canBroadcastReco: false,
    canAccessAllStores: false,
    canCreateGrowthUser: false,
    canAddTemplates: false,
    canAddNotification: false
  }
};
export const initialStateForRecommendationGroup = {
  id: nanoid(),
  metric: '',
  dimension: '',
  dimenstion_value: [],
  time_dimension: null,
  dateRange: [moment(moment().subtract(30, 'd')).format('YYYY-MM-DD'), moment(moment()).format('YYYY-MM-DD')],
  granularity: 'day',
  is_chart_visible: true,
  is_chart_error: false,
  chart_query: [],
  filters: []
};

export const initialStateForChannelSubChannelGroup = {
  id: nanoid(),
  action_channel: '',
  action_sub_channel: ''
};

export const publishedChildTabsInitialState = [
  {
    id: nanoid(),
    title: 'New',
    end_user_status: 'to_do',
    admin_status: 'published'
  },
  {
    id: nanoid(),
    title: 'In Progress',
    end_user_status: 'in_progress',
    admin_status: 'published'
  },
  {
    id: nanoid(),
    title: 'Completed',
    end_user_status: 'done',
    admin_status: 'published'
  },
  {
    id: nanoid(),
    title: 'Ignored',
    end_user_status: 'ignore',
    admin_status: 'published'
  },
  {
    id: nanoid(),
    title: 'Failed',
    end_user_status: 'execution_failed',
    admin_status: 'published'
  }
];

export const convertQueryBystatus = (queryArray, storeFilter, sourceName) => {
  const convertedQueryArray =
    !_isEmpty(queryArray) &&
    queryArray?.map(singlequeryObject => {
      const filterArray = singlequeryObject?.filters;
      return {
        ...singlequeryObject,
        filters: filterArray?.map(singleFilterObject => {
          switch (singleFilterObject?.dimension) {
            case 'MgOrders.status':
              return {
                ...singleFilterObject,
                values: storeFilter || ['pending', 'complete', 'processing']
              };
            case 'MgOrders.sourceName':
              return !_isEmpty(sourceName)
                ? {
                    ...singleFilterObject,
                    values: sourceName
                  }
                : filterArray.splice(filterArray.indexOf(singleFilterObject?.dimension), 1);

            default:
              return singleFilterObject;
          }
        })
      };
    });

  return convertedQueryArray || [];
};

export const channelQueries = [
  {
    title: 'Total Facebook Spends',
    channel_name: 'Facebook',
    query: [
      {
        measures: ['FbAdsAdPerformance.adSpend'],
        timeDimensions: [
          {
            dimension: 'FbAdsAdPerformance.reportDate',
            granularity: 'day'
          }
        ],
        order: {
          'FbAdsAdPerformance.reportDate': 'asc'
        },
        filters: []
      }
    ]
  },
  {
    title: 'Total Google Spends',
    channel_name: 'Google',
    query: [
      {
        filters: [
          {
            member: 'MarketingOverall.channel',
            operator: 'contains',
            values: ['Google Ads']
          }
        ],
        measures: ['MarketingOverall.TotalAdSpend'],
        timezone: 'Asia/Kolkata',
        dimensions: ['MarketingOverall.channel'],
        timeDimensions: [
          {
            dimension: 'MarketingOverall.reportDate'
            // dateRange: ['2022-12-28', '2023-01-04']
          }
        ]
      }
    ]
  },
  {
    title: 'Total Revenue',
    channel_name: 'Total Revenue',
    query: [
      {
        filters: [
          {
            values: ['pending', 'complete', 'processing'],
            operator: 'equals',
            dimension: 'MgOrders.status'
          },
          {
            values: ['web'],
            operator: 'equals',
            dimension: 'MgOrders.sourceName'
          }
        ],
        measures: ['MgOrders.totalPrice'],
        timezone: 'Asia/Kolkata',
        renewQuery: true,
        timeDimensions: [
          {
            dateRange: 'Last 7 days',
            dimension: 'MgOrders.createdAt',
            granularity: 'day'
          }
        ],
        aggregationTypes: {
          'MgOrders.totalPrice': 'sum'
        }
      }
    ]
  }
];
