import RevenueCalculator from 'components/RevenueCalculator/RevenueCalculatorForm/RevenueCalculator';
import RevenueCalculatorForm from 'components/RevenueCalculator/RevenueCalculatorForm/RevenueCalculatorForm/RevenueCalculatorForm';
import React from 'react';
import { useSelector } from 'react-redux';
import TopBar from '../../components/layout/TopBar';

const App = props => {
  const isDashboardRoute = window.location.pathname === '/dashboard' || false;
  const isRecommendationTemplateRoute = window.location.pathname === '/templates' || false;
  const isNotificationRoute = window.location.pathname === '/notification' || false;

  const { userData } = useSelector(state => state.User);

  if (window.location.pathname === '/revenue-calculator') {
    return <RevenueCalculator />;
  }

  if (window.location.pathname === `/revenue-calculator/${userData.organisationId}`) {
    return <RevenueCalculatorForm />;
  }

  return (
    <TopBar
      children={props?.children}
      isDashboardRoute={isDashboardRoute}
      isRecommendationTemplateRoute={isRecommendationTemplateRoute}
      isNotificationRoute={isNotificationRoute}
    />
  );
};

export default App;
