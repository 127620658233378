import { recommendationsConstants } from './constants';
import { message } from 'antd';
import Api from './api';
import moment from 'moment';
import { track, actions as TRACKER_ACTION } from '../../redux/tracker';
import { isEmpty } from 'lodash-es';

const recommendations = new Api('aarya/recommendations');
const recommendationRejectReasons = new Api('aarya');
const linkedInsights = new Api('insights/list');
const metricList = new Api('aarya/metrics');
const metricGroupMappingList = new Api('aarya/chart-builder');

const handleError = error => {
  const statusFirstDigit = error && (error.status / 100) >> 0;
  if (statusFirstDigit === 4 || statusFirstDigit === 5) {
    if (statusFirstDigit === 4) {
      message.error('Something went wrong. Please refresh the page once.');
    } else {
      if (error.response && error.response.data && error.response.data.detail) {
        message.error(error.response.data.detail);
      } else {
        message.error('Something went wrong. Please try again after some time.');
      }
    }
  }
};

// function to add read and published key for filter
function refactorRecommendation(data) {
  const dataArray = [...data];
  const startDate = moment(moment().subtract(8, 'd')).format('YYYY-MM-DD');
  const endDate = moment(moment().add(8, 'd')).format('YYYY-MM-DD');
  return (
    dataArray.map(item => {
      const recommendationObject = { ...item };
      // -- new property for filter the recos by result soon
      recommendationObject.result_soon =
        (item.expected_result_date &&
          item.end_user_status === 'done' &&
          moment(item.expected_result_date, 'YYYY-MM-DD').isBetween(startDate, endDate) && ['is_result_soon']) ||
        [];

      // -- new property for filter the recos by due soon
      recommendationObject.due_soon =
        (item.due_date &&
          ['in_progress', 'to_do', 'ignore'].includes(item.end_user_status) &&
          moment(item.due_date, 'YYYY-MM-DD').isBetween(startDate, endDate) && ['is_due_soon']) ||
        [];

      return recommendationObject;
    }) || []
  );
}

// --- Get All Recommendations
export const getMonthluPublishedCount = () => {
  let apiData = {};
  let errorObj = null;
  return async dispatch => {
    try {
      dispatch({ type: recommendationsConstants.GET_RECOMMENDATION_PUBLISHED_COUNT_REQUEST });
      apiData = await recommendations.getPublishedCount();

      if (apiData && apiData.hasError) {
        errorObj = apiData.error;
        throw new Error();
      } else {
        const { data } = apiData;
        dispatch({
          type: recommendationsConstants.GET_RECOMMENDATION_PUBLISHED_COUNT_SUCCESS,
          payload: { ...data }
        });
      }
    } catch (error) {
      handleError(errorObj);
      dispatch({
        type: recommendationsConstants.GET_RECOMMENDATION_PUBLISHED_COUNT_ERROR,
        payload: { errorObj }
      });
    }
  };
};

// --- Specific get recommendation action
export const getRecommendationById = id => {
  let apiData = {};
  let errorObj = null;
  return async dispatch => {
    try {
      dispatch({ type: recommendationsConstants.GET_RECOMMENDATION_BY_ID_REQUEST });
      apiData = await recommendations.getRecommendationById(id);
      const { data } = apiData;
      if (apiData && apiData.hasError) {
        errorObj = apiData.error;
        throw new Error();
      } else {
        const finalObject = { details: data };
        dispatch({
          type: recommendationsConstants.GET_RECOMMENDATION_BY_ID_SUCCESS,
          payload: { ...finalObject }
        });
      }
    } catch (error) {
      handleError(errorObj);
      dispatch({
        type: recommendationsConstants.GET_RECOMMENDATION_BY_ID_ERROR
      });
    }
  };
};

// --- Specific get recommendation action
export const getRecommendationLinkedInsights = insightArray => {
  let apiData = {};
  let errorObj = null;
  return async dispatch => {
    try {
      dispatch({ type: recommendationsConstants.GET_LINKED_INSIGHTS_REQUEST });
      apiData = await Promise.all(insightArray.map(id => linkedInsights.getRecommendationById(id)));

      if (apiData && apiData.hasError) {
        errorObj = apiData.error;
        throw new Error();
      } else {
        let linkedInsightList = [];
        apiData.forEach(item => {
          const { data } = item;
          linkedInsightList = [...linkedInsightList, data];
        });

        dispatch({
          type: recommendationsConstants.GET_LINKED_INSIGHTS_SUCCESS,
          payload: [...linkedInsightList]
        });
      }
    } catch (error) {
      handleError(errorObj);
      dispatch({
        type: recommendationsConstants.GET_LINKED_INSIGHTS_ERROR
      });
    }
  };
};

// -- create new recommendation
export const createNewRecommendation = (recommendation, history, activeTab) => {
  let apiData = {};
  let errorObj = null;
  return async dispatch => {
    try {
      dispatch({ type: recommendationsConstants.CREATE_NEW_RECOMMENDATION_REQUEST });
      const postApiData = await recommendations.createNewRecommendation(recommendation);
      if (postApiData && postApiData.hasError) {
        errorObj = postApiData.error;
        throw new Error();
      }
      apiData = await recommendations.getRecommendationBySelectedTab(activeTab);
      if (apiData && apiData.hasError) {
        errorObj = apiData.error;
        throw new Error();
      } else {
        const { data } = apiData;
        const updatedInsightData = { data: refactorRecommendation(data) };
        dispatch({
          type: recommendationsConstants.GET_RECOMMENDATIONS_SUCCESS,
          payload: { ...updatedInsightData }
        });
        message.success('Recommendation has been successfully created!');
        track(TRACKER_ACTION.recommendationCreate, recommendation);
        // -- To clear all linked history params.
        history.replace({ pathname: '/recommendations' });
      }
    } catch (error) {
      handleError(errorObj);
      dispatch({
        type: recommendationsConstants.CREATE_NEW_RECOMMENDATION_ERROR
      });
    }
  };
};

// --- Specific update recommendation action
export const updateRecommendationAction = (id, restParameters, activeTab) => {
  let apiData = {};
  let errorObj = null;
  return async dispatch => {
    try {
      dispatch({ type: recommendationsConstants.UPDATE_RECOMMENDATION_REQUEST });
      const postApiData = await recommendations.updateRecommendationAction(id, restParameters);
      if (postApiData && postApiData.hasError) {
        errorObj = postApiData.error;
        throw new Error();
      }
      apiData = await recommendations.getRecommendationBySelectedTab(activeTab);
      if (apiData && apiData.hasError) {
        errorObj = apiData.error;
        throw new Error();
      } else {
        const { data } = apiData;
        const updatedInsightData = { data: refactorRecommendation(data) };
        dispatch({
          type: recommendationsConstants.GET_RECOMMENDATIONS_SUCCESS,
          payload: { ...updatedInsightData }
        });
        message.success('Recommendation has been successfully updated!');
        track(TRACKER_ACTION.recommendationUpdate, { id, restParameters });
      }
    } catch (error) {
      handleError(errorObj);
      dispatch({
        type: recommendationsConstants.UPDATE_RECOMMENDATION_ERROR
      });
    }
  };
};

// --- Specific update recommendation action
export const deleteRecommendationAction = (id, activeTab) => {
  let apiData = {};
  let errorObj = null;
  return async dispatch => {
    try {
      dispatch({ type: recommendationsConstants.DELETE_RECOMMENDATION_REQUEST });
      const postApiData = await recommendations.deleteRecommendationAction(id);
      if (postApiData && postApiData.hasError) {
        errorObj = postApiData.error;
        throw new Error();
      }
      apiData = await recommendations.getRecommendationBySelectedTab(activeTab);
      if (apiData && apiData.hasError) {
        errorObj = apiData.error;
        throw new Error();
      } else {
        const { data } = apiData;
        const updatedInsightData = { data: refactorRecommendation(data) };
        dispatch({
          type: recommendationsConstants.GET_RECOMMENDATIONS_SUCCESS,
          payload: { ...updatedInsightData }
        });
        message.success('Recommendation has been successfully deleted!');
        track(TRACKER_ACTION.recommendationDelete, id);
      }
    } catch (error) {
      handleError(errorObj);
      dispatch({
        type: recommendationsConstants.DELETE_RECOMMENDATION_ERROR
      });
    }
  };
};

// Specific get recommendation action
export const clearSelectedRecommendation = () => {
  return async dispatch => {
    try {
      dispatch({
        type: recommendationsConstants.CLEAR_SELECTED_RECOMMENDATION
      });
    } catch (error) {}
  };
};

// Specific get recommendation action
export const clearLinkedInsight = () => {
  return async dispatch => {
    try {
      dispatch({
        type: recommendationsConstants.CLEAR_LINKED_INSIGHTS
      });
    } catch (error) {}
  };
};

// Specific get recommendation action
export const clearDimensionMapping = () => {
  return async dispatch => {
    try {
      dispatch({
        type: recommendationsConstants.CLEAR_DIMENSION_MAPPING
      });
    } catch (error) {}
  };
};

// --- Specific get recommendation action
export const getMetricList = () => {
  let apiData = {};
  let errorObj = null;
  return async dispatch => {
    try {
      dispatch({ type: recommendationsConstants.GET_METRIC_LIST_REQUEST });
      apiData = await metricList.getMetricList();
      const { data } = apiData;
      if (apiData && apiData.hasError) {
        errorObj = apiData.error;
        throw new Error();
      } else {
        dispatch({
          type: recommendationsConstants.GET_METRIC_LIST_SUCCESS,
          payload: data
        });
      }
    } catch (error) {
      handleError(errorObj);
      dispatch({
        type: recommendationsConstants.GET_METRIC_LIST_ERROR
      });
    }
  };
};

// --- Specific get recommendation action
export const getMetricGroupMappingList = () => {
  let apiData = {};
  let errorObj = null;
  return async dispatch => {
    try {
      dispatch({ type: recommendationsConstants.GET_METRICS_GROUP_MAPPING_REQUEST });
      apiData = await metricGroupMappingList.getMetricGroupMappingList();
      const { data } = apiData;
      if (apiData && apiData.hasError) {
        errorObj = apiData.error;
        throw new Error();
      } else {
        dispatch({
          type: recommendationsConstants.GET_METRICS_GROUP_MAPPING_SUCCESS,
          payload: data
        });
      }
    } catch (error) {
      handleError(errorObj);
      dispatch({
        type: recommendationsConstants.GET_METRICS_GROUP_MAPPING_ERROR
      });
    }
  };
};

// --- Specific get recommendation action
export const setMetricGroupMappingList = mappingObject => {
  let errorObj = null;
  return async dispatch => {
    try {
      dispatch({ type: recommendationsConstants.SET_METRICS_GROUP_MAPPING_REQUEST });

      if (!isEmpty(mappingObject)) {
        dispatch({
          type: recommendationsConstants.SET_METRICS_GROUP_MAPPING_SUCCESS,
          payload: mappingObject
        });
      }
    } catch (error) {
      handleError(errorObj);
      dispatch({
        type: recommendationsConstants.SET_METRICS_GROUP_MAPPING_ERROR
      });
    }
  };
};

// TODO: Handle in better way that can be standardize in 2.0
export const discardSuggestedRecommendation = async recommendation => {
  const postApiData = await recommendations.updateRecommendationAction(recommendation.id, recommendation);
  if (postApiData && postApiData.hasError) {
    throw new Error();
  }
  track(TRACKER_ACTION.rejectSuggestedRecommendation, recommendation);
  return postApiData;
};

export const getRejectReasons = async () => {
  try {
    const postApiData = await recommendationRejectReasons.getRejectReasons();
    if (postApiData && postApiData.hasError) {
      throw new Error();
    }
    return postApiData.data;
  } catch (error) {}
};

export const getRecommendationForSelectedTab = tabKey => {
  return async dispatch => {
    try {
      dispatch({ type: recommendationsConstants.GET_RECOMMENDATIONS_BY_ACTIVE_TAB_REQUEST });
      const { data } = await recommendations.getRecommendationBySelectedTab(tabKey);
      if (data && data.hasError) {
        throw new Error();
      }
      dispatch({ type: recommendationsConstants.GET_RECOMMENDATIONS_BY_ACTIVE_TAB_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: recommendationsConstants.GET_RECOMMENDATIONS_BY_ACTIVE_TAB_ERROR });
    }
  };
};
