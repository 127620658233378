import { RevenueCalculatorConstants } from './RevenueCalculatorConstant';

const initialState = {
  revenueCalculatorData: {},
  predictedRevenue: {}
};

export default function revenueCalculatorReducer(state = initialState, action) {
  switch (action.type) {
    case RevenueCalculatorConstants.GET_REVENUECALCULATOR_DATA_REQUEST:
      return {
        ...state,
        revenueCalculatorData: {
          loading: true,
          error: false
        }
      };
    case RevenueCalculatorConstants.GET_REVENUECALCULATOR_DATA_SUCCESS:
      return {
        ...state,
        revenueCalculatorData: {
          loading: false,
          error: false,
          data: action.payload
        }
      };
    case RevenueCalculatorConstants.GET_REVENUE_CALCULATOR_DATA_ERROR:
      return {
        ...state,
        revenueCalculatorData: {
          loading: false,
          error: true
        }
      };

    case RevenueCalculatorConstants.GET_PREDICTED_REVENUE_REQUEST:
      return {
        ...state,
        predictedRevenue: {
          loading: true,
          error: false
        }
      };

    case RevenueCalculatorConstants.GET_PREDICTED_REVENUE_SUCCESS:
      return {
        ...state,
        predictedRevenue: {
          loading: false,
          error: false,
          data: action.payload
        }
      };

    case RevenueCalculatorConstants.GET_PREDICTED_REVENUE_ERROR:
      return {
        ...state,
        predictedRevenue: {
          loading: false,
          error: true
        }
      };

    case RevenueCalculatorConstants.RESET_PREDICTED_REVENUE_DATA:
      return {
        ...state,
        predictedRevenue: {}
      };

    default:
      return state;
  }
}
