import { notificationConstants } from './NotificationConstants';
const initialState = {
  notification: {},
  singleNotification: {},
  organisationVertical: {},
  organisationList: {},
  usersFromOrganisation: {}
};

export default function Notification(state = initialState, action) {
  switch (action.type) {
    // --- get Notification
    case notificationConstants.GET_NOTIFICATION_REQUEST:
      return {
        ...state,
        notification: { notification_fetching_status: 'fetching' }
      };
    case notificationConstants.GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notification: { notification_fetching_status: 'success', data: action.payload }
      };
    case notificationConstants.GET_NOTIFICATION_ERROR:
      return {
        ...state,
        notification: { notification_fetching_status: 'fail' }
      };

    // --- get organisation vertical
    case notificationConstants.GET_ORGANISATION_VERTICAL_REQUEST:
      return {
        ...state,
        organisationVertical: { organisation_vertical_fetching_status: 'fetching' }
      };
    case notificationConstants.GET_ORGANISATION_VERTICAL_SUCCESS:
      return {
        ...state,
        organisationVertical: { organisation_vertical_fetching_status: 'success', data: action.payload }
      };
    case notificationConstants.GET_ORGANISATION_VERTICAL_ERROR:
      return {
        ...state,
        organisationVertical: { organisation_vertical_fetching_status: 'fail' }
      };

    // --- get organisation vertical
    case notificationConstants.GET_ORGANISATION_LIST_REQUEST:
      return {
        ...state,
        organisationList: { organisation_list_fetching_status: 'fetching' }
      };
    case notificationConstants.GET_ORGANISATION_LIST_SUCCESS:
      return {
        ...state,
        organisationList: { organisation_list_fetching_status: 'success', data: action.payload }
      };
    case notificationConstants.GET_ORGANISATION_LIST_ERROR:
      return {
        ...state,
        organisationList: { organisation_list_fetching_status: 'fail' }
      };

    // --- get User Notification
    case notificationConstants.GET_USERS_FROM_ORGANISATION_REQUEST:
      return {
        ...state,
        usersFromOrganisation: { user_notification_fetching_status: 'fetching' }
      };
    case notificationConstants.GET_USERS_FROM_ORGANISATION_SUCCESS:
      return {
        ...state,
        usersFromOrganisation: { user_notification_fetching_status: 'success', data: action.payload }
      };
    case notificationConstants.GET_USERS_FROM_ORGANISATION_ERROR:
      return {
        ...state,
        usersFromOrganisation: { user_notification_fetching_status: 'fail' }
      };

    // --- get single Notification
    case notificationConstants.GET_SINGLE_NOTIFICATION_REQUEST:
      return {
        ...state,
        singleNotification: { single_notification_fetching_status: 'fetching' }
      };
    case notificationConstants.GET_SINGLE_NOTIFICATION_SUCCESS:
      return {
        ...state,

        singleNotification: {
          notificationData: action.payload,
          single_notification_fetching_status: 'success'
        }
      };
    case notificationConstants.GET_SINGLE_NOTIFICATION_ERROR:
      return {
        ...state,
        singleNotification: { single_notification_fetching_status: 'fail' }
      };

    // --- create Notification
    case notificationConstants.CREATE_NOTIFICATION_REQUEST:
      return {
        ...state,
        notification: { create_notification_fetching_status: 'fetching' }
      };
    case notificationConstants.CREATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notification: {
          data: [...action.payload],
          create_notification_fetching_status: 'success'
        }
      };
    case notificationConstants.CREATE_NOTIFICATION_ERROR:
      return {
        ...state,
        notification: { create_notification_fetching_status: 'fail' }
      };

    // --- update Notification
    case notificationConstants.UPDATE_NOTIFICATION_REQUEST:
      return {
        ...state,
        notification: { update_notification_fetching_status: 'fetching' }
      };
    case notificationConstants.UPDATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notification: {
          data: [...action.payload],
          update_notification_fetching_status: 'success'
        }
      };
    case notificationConstants.UPDATE_NOTIFICATION_ERROR:
      return {
        ...state,
        notification: { update_notification_fetching_status: 'fail' }
      };

    case notificationConstants.CLEAR_NOTIFICATION:
      return {
        ...state,
        singleNotification: {},
        organisationVertical: {},
        organisationList: {},
        usersFromOrganisation: {}
      };
    default:
      return state;
  }
}
