import { EditFilled, EyeFilled, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Modal, Space, Spin, Tooltip } from 'antd';
import SMResult from 'components/SMResult/SMResult';
import { isEmpty as _isEmpty } from 'lodash-es';
import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearNotification, getNotification, getSingleNotification } from '../../redux/Notification/NotificationAction';
import { ADD_CONFIG, checkDatByTodayDate, EDIT_CONFIG, VIEW_CONFIG } from '../../utilityFunctions/utilityFunctions';
import CommonTable from '../CommonTable/CommonTable';
import Crud from './Crud';

const antIcon = <LoadingOutlined spin style={{ fontSize: 48 }} />;

const NotificationAdmin = () => {
  const dispatch = useDispatch();
  const [createNotifications, setCreateNotifications] = useState(false);
  const [viewNotifications, setViewNotifications] = useState(false);
  const [editNotifications, setEditNotifications] = useState(false);

  const notifications = useSelector(state => state?.NotificationReducer?.notification);
  const singleNotificationData = useSelector(state => state?.NotificationReducer?.singleNotification?.notificationData);

  const onClick = (id, setTemplate) => {
    dispatch(getSingleNotification(id));
    setTemplate(true);
  };

  const renderButton = record => {
    return (
      <Space>
        <Tooltip title='View Notification'>
          <Button onClick={() => onClick(record?.key, setViewNotifications)}>
            <EyeFilled />
          </Button>
        </Tooltip>

        <Tooltip
          title={`${
            checkDatByTodayDate(record?.created_at) ? 'you can not edit older Notification' : 'Edit Notification'
          }`}
        >
          <Button
            disabled={checkDatByTodayDate(record?.created_at)}
            onClick={() => onClick(record?.key, setEditNotifications)}
          >
            <EditFilled />
          </Button>
        </Tooltip>
      </Space>
    );
  };
  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority'
    },
    {
      title: 'Module',
      dataIndex: 'module',
      key: 'module'
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => renderButton(record)
    }
  ];
  const dataSource =
    notifications?.data &&
    notifications?.data?.map(singleNotification => {
      return {
        key: singleNotification?.id,
        title: singleNotification?.title,
        description: singleNotification?.description,
        priority: singleNotification?.priority,
        module: singleNotification?.module,
        created_at: singleNotification?.created_at
      };
    });

  useEffect(() => {
    dispatch(getNotification());
  }, [dispatch]);

  const renderNotificationBody = () => {
    if (notifications?.notification_fetching_status === 'fetching') {
      return <SMResult type='loader' message='Loading data' />;
    }

    if (notifications?.notification_fetching_status === 'fail') {
      return <SMResult type='error' message='Unable to Fetch Data' />;
    }

    if (_isEmpty(notifications?.data)) {
      return <SMResult type='empty' message='No data available' />;
    }
    return (
      <div className='pageWrapper_body'>
        <div className='container-fluid'>
          <CommonTable type='templates' dataSource={dataSource} columns={columns} />
        </div>
      </div>
    );
  };

  return (
    <>
      <div className='c-pageWrapper'>
        <div className='pageWrapper_header'>
          <div className='container-fluid text-right'>
            <Button type='primary' onClick={() => setCreateNotifications(true)}>
              <PlusOutlined />
              Create Notification
            </Button>
          </div>
        </div>
        {renderNotificationBody()}
      </div>

      <Modal
        destroyOnClose={true}
        footer={false}
        title='Add New Notifications'
        visible={createNotifications}
        afterClose={() => dispatch(clearNotification())}
        width={760}
        onCancel={() => {
          setCreateNotifications(false);
          Modal.destroyAll();
        }}
      >
        <Suspense fallback={<Spin indicator={antIcon} />}>
          <Crud type={ADD_CONFIG} closeModal={setCreateNotifications} />
        </Suspense>
      </Modal>
      <Modal
        destroyOnClose={true}
        footer={false}
        title='Edit Notifications'
        visible={editNotifications}
        width={760}
        afterClose={() => dispatch(clearNotification())}
        onCancel={() => {
          dispatch(clearNotification());
          setEditNotifications(false);
          Modal.destroyAll();
        }}
      >
        <Suspense fallback={<Spin indicator={antIcon} />}>
          <Crud
            closeModal={setEditNotifications}
            singleNotificationData={singleNotificationData}
            type={EDIT_CONFIG}
            notifications={notifications}
          />
        </Suspense>
      </Modal>
      <Modal
        destroyOnClose={true}
        footer={false}
        title='View Notifications'
        visible={viewNotifications}
        width={760}
        afterClose={() => dispatch(clearNotification())}
        onCancel={() => {
          dispatch(clearNotification());
          setViewNotifications(false);
          Modal.destroyAll();
        }}
      >
        <Suspense fallback={<Spin indicator={antIcon} />}>
          <Crud
            closeModal={setViewNotifications}
            singleNotificationData={singleNotificationData}
            type={VIEW_CONFIG}
            notifications={notifications}
          />
        </Suspense>
      </Modal>
    </>
  );
};

export default NotificationAdmin;
