import Api from '../api';

export default class Metadata extends Api {
  getRecommendationTemplate = async () => {
    try {
      const data = await this.get(`/recommendation-template/`);
      return data;
    } catch (error) {
      return error;
    }
  };

  getSingleRecommendationTemplate = async recommendationTemplateId => {
    try {
      const data = await this.get(`/recommendation-template/${recommendationTemplateId}/`);
      return data;
    } catch (error) {
      return error;
    }
  };

  createRecommendationTemplate = async recommendationTemplateData => {
    try {
      const data = await this.post(`/recommendation-template/`, recommendationTemplateData);
      return data;
    } catch (error) {
      return error;
    }
  };

  updateRecommendationTemplate = async (recommendationTemplateData, recommendationTemplateId) => {
    try {
      const data = await this.patch(
        `/recommendation-template/${recommendationTemplateId}/`,
        recommendationTemplateData
      );
      return data;
    } catch (error) {
      return error;
    }
  };

  deleteRecommendationTemplate = async recommendationTemplateId => {
    try {
      const data = await this.delete(`/recommendation-template/${recommendationTemplateId}/`);
      return data;
    } catch (error) {
      return error;
    }
  };
}
