import { Button, Card, DatePicker, Divider, Space, Tooltip } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { isEmpty as _isEmpty } from 'lodash-es';
import { getConstantDssMetadata, getVariableDssMetadata } from 'redux/MetaData/metadataAction';
import DSSMetadataConstantDetails from './DSSMetadataConstantDetails/DSSMetadataConstantDetails';
import DSSMetadataConstantForm from './DSSMetadataConstantForm/DSSMetadataConstantForm';
import DSSMetadataVariableDetails from './DSSMetadataVariableDetails/DSSMetadataVariableDetails';
import DSSMetadataVariableForm from './DSSMetadataVariableForm/DSSMetadataVariableForm';
import DSSMetadataCreateVariableForm from './DSSMetadataVariableForm/DSSMetadataCreateVariableForm';

const DSSMetadata = () => {
  const { dssConstantMetadata, dssVariableMetadata } = useSelector(state => state.MetaData);

  const [dssMetadataConstantToggleForm, setDssMetadataConstantToggleForm] = useState(false);
  const [dssMetadataVariableToggleForm, setDssMetadataVariableToggleForm] = useState(false);
  const [dssMetadataCreateVariableToggleForm, setDssMetadataCreateVariableToggleForm] = useState(false);
  const [editFormDisable, setEditFormDisable] = useState(false);
  const [addFormDisable, setAddFormDisable] = useState(false);

  const [dateForMonthPicker, setDateForMonthPicker] = useState(
    !_isEmpty(dssVariableMetadata)
      ? moment(dssVariableMetadata?.data?.variable_metrics_list?.REPORT_DATE).format('YYYY-MM')
      : moment().format('YYYY-MM')
  );
  const dispatch = useDispatch();

  const getDssMetaData = useCallback(async () => {
    dispatch(getConstantDssMetadata());
    dispatch(getVariableDssMetadata(moment().startOf('month').format('YYYY-MM-DD')));
  }, [dispatch]);

  useEffect(() => {
    getDssMetaData();
  }, [getDssMetaData]);

  const setStateForMonth = useCallback(async date => {
    setDateForMonthPicker(date);
  }, []);

  useEffect(() => {
    if (!_isEmpty(dssVariableMetadata?.data?.variable_metrics_list?.REPORT_DATE)) {
      setStateForMonth(moment(dssVariableMetadata?.data?.variable_metrics_list?.REPORT_DATE).format('YYYY-MM'));
    }
  }, [dssVariableMetadata, setStateForMonth]);

  return (
    <div className='container-fluid'>
      <Card
        title='DSS Metadata Variable Details'
        extra={
          <Space>
            <DatePicker
              picker='month'
              onChange={dateObject => {
                setDateForMonthPicker(moment(dateObject).format('YYYY-MM'));
                dispatch(getVariableDssMetadata(moment(dateObject).startOf('month').format('YYYY-MM-DD')));
              }}
              format={'YYYY-MM'}
              value={moment(dateForMonthPicker, 'YYYY-MM')}
              allowClear={false}
              disabled={editFormDisable || addFormDisable}
            />

            <Tooltip title={dssVariableMetadata?.status === 'FAILURE' ? 'No Data To Edit' : 'Edit Metadata'}>
              <Button
                disabled={dssVariableMetadata?.status === 'FAILURE' ? true : false}
                onClick={() => {
                  setDssMetadataVariableToggleForm(true);
                  setEditFormDisable(true);
                }}
              >
                Edit Metadata
              </Button>
            </Tooltip>
            <Tooltip title={dssVariableMetadata?.status === 'SUCCESS' ? 'No Data To Add' : 'Add Metadata'}>
              <Button
                disabled={dssVariableMetadata?.status === 'SUCCESS' ? true : false}
                onClick={() => {
                  setDssMetadataCreateVariableToggleForm(true);
                  setAddFormDisable(true);
                }}
              >
                Add Metadata
              </Button>
            </Tooltip>
          </Space>
        }
        bordered={false}
        className='customCard'
      >
        {!dssMetadataVariableToggleForm && !dssMetadataCreateVariableToggleForm && (
          <DSSMetadataVariableDetails dssVariableMetadata={dssVariableMetadata} />
        )}
        {dssMetadataVariableToggleForm && (
          <DSSMetadataVariableForm
            dssVariableMetadata={dssVariableMetadata}
            dateForMonthPicker={dateForMonthPicker}
            setDssMetadataVariableToggleForm={setDssMetadataVariableToggleForm}
            setEditFormDisable={setEditFormDisable}
          />
        )}
        {dssMetadataCreateVariableToggleForm && (
          <DSSMetadataCreateVariableForm
            dateForMonthPicker={dateForMonthPicker}
            setDateForMonthPicker={setDateForMonthPicker}
            setDssMetadataCreateVariableToggleForm={setDssMetadataCreateVariableToggleForm}
            setAddFormDisable={setAddFormDisable}
          />
        )}
      </Card>
      <Divider />
      <Card
        title='DSS Metadata Constant Details'
        extra={<Button onClick={() => setDssMetadataConstantToggleForm(true)}>Edit Metadata</Button>}
        bordered={false}
        className='customCard'
      >
        {!dssMetadataConstantToggleForm && <DSSMetadataConstantDetails dssConstantMetadata={dssConstantMetadata} />}
        {dssMetadataConstantToggleForm && (
          <DSSMetadataConstantForm
            dssConstantMetadata={dssConstantMetadata}
            setDssMetadataConstantToggleForm={setDssMetadataConstantToggleForm}
          />
        )}
      </Card>
      <Divider />
    </div>
  );
};

export default DSSMetadata;
