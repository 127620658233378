import {
  DeleteFilled,
  EditFilled,
  ExclamationCircleOutlined,
  EyeFilled,
  LoadingOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { Button, Modal, Space, Spin, Tooltip } from 'antd';
import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  clearRecommendationTemplate,
  deleteRecommendationTemplate,
  getRecommendationTemplate,
  getSingleRecommendationTemplate
} from '../../redux/RecommendationTemplate/recommendationTemplateAction';
import { ADD_CONFIG, EDIT_CONFIG, VIEW_CONFIG } from '../../utilityFunctions/utilityFunctions';
import CommonTable from '../CommonTable/CommonTable';
import Crud from './Crud';
const RecommendationTemplates = () => {
  const dispatch = useDispatch();
  const templates = useSelector(state => state?.RecommendationTemplate.templates?.data);
  const singleRecommendationTemplateData = useSelector(
    state => state?.RecommendationTemplate?.singleRecommendationTemplate?.templateData
  );
  const isSuperAdmin = useSelector(state => state?.User?.userData?.orbSuperAdmin);

  const [createTemplate, setCreateTemplate] = useState(false);
  const [viewTemplate, setViewTemplate] = useState(false);
  const [editTemplate, setEditTemplate] = useState(false);

  const onDeleteClick = id => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete?',
      okText: 'YES',
      cancelText: 'CANCEL',
      onOk() {
        dispatch(deleteRecommendationTemplate(id));
      }
    });
  };

  const onClick = (id, setTemplate) => {
    dispatch(getSingleRecommendationTemplate(id));
    setTemplate(true);
  };

  const renderButton = record => {
    return (
      <Space>
        <Tooltip title='View Template'>
          <Button onClick={() => onClick(record?.key, setViewTemplate)}>
            <EyeFilled />
          </Button>
        </Tooltip>

        <Tooltip title='Edit Template'>
          <Button onClick={() => onClick(record?.key, setEditTemplate)}>
            <EditFilled />
          </Button>
        </Tooltip>

        <Tooltip title='Delete Template'>
          <Button onClick={() => onDeleteClick(record?.key)}>
            <DeleteFilled />
          </Button>
        </Tooltip>
      </Space>
    );
  };

  const columns = [
    {
      title: 'Recommendation Type',
      dataIndex: 'recommendation_types',
      key: 'recommendation_types'
    },
    {
      title: 'Template name',
      dataIndex: 'template_name',
      key: 'template_name'
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true
    },
    {
      title: 'Analysis Description',
      dataIndex: 'analysis_detail',
      key: 'analysis_detail',
      ellipsis: true
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => renderButton(record)
    }
  ];

  const dataSource =
    templates &&
    templates?.map(singleTemplate => {
      return {
        key: singleTemplate?.id,
        recommendation_types: singleTemplate?.recommendation_types,
        template_name: singleTemplate?.template_name,
        title: singleTemplate?.title,
        description: singleTemplate?.description,
        analysis_detail: singleTemplate?.analysis_detail
      };
    });

  const antIcon = <LoadingOutlined spin style={{ fontSize: 48 }} />;

  useEffect(() => {
    dispatch(getRecommendationTemplate());
  }, [dispatch]);

  if (!isSuperAdmin) {
    return <Redirect to='/' />;
  }

  return (
    <>
      <div className='c-pageWrapper'>
        <div className='pageWrapper_header'>
          <div className='container-fluid text-right'>
            <Button type='primary' onClick={() => setCreateTemplate(true)}>
              <PlusOutlined />
              Create New Template
            </Button>
          </div>
        </div>
        <div className='pageWrapper_body'>
          <div className='container-fluid'>
            <CommonTable type='templates' dataSource={dataSource} columns={columns} />
          </div>
        </div>
      </div>

      <Modal
        destroyOnClose={true}
        footer={false}
        title='Add New Template'
        visible={createTemplate}
        width={760}
        onCancel={() => {
          setCreateTemplate(false);
          Modal.destroyAll();
        }}
      >
        <Suspense fallback={<Spin indicator={antIcon} />}>
          <Crud type={ADD_CONFIG} closeModal={setCreateTemplate} templates={templates} />
        </Suspense>
      </Modal>
      <Modal
        destroyOnClose={true}
        footer={false}
        title='Edit Template'
        visible={editTemplate}
        width={760}
        onCancel={() => {
          dispatch(clearRecommendationTemplate());
          setEditTemplate(false);
          Modal.destroyAll();
        }}
      >
        <Suspense fallback={<Spin indicator={antIcon} />}>
          <Crud
            closeModal={setEditTemplate}
            type={EDIT_CONFIG}
            singleRecommendationTemplateData={singleRecommendationTemplateData}
            templates={templates}
          />
        </Suspense>
      </Modal>
      <Modal
        destroyOnClose={true}
        footer={false}
        title='View Template'
        visible={viewTemplate}
        width={760}
        onCancel={() => {
          dispatch(clearRecommendationTemplate());
          setViewTemplate(false);
          Modal.destroyAll();
        }}
      >
        <Suspense fallback={<Spin indicator={antIcon} />}>
          <Crud
            closeModal={setViewTemplate}
            type={VIEW_CONFIG}
            singleRecommendationTemplateData={singleRecommendationTemplateData}
            templates={templates}
          />
        </Suspense>
      </Modal>
    </>
  );
};
export default RecommendationTemplates;
