import Api from './api';
import { insightsConstants } from './constants';
import { forEach as _forEach, isEmpty as _isEmpty, isArray as _isArray } from 'lodash-es';

const insights = new Api('search/');
const polaris = new Api('polaris/');

export const getInsightById = id => {
  return async dispatch => {
    dispatch({ type: insightsConstants.GET_INSIGHTS_BY_ID_REQUEST });
    try {
      const { data } = await insights.getInsightById(id);
      dispatch({ type: insightsConstants.GET_INSIGHTS_BY_ID_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: insightsConstants.GET_INSIGHTS_BY_ID_ERROR });
    }
  };
};

export const getChannel = () => {
  return async dispatch => {
    dispatch({ type: insightsConstants.GET_CHANNEL_REQUEST });
    try {
      const { data } = await polaris.getChannel();
      dispatch({
        type: insightsConstants.GET_CHANNEL_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch({ type: insightsConstants.GET_CHANNEL_ERROR });
    }
  };
};

export const getSubChannel = () => {
  const convertSubChannelAccordingToChannel = dataForSubChannel => {
    let obj = {};
    _forEach(dataForSubChannel, singleSubChannel => {
      const splitedVariable = singleSubChannel.split('-')?.[0];
      if (obj.hasOwnProperty(splitedVariable)) {
        obj[splitedVariable] = [...obj[splitedVariable], singleSubChannel];
      } else {
        obj[splitedVariable] = [singleSubChannel || 'OVERALL'];
      }
    });
    _forEach(obj, single_object => {
      !_isEmpty(single_object) && _isArray(single_object) && single_object.push('OVERALL');
    });
    obj['OVERALL'] = ['OVERALL'];
    return obj;
  };

  return async dispatch => {
    dispatch({ type: insightsConstants.GET_SUB_CHANNEL_REQUEST });
    try {
      const { data } = await polaris.getSubChannel();
      let convertedObject = {};
      if (data?.status === 'SUCCESS') {
        convertedObject = convertSubChannelAccordingToChannel(data?.data);
      }
      dispatch({ type: insightsConstants.GET_SUB_CHANNEL_SUCCESS, payload: convertedObject });
    } catch (error) {
      dispatch({ type: insightsConstants.GET_SUB_CHANNEL_ERROR });
    }
  };
};

export const clearSingleInsight = () => {
  return async dispatch => {
    try {
      dispatch({
        type: insightsConstants.CLEAR_SINGLE_INSIGHTS
      });
    } catch (error) {}
  };
};
