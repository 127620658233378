import Api from '../api';

export default class Recommendations extends Api {
  // Get recommendation by id
  getMetricList = async () => {
    try {
      const metricList = await this.get(`/`);
      return metricList;
    } catch (error) {
      return {
        hasError: true,
        error: error.response
      };
    }
  };

  // Get Metric Group Mapping
  getMetricGroupMappingList = async () => {
    try {
      const metricList = await this.get(`/`);
      return metricList;
    } catch (error) {
      return {
        hasError: true,
        error: error.response
      };
    }
  };

  // Get recommendation by id
  getPublishedCount = async () => {
    try {
      const monthlyPublishedCount = await this.get(`/metering/`);
      return monthlyPublishedCount;
    } catch (error) {
      return {
        hasError: true,
        error: error.response
      };
    }
  };

  // Get recommendation by id
  getRecommendationById = async id => {
    try {
      const data = await this.get(`/${id}/`);
      return data;
    } catch (error) {
      return {
        hasError: true,
        error: error.response
      };
    }
  };

  createNewRecommendation = async postData => {
    try {
      const data = await this.post(`/`, postData);
      return data;
    } catch (error) {
      return {
        hasError: true,
        error: error.response
      };
    }
  };

  updateRecommendationAction = async (id, restParameters) => {
    try {
      const data = await this.patch(`/${id}/`, restParameters);
      return data;
    } catch (error) {
      return {
        hasError: true,
        error: error.response
      };
    }
  };

  deleteRecommendationAction = async id => {
    try {
      const data = await this.patch(`/${id}/`, { is_deleted: true });
      return data;
    } catch (error) {
      return {
        hasError: true,
        error: error.response
      };
    }
  };

  handleRecommendationStatus = async (userObject, recommendationId) => {
    try {
      const data = await this.patch(`published/${recommendationId}/`, userObject);
      return data;
    } catch (error) {
      return {
        hasError: true,
        error: error.response
      };
    }
  };

  getRejectReasons = async () => {
    try {
      return await this.get(`/discard-reasons/`);
    } catch (error) {
      return {
        hasError: true,
        error: error.response
      };
    }
  };

  getRecommendationBySelectedTab = async activeTab => {
    try {
      const data = await this.get(`?admin_status=${activeTab}`);
      return data;
    } catch (error) {
      return {
        hasError: true,
        error: error.response
      };
    }
  };
}
