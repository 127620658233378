export const notificationConstants = {
  GET_NOTIFICATION_REQUEST: 'Notification/GET_NOTIFICATION_REQUEST',
  GET_NOTIFICATION_SUCCESS: 'Notification/GET_NOTIFICATION_SUCCESS',
  GET_NOTIFICATION_ERROR: 'Notification/GET_NOTIFICATION_ERROR',

  GET_ORGANISATION_VERTICAL_REQUEST: 'Notification/GET_ORGANISATION_VERTICAL_REQUEST',
  GET_ORGANISATION_VERTICAL_SUCCESS: 'Notification/GET_ORGANISATION_VERTICAL_SUCCESS',
  GET_ORGANISATION_VERTICAL_ERROR: 'Notification/GET_ORGANISATION_VERTICAL_ERROR',

  GET_ORGANISATION_LIST_REQUEST: 'Notification/GET_ORGANISATION_LIST_REQUEST',
  GET_ORGANISATION_LIST_SUCCESS: 'Notification/GET_ORGANISATION_LIST_SUCCESS',
  GET_ORGANISATION_LIST_ERROR: 'Notification/GET_ORGANISATION_LIST_ERROR',

  GET_USERS_FROM_ORGANISATION_REQUEST: 'Notification/GET_USERS_FROM_ORGANISATION_REQUEST',
  GET_USERS_FROM_ORGANISATION_SUCCESS: 'Notification/GET_USERS_FROM_ORGANISATION_SUCCESS',
  GET_USERS_FROM_ORGANISATION_ERROR: 'Notification/GET_USERS_FROM_ORGANISATION_ERROR',

  GET_SINGLE_NOTIFICATION_REQUEST: 'Notification/GET_SINGLE_NOTIFICATION_REQUEST',
  GET_SINGLE_NOTIFICATION_SUCCESS: 'Notification/GET_SINGLE_NOTIFICATION_SUCCESS',
  GET_SINGLE_NOTIFICATION_ERROR: 'Notification/GET_SINGLE_NOTIFICATION_ERROR',

  CREATE_NOTIFICATION_REQUEST: 'Notification/CREATE_NOTIFICATION_REQUEST',
  CREATE_NOTIFICATION_SUCCESS: 'Notification/CREATE_NOTIFICATION_SUCCESS',
  CREATE_NOTIFICATION_ERROR: 'Notification/CREATE_NOTIFICATION_ERROR',

  UPDATE_NOTIFICATION_REQUEST: 'Notification/UPDATE_NOTIFICATION_REQUEST',
  UPDATE_NOTIFICATION_SUCCESS: 'Notification/UPDATE_NOTIFICATION_SUCCESS',
  UPDATE_NOTIFICATION_ERROR: 'Notification/UPDATE_NOTIFICATION_ERROR',

  CLEAR_NOTIFICATION: 'Notification/CLEAR_NOTIFICATION'
};
