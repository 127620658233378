import { message } from 'antd';
import Api from './NotificationAPI';
import { notificationConstants } from './NotificationConstants';

const notification = new Api('polaris');

export const getNotification = () => {
  return async dispatch => {
    try {
      dispatch({ type: notificationConstants.GET_NOTIFICATION_REQUEST });
      const { data } = await notification.getNotification();

      dispatch({
        type: notificationConstants.GET_NOTIFICATION_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: notificationConstants.GET_NOTIFICATION_ERROR
      });
    }
  };
};

export const getOrganisationVertical = () => {
  return async dispatch => {
    try {
      dispatch({ type: notificationConstants.GET_ORGANISATION_VERTICAL_REQUEST });
      const { data } = await notification.getOrganisationVertical();

      dispatch({
        type: notificationConstants.GET_ORGANISATION_VERTICAL_SUCCESS,
        payload: data?.organisation_verticals
      });
    } catch (error) {
      dispatch({
        type: notificationConstants.GET_ORGANISATION_VERTICAL_ERROR
      });
    }
  };
};

export const getOrganisationList = storeType => {
  return async dispatch => {
    try {
      dispatch({ type: notificationConstants.GET_ORGANISATION_LIST_REQUEST });
      const { data } = await notification.getOrganisationList(storeType);

      dispatch({
        type: notificationConstants.GET_ORGANISATION_LIST_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: notificationConstants.GET_ORGANISATION_LIST_ERROR
      });
    }
  };
};

export const getUserFromOrganisation = (stores, storeType) => {
  return async dispatch => {
    try {
      dispatch({ type: notificationConstants.GET_USERS_FROM_ORGANISATION_REQUEST });
      const { data } = await notification.getUserFromOrganisation(stores, storeType);

      dispatch({
        type: notificationConstants.GET_USERS_FROM_ORGANISATION_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: notificationConstants.GET_USERS_FROM_ORGANISATION_ERROR
      });
    }
  };
};

export const getSingleNotification = notificationId => {
  return async dispatch => {
    try {
      dispatch({ type: notificationConstants.GET_SINGLE_NOTIFICATION_REQUEST });
      const { data } = await notification.getSingleNotification(notificationId);
      dispatch({
        type: notificationConstants.GET_SINGLE_NOTIFICATION_SUCCESS,
        payload: data
      });
    } catch (error) {
      message.error('Fetching single Notification Failed');
      dispatch({
        type: notificationConstants.GET_SINGLE_NOTIFICATION_ERROR
      });
    }
  };
};

export const createNotification = notificationData => {
  return async dispatch => {
    dispatch({ type: notificationConstants.CREATE_NOTIFICATION_REQUEST });
    try {
      await notification.createNotification(notificationData);
      const { data } = await notification.getNotification();
      dispatch({
        type: notificationConstants.CREATE_NOTIFICATION_SUCCESS,
        payload: data
      });
      message.success('Notification Created Successfully');
    } catch (error) {
      message.error('Notification Updating Failed');
      dispatch({
        type: notificationConstants.CREATE_NOTIFICATION_ERROR
      });
    }
  };
};

export const updateNotification = (notificationData, notificationId) => {
  return async dispatch => {
    dispatch({ type: notificationConstants.UPDATE_NOTIFICATION_REQUEST });
    try {
      await notification.updateNotification(notificationData, notificationId);
      const { data } = await notification.getNotification();
      dispatch({
        type: notificationConstants.UPDATE_NOTIFICATION_SUCCESS,
        payload: data
      });
      message.success('Notification Updated Successfully');
    } catch (error) {
      message.error('Notification Updating Failed');
      dispatch({
        type: notificationConstants.UPDATE_NOTIFICATION_ERROR
      });
    }
  };
};

export const clearNotification = () => {
  return async dispatch => {
    try {
      dispatch({
        type: notificationConstants.CLEAR_NOTIFICATION
      });
    } catch (error) {
      return false;
    }
  };
};
