import { Button, Card, Col, Divider, Form, Input, Modal, Row, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIncrementalityChecker, getIncrementalityPredictor } from 'redux/RevenueCalculator/RevenueCalculatorActions';
import { isEmpty as _isEmpty, isArray as _isArray } from 'lodash-es';
import SMResult from 'components/SMResult/SMResult';
import { Link } from 'react-router-dom';
import logo from '../../../../assets/images/graas_white.svg';
import { channelQueries } from 'utilityFunctions/InitialStates';
import { ChannelQueryData } from 'components/RevenueCalculator/ChannelQueryData/ChannelQueryData';
import { RevenueCalculatorConstants } from 'redux/RevenueCalculator/RevenueCalculatorConstant';

const RevenueCalculatorForm = () => {
  const dispatch = useDispatch();
  const { userData } = useSelector(state => state.User);
  const [form] = Form.useForm();
  const { revenueCalculatorData, predictedRevenue } = useSelector(state => state.revenueCalculator);
  const [isModalOpen, setIsModalOpen] = useState(true);

  useEffect(() => {
    dispatch(getIncrementalityChecker());
  }, [dispatch]);

  const onUpdate = values => {
    for (let i in values) {
      values[i] = Number(values[i]);
    }
    dispatch(getIncrementalityPredictor(values));
    setIsModalOpen(true);
  };

  useEffect(() => {
    return () => dispatch({ type: RevenueCalculatorConstants.RESET_PREDICTED_REVENUE_DATA });
  }, [dispatch]);

  let googleSpendsArray = [];
  if (!_isEmpty(revenueCalculatorData?.data) && _isArray(revenueCalculatorData?.data?.feature_details)) {
    googleSpendsArray = revenueCalculatorData?.data?.feature_details.filter(item => item.channel_name === 'Google');
  }

  let facebookSpendsArray = [];
  if (!_isEmpty(revenueCalculatorData?.data) && _isArray(revenueCalculatorData?.data?.feature_details)) {
    facebookSpendsArray = revenueCalculatorData?.data?.feature_details.filter(item => item.channel_name === 'Facebook');
  }
  if (revenueCalculatorData?.loading && !revenueCalculatorData?.error) {
    return <SMResult type='loader' message='Loading revenue calculator data' />;
  }

  if (!revenueCalculatorData?.loading && revenueCalculatorData?.error) {
    return <SMResult type='error' message='Unable to Fetch Data' />;
  }

  if (predictedRevenue?.loading && !predictedRevenue?.error) {
    return <SMResult type='loader' message='Loading predicted revenue' />;
  }

  if (!predictedRevenue?.loading && predictedRevenue?.error) {
    return <SMResult type='error' message='Unable to Fetch Data' />;
  }

  const PredictedRevenueData = () => {
    return (
      <Card className='card'>
        <div className='content'>
          Expected Revenue with above Spends is{' '}
          <span> ₹{Number(predictedRevenue?.data?.predicted_revenue).toFixed(2) || 0}</span>
        </div>
        <Divider style={{ borderTop: '1px solid #ffffff' }} />
        <Space direction='horizontal' size='large'>
          <Link to='/revenue-calculator'>
            <Button shape='round' size='large' className='btn-ok'>
              ok
            </Button>
          </Link>
          <Button shape='round' size='large' className='btn-next' type='link' onClick={() => setIsModalOpen(false)}>
            Try Again
          </Button>
        </Space>
      </Card>
    );
  };

  const capatalizeString = str => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <div className='container'>
      <div className='header'>
        <div className='logo'>
          <img src={logo} alt='logo' />
        </div>
        <h1 className='heading'>Incrementality Calculator</h1>
      </div>
      <div className='form-component'>
        <p className='text'>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Perferendis repellendus illo maiores voluptates
          tempore! Provident, obcaecati! Optio, veritatis quidem autem ipsa minima obcaecati illum ipsam, dolorum
          dolores similique voluptate quos?
        </p>

        {!revenueCalculatorData?.loading &&
        (revenueCalculatorData?.error || !_isArray(revenueCalculatorData?.data?.feature_details)) ? (
          <Card className='card'>
            <ExclamationCircleOutlined style={{ fontSize: '1.7rem', marginBottom: '1rem' }} />
            <div>We couldn't find the algorithm that fits into your data. Please come back after a few weeks</div>
            <Divider style={{ borderTop: '1px solid #ffffff' }} />
            <Link to='/revenue-calculator'>
              <Button shape='round' size='large' className='btn-next' type='link'>
                Close
              </Button>
            </Link>
          </Card>
        ) : (
          <>
            <div className='text-center'>Selected store :{capatalizeString(userData?.organisationSet?.store_code)}</div>
            <div className='text-center'>
              Data available since : {revenueCalculatorData?.data?.data_available_since}
            </div>
            <div className='campaign'>
              {channelQueries.map(item => (
                <ChannelQueryData
                  key={item.title}
                  channelTitle={item.title}
                  channelName={item.channel_name}
                  queryData={item.query}
                />
              ))}
            </div>
            <p className='text text-center'>
              Enter your daily spends by campaign types in the below checkboxes. you can enter 0 if not spending on
              that.
            </p>
            <Form className='form-container' onFinish={onUpdate} form={form}>
              <Row gutter={24}>
                <Space size='small' align='start'>
                  <Col md={12}>
                    <div className='form-channel-title'>Google Spends</div>
                    {googleSpendsArray &&
                      googleSpendsArray.map(item => (
                        <Form.Item
                          label={capatalizeString(item.feature.replaceAll('_', ' ').toLowerCase())}
                          name={item.feature}
                          key={item.feature}
                          rules={[
                            {
                              required: true,
                              message: `Please Enter some value`
                            }
                          ]}
                          initialValue={item.average_adspent}
                        >
                          <Input style={{ color: '#ffffff' }} type='number' />
                        </Form.Item>
                      ))}
                  </Col>
                  <Col md={12}>
                    <div className='form-channel-title'>Facebook Spends</div>
                    {facebookSpendsArray &&
                      facebookSpendsArray.map(item => (
                        <Form.Item
                          label={capatalizeString(item.feature.replaceAll('_', ' ').toLowerCase())}
                          name={item.feature}
                          key={item.feature}
                          rules={[
                            {
                              required: true,
                              message: `Please Enter some value`
                            }
                          ]}
                          initialValue={item.average_adspent}
                        >
                          <Input style={{ color: '#ffffff' }} type='number' />
                        </Form.Item>
                      ))}
                  </Col>
                </Space>
              </Row>
              <Form.Item>
                <Button shape='round' size='large' className='btn-next' htmlType='submit'>
                  Calculate
                </Button>
              </Form.Item>
            </Form>
          </>
        )}
        {predictedRevenue?.data && (
          <Modal
            visible={isModalOpen}
            footer={null}
            wrapClassName='predictedRevenue-modal'
            onCancel={() => setIsModalOpen(false)}
          >
            <PredictedRevenueData />
          </Modal>
        )}
      </div>
    </div>
  );
};

export default RevenueCalculatorForm;
