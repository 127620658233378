import { Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';

const DiscardRecommendation = React.forwardRef((props, ref) => {
  const { reasons } = props;
  const [feedback, setFeedback] = useState('');
  const [selectedReason, setSelectedReason] = useState(reasons[0]?.reason);

  useEffect(() => {
    ref.current.state = { feedback, reason: selectedReason };
  }, [feedback, selectedReason, ref]);

  const onReasonChange = value => {
    setSelectedReason(value);
  };

  return (
    <>
      <div class='c-formElement'>
        <label>Please select reason to discard</label>
        <Select
          defaultValue={reasons[0]?.reason || 'Other'}
          style={{ 'min-width': '200px', 'margin-bottom': '12px' }}
          onChange={onReasonChange}
        >
          {reasons.map(({ id, reason }) => (
            <Select.Option value={reason}>{reason}</Select.Option>
          ))}
        </Select>
      </div>
      {selectedReason.toLocaleLowerCase().includes('other') && (
        <Input.TextArea
          value={feedback}
          onChange={e => setFeedback(e.target.value)}
          placeholder={'Let the user know why...'}
          autoSize={{ minRows: 4, maxRows: 5 }}
        />
      )}
    </>
  );
});

export default DiscardRecommendation;
