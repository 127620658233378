export const dashboardConstants = {
  GET_DASHBOARD_STAT_REQUEST: 'Dashboard/GET_DASHBOARD_STAT_REQUEST',
  GET_DASHBOARD_STAT_SUCCESS: 'Dashboard/GET_DASHBOARD_STAT_SUCCESS',
  GET_DASHBOARD_STAT_ERROR: 'Dashboard/GET_DASHBOARD_STAT_ERROR',

  GET_DASHBOARD_TODO_REQUEST: 'Dashboard/GET_DASHBOARD_TODO_REQUEST',
  GET_DASHBOARD_TODO_SUCCESS: 'Dashboard/GET_DASHBOARD_TODO_SUCCESS',
  GET_DASHBOARD_TODO_ERROR: 'Dashboard/GET_DASHBOARD_TODO_ERROR',

  GET_TOP_STORES_REQUEST: 'Dashboard/GET_TOP_STORES_REQUEST',
  GET_TOP_STORES_SUCCESS: 'Dashboard/GET_TOP_STORES_SUCCESS',
  GET_TOP_STORES_ERROR: 'Dashboard/GET_TOP_STORES_ERROR',

  GET_BOTTOM_STORES_REQUEST: 'Dashboard/GET_BOTTOM_STORES_REQUEST',
  GET_BOTTOM_STORES_SUCCESS: 'Dashboard/GET_BOTTOM_STORES_SUCCESS',
  GET_BOTTOM_STORES_ERROR: 'Dashboard/GET_BOTTOM_STORES_ERROR',

  GET_STORES_REPORTS_REQUEST: 'Dashboard/GET_STORES_REPORTS_REQUEST',
  GET_STORES_REPORTS_SUCCESS: 'Dashboard/GET_STORES_REPORTS_SUCCESS',
  GET_STORES_REPORTS_ERROR: 'Dashboard/GET_STORES_REPORTS_ERROR',

  GET_BUSINESS_UNIT_REQUEST: 'Dashboard/GET_BUSINESS_UNIT_REQUEST',
  GET_BUSINESS_UNIT_SUCCESS: 'Dashboard/GET_BUSINESS_UNIT_SUCCESS',
  GET_BUSINESS_UNIT_ERROR: 'Dashboard/GET_BUSINESS_UNIT_ERROR',

  CREATE_NEW_USER_REQUEST: 'Dashboard/CREATE_NEW_USER_REQUEST',
  CREATE_NEW_USER_SUCCESS: 'Dashboard/CREATE_NEW_USER_SUCCESS',
  CREATE_NEW_USER_ERROR: 'Dashboard/CREATE_NEW_USER_ERROR',
  CREATE_NEW_USER_CLEAR: 'Dashboard/CREATE_NEW_USER_CLEAR',

  GET_SUMMARY_REQUEST: 'Dashboard/GET_SUMMARY_REQUEST',
  GET_SUMMARY_SUCCESS: 'Dashboard/GET_SUMMARY_SUCCESS',
  GET_SUMMARY_ERROR: 'Dashboard/GET_SUMMARY_ERROR',

  CLEAR_DASHBOARD_STAT: 'Dashboard/CLEAR_DASHBOARD_STAT',
  GET_RECENT_ONBOARDED_STORES: 'Dashboard/GET_RECENT_ONBOARDED_STORES',
  SET_SELECTED_BUSINESS_UNIT: 'Dashboard/SET_SELECTED_BUSINESS_UNIT',
  CLEAR_BUSINESS_UNITS: 'Dashboard/CLEAR_BUSINESS_UNITS'
};
