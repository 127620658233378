import actions from './actions';

const initialState = {
  insight_data: [],
  insight_success: false,
  insight_delete_success: false,
  selected_insights: [],
  insight_time_configs: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_INSIGHT_DATA_RESPONSE:
      return { ...state, ...action.payload };
    case actions.SET_INSIGHT_DATA:
      return { ...state, ...action.payload };
    case actions.SET_INSIGHT_API_SUCCESS_REDUCER:
      return { ...state, ...action.payload };
    case actions.LINK_INSIGHT:
      return { ...state, selected_insights: [...action.payload] };
    case actions.GET_TIME_CONFIGS_NEW:
      return { ...state, insight_time_configs: action.payload };
    case actions.CLEAR_TIME_CONFIGS_NEW:
      return { ...state, insight_time_configs: action.payload };
    default:
      return state;
  }
}
