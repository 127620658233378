import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, Modal, Row, Select } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateAMDM } from '../../../redux/MetaData/metadataAction';
import { createAMDMArray } from '../../../utilityFunctions/utilityFunctions';
import '../Form.scss';
const AMDMForm = props => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [accountManager, setAccountManager] = useState();
  const [digitalMarketer, setDigitalMarketer] = useState();
  const { setAMDMToggleForm, managerData, organisationUser } = props;
  const formInitialValue = {
    accountManager: managerData?.accountManager,
    digitalMarketer: managerData?.digitalMarketer
  };
  const formItemLayout = {
    labelCol: {
      span: 24
    },
    wrapperCol: {
      span: 22
    }
  };
  const onUpdate = () => {
    if (accountManager || digitalMarketer) {
      const updatedAMDMData = createAMDMArray(accountManager, digitalMarketer);
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: `Are you sure you want to update Manager's data ?`,
        okText: 'YES',
        cancelText: 'CANCEL',
        onOk() {
          dispatch(updateAMDM(updatedAMDMData));
          setAMDMToggleForm(false);
        }
      });
    }
  };
  return (
    <Form onFinish={onUpdate} {...formItemLayout} form={form} layout={formItemLayout} initialValues={formInitialValue}>
      <Row gutter={24}>
        <Col md={12}>
          <div className='c-formElement'>
            <Form.Item
              name={'accountManager'}
              label='Account Manager'
              rules={[
                {
                  required: true,
                  message: 'Please select account manager'
                },
                {
                  type: 'email',
                  message: 'Please Enter valid email'
                }
              ]}
            >
              <Select
                onChange={accountManager => setAccountManager(accountManager)}
                name='accountManager'
                showSearch
                placeholder='Select Account manager'
              >
                {organisationUser &&
                  organisationUser?.map((singleOrgUser, index) => {
                    return (
                      <Select.Option key={index} value={singleOrgUser?.email}>
                        {singleOrgUser?.email}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </div>
        </Col>

        <Col md={12}>
          <div className='c-formElement'>
            <Form.Item
              name={'digitalMarketer'}
              label='Digital Marketer'
              rules={[
                {
                  required: true,
                  message: 'Please select digital marketer'
                },
                {
                  type: 'email',
                  message: 'Please Enter valid email'
                }
              ]}
            >
              <Select
                onChange={digitalMarketer => setDigitalMarketer(digitalMarketer)}
                name='digitalMarketer'
                showSearch
                placeholder='Select Digital Marketer'
              >
                {organisationUser &&
                  organisationUser?.map((singleOrgUser, index) => {
                    return (
                      <Select.Option key={index} value={singleOrgUser?.email}>
                        {singleOrgUser?.email}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </div>
        </Col>
      </Row>
      <Form.Item className='center'>
        <Button type='primary' htmlType='submit' style={{ marginRight: '10px', marginTop: '10px' }}>
          Update AM-DM
        </Button>
        <Button htmlType='button' onClick={() => setAMDMToggleForm(false)}>
          Close
        </Button>
      </Form.Item>
    </Form>
  );
};
export default AMDMForm;
