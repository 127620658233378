import Api from '../api';

export default class Metadata extends Api {
  getNotification = async () => {
    try {
      const data = await this.get(`/notifications/?all=True`);
      return data;
    } catch (error) {
      return error;
    }
  };

  getSingleNotification = async notificationId => {
    try {
      const data = await this.get(`/notifications/${notificationId}/`);
      return data;
    } catch (error) {
      return error;
    }
  };

  createNotification = async notificationData => {
    try {
      const data = await this.post(`/notifications/`, notificationData);
      return data;
    } catch (error) {
      return error;
    }
  };

  updateNotification = async (notificationData, notificationId) => {
    try {
      const data = await this.put(`/notifications/${notificationId}/`, notificationData);
      return data;
    } catch (error) {
      return error;
    }
  };
  getOrganisationVertical = async () => {
    try {
      const data = await this.get(`/organisation/vertical/list`);
      return data;
    } catch (error) {
      return error;
    }
  };

  getOrganisationList = async storeType => {
    const stringifyValue = JSON.stringify(storeType);
    try {
      const data = await this.get(`/organisation/list/?store_types=${stringifyValue}`);
      return data;
    } catch (error) {
      return error;
    }
  };

  getUserFromOrganisation = async (stores, userType) => {
    const stringifyValueForStores = JSON.stringify(stores);
    const stringifyValueForUserType = JSON.stringify(userType);
    try {
      const data = await this.get(
        `/organisation/user/?stores=${stringifyValueForStores}&user_type=${stringifyValueForUserType}`
      );
      return data;
    } catch (error) {
      return error;
    }
  };
}
