import React from 'react';
import { Card } from 'antd';

import LoginIcon from 'assets/icons/polaris-growthPlatform-logo.png';
import LoginBg from 'assets/icons/login_bg.jpg';

export default function Auth(props) {
  return (
    <div className='auth__page__wrapper'>
      <div className='auth__page__backgroud__wrapper'>
        <img className='auth__page__backgroud' src={LoginBg} alt='Background' />
      </div>
      <div className='auth__page__container'>
        <div className='auth__card__wrapper'>
          <Card>
            <div className='login__main__icon__wrapper'>
              <div className='logoContainer'>
                <img className='login__main__icon' src={LoginIcon} alt='Insights and Recommendations' />
              </div>
              <div className='titleContainer'>Insights and Recommendations Admin Tool</div>
            </div>

            {props.children}
          </Card>
        </div>
      </div>
    </div>
  );
}
