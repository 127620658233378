import {
  BulbOutlined,
  CrownOutlined,
  DashboardOutlined,
  FundViewOutlined,
  LogoutOutlined,
  ProjectOutlined,
  SaveOutlined,
  UserAddOutlined,
  UserOutlined,
  NotificationOutlined
} from '@ant-design/icons';
import { Col, Layout, Menu, Row, Space, Typography } from 'antd';
import { nanoid } from 'nanoid';
import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { connect, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import insightActions from 'redux/Insights/actions';
import UserActions from 'redux/User/actions';
import { fetchTitleFromUrl } from '../../../utilityFunctions/utilityFunctions';
import Account from './Account';
import RenderAvatar from './RenderAvatar';
import './TopBar.scss';

const { Header, Content, Sider } = Layout;
const { Title } = Typography;
const TopBar = props => {
  const location = useLocation();
  const newUserStatus = useSelector(state => state.Dashboard?.userCreateStatus);
  const userRoles = useSelector(state => state.User?.userRoles);

  const [collapsed, setCollapsed] = useState(false);
  const [title, setTitle] = useState('');

  const { isRecommendationTemplateRoute, isDashboardRoute, isNotificationRoute, children } = props;

  const { SubMenu } = Menu;

  const sideBarObject = [
    {
      id: nanoid(),
      title: 'Recommendations',
      redirectUrl: '/recommendations',
      isActiveFlag: true,
      icons: CrownOutlined,
      tooltipPlacement: 'right'
    },
    {
      id: nanoid(),
      title: 'Focused Insight',
      redirectUrl: '/spotlight',
      isActiveFlag: true,
      icons: FundViewOutlined,
      tooltipPlacement: 'right'
    },
    {
      id: nanoid(),
      title: 'Detailed Insights',
      redirectUrl: '/insights',
      isActiveFlag: true,
      icons: BulbOutlined,
      tooltipPlacement: 'right'
    },

    {
      id: nanoid(),
      title: 'Dashboard',
      redirectUrl: '/dashboard',
      isActiveFlag: true,
      icons: DashboardOutlined,
      tooltipPlacement: 'right'
    },
    {
      id: nanoid(),
      title: 'Metadata',
      children: [
        { title: 'Metadata', redirectUrl: '/metadata' },
        { title: 'DSS Metadata', redirectUrl: '/dss-metadata' }
      ],
      isActiveFlag: true,
      icons: SaveOutlined,
      tooltipPlacement: 'right'
    },
    {
      id: nanoid(),
      title: 'Templates',
      redirectUrl: '/templates',
      isActiveFlag: userRoles?.canAddTemplates,
      icons: ProjectOutlined,
      tooltipPlacement: 'right'
    },
    {
      id: nanoid(),
      title: 'Notification',
      redirectUrl: '/notification',
      isActiveFlag: userRoles?.canAddNotification,
      icons: NotificationOutlined,
      tooltipPlacement: 'right'
    }
  ];

  const onSwitchOrg = id => {
    props.switchOrg(id);
  };

  const onCollapse = collapsed => {
    setCollapsed(collapsed);
  };

  useEffect(() => {
    props.getInsightTimeConfigs();
    setTitle(fetchTitleFromUrl(location.pathname));
  }, [location.pathname, props]);

  return (
    <>
      <Helmet>
        <script type='text/javascript'>
          {`(function (c, l, a, r, i, t, y) {
  c[a] =
    c[a] ||
    function () {
      (c[a].q = c[a].q || []).push(arguments);
    };
  t = l.createElement(r);
  t.async = 1;
  t.src = "https://www.clarity.ms/tag/" + i;
  y = l.getElementsByTagName(r)[0];
  y.parentNode.insertBefore(t, y);
  console.log('Clarity Pixel fired 🔥');
})(window, document, "clarity", "script", "${process.env.REACT_APP_CLARITY_PIXEL}");`}
        </script>
        <script type='text/javascript'>
          {`
              window._mfq = window._mfq || [];
              (function(i) {
                var mf = document.createElement("script");
                mf.type = "text/javascript"; mf.defer = true;
                mf.src = i;
                document.getElementsByTagName("head")[0].appendChild(mf);
                console.log('Mouseflow Pixel fired with ORB admin dashboard 🔥');
              })("${process.env.REACT_APP_MOUSEFLOW_URL}");
            `}
        </script>
      </Helmet>
      <Layout className={`main-section ${collapsed ? 'isCollapsed' : ''}`} hasSider>
        <Sider className='main-sidebar' collapsible collapsed={collapsed} onCollapse={onCollapse}>
          <div className='sidebarMenu_holder'>
            <div className='menuHolder__body'>
              <Menu theme='dark' mode='inline' defaultSelectedKeys={['1']}>
                {sideBarObject?.map(singleSidebarObject => {
                  const IconsForSideBar = singleSidebarObject?.icons;
                  if (singleSidebarObject?.isActiveFlag) {
                    if (singleSidebarObject?.children) {
                      return (
                        <SubMenu title={singleSidebarObject.title} icon={<IconsForSideBar />}>
                          {singleSidebarObject?.children.map(child => (
                            <Menu.Item key={child?.title} icon={<IconsForSideBar />}>
                              <NavLink
                                activeClassName='active'
                                to={child?.redirectUrl}
                                className='navLink'
                              >
                                {child.title}
                              </NavLink>
                            </Menu.Item>
                          ))}
                        </SubMenu>
                      );
                    }
                     else {
                    return (
                      <Menu.Item key={singleSidebarObject?.id} icon={<IconsForSideBar />}>
                        <NavLink activeClassName='active' to={singleSidebarObject?.redirectUrl} className='navLink'>
                          {singleSidebarObject?.title}
                        </NavLink>
                      </Menu.Item>
                    );
                     }
                  }
                  return null;
                })}
                {userRoles?.canCreateGrowthUser && (
                  <SubMenu key='sub1' icon={<UserOutlined />} title='User'>
                    <Menu.Item
                      key='1'
                      loading={newUserStatus?.create_user_status === 'fetching'}
                      icon={<UserAddOutlined />}
                    >
                      <NavLink activeClassName='active' to='/inviteuser'>
                        Invite user on GP
                      </NavLink>
                    </Menu.Item>
                  </SubMenu>
                )}
              </Menu>
            </div>
            <div className='menuHolder__footer'>
              <Menu theme='dark' mode='inline'>
                <Menu.Item
                  onClick={() => {
                    props.logout(props.user);
                  }}
                  icon={<LogoutOutlined />}
                >
                  Logout
                </Menu.Item>
              </Menu>
            </div>
          </div>
        </Sider>
        <Layout className='sub-layout'>
          <Header className='c-header'>
            <div className='container-fluid'>
              <Row type='flex' align='middle'>
                <Col className='title' md={12}>
                  <Title level={3} className='m0'>
                    {title} 
                  </Title>
                </Col>
                <Col md={12}>
                  <div className='text-right'>
                    <Space>
                      {!isDashboardRoute && !isRecommendationTemplateRoute && !isNotificationRoute && (
                        <div className='text-left'>
                          <Account
                            options={props.organisations}
                            onSelect={onSwitchOrg}
                            defaultOrgId={props.currentOrgId}
                          />
                        </div>
                      )}
                      <RenderAvatar />
                    </Space>
                  </div>
                </Col>
              </Row>
            </div>
          </Header>
          <Content className='sub-content'>{children}</Content>
        </Layout>
      </Layout>
    </>
  );
};

var mapStateToProps = ({ User }) => ({
  organisations: User.organisations,
  currentOrgId: User.userData.organisationId,
  user: User.userData
});

var mapDispatchToProps = dispatch => ({
  logout: user =>
    dispatch({
      type: UserActions.LOGOUT,
      payload: {
        user
      }
    }),
  getInsightTimeConfigs: () =>
    dispatch({
      type: insightActions.GET_TIME_CONFIGS
    }),
  switchOrg: id =>
    dispatch({
      type: UserActions.SWITCH_ORG,
      payload: {
        id
      }
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
