import { metadataConstants } from './metadataConstant';
import { message } from 'antd';
import Api from './metadataApi';
import { isEmpty as _isEmpty } from 'lodash-es';
import { convertToManagerData, convertToOrganisationUser } from '../../utilityFunctions/utilityFunctions';

const metadata = new Api('polaris');
const insights = new Api('insights');

// --- Get Single Organisation Record
export const getSingleOrganisationRecord = organisationId => {
  return async dispatch => {
    try {
      dispatch({ type: metadataConstants.GET_SINGLE_ORGANISATION_RECORDS_REQUEST });
      const { data } = await metadata.getSingleOrganisationRecord(organisationId);
      dispatch({
        type: metadataConstants.GET_SINGLE_ORGANISATION_RECORDS_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: metadataConstants.GET_SINGLE_ORGANISATION_RECORDS_ERROR
      });
    }
  };
};

// --- Get AMDM by organisation
export const getAMDMByOrganisation = () => {
  return async dispatch => {
    try {
      dispatch({ type: metadataConstants.GET_AMDM_BY_ORGANISATION_REQUEST });
      const { data } = await insights.getAMDMByOrganisation();
      const managersData = convertToManagerData(data);
      dispatch({
        type: metadataConstants.GET_AMDM_BY_ORGANISATION_SUCCESS,
        payload: managersData
      });
    } catch (error) {
      dispatch({
        type: metadataConstants.GET_AMDM_BY_ORGANISATION_ERROR
      });
    }
  };
};

// --- Get Organisation user
export const getOrganisationUser = () => {
  return async dispatch => {
    try {
      dispatch({ type: metadataConstants.GET_ORGANISATION_USER_REQUEST });
      const { data } = await metadata.getOrganisationUser();
      const convertedOrganisationUser = convertToOrganisationUser(data);
      dispatch({
        type: metadataConstants.GET_ORGANISATION_USER_SUCCESS,
        payload: convertedOrganisationUser
      });
    } catch (error) {
      dispatch({
        type: metadataConstants.GET_ORGANISATION_USER_ERROR
      });
    }
  };
};

// --- Get Organisation BROAS
export const getBroasForOrganisation = () => {
  return async dispatch => {
    try {
      dispatch({ type: metadataConstants.GET_ORGANISATION_BROAS_REQUEST });
      const { data } = await metadata.getBroasForOrganisation();
      dispatch({
        type: metadataConstants.GET_ORGANISATION_BROAS_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: metadataConstants.GET_ORGANISATION_BROAS_ERROR
      });
    }
  };
};

// -- update AMDM
export const updateAMDM = amdmData => {
  return async dispatch => {
    try {
      dispatch({ type: metadataConstants.UPDATE_AMDM_REQUEST });
      const { data } = await metadata.updateAMDM(amdmData);
      dispatch({
        type: metadataConstants.UPDATE_AMDM_SUCCESS
      });
      const organisationObject = await insights.getAMDMByOrganisation();
      const managersData = convertToManagerData(organisationObject?.data);
      dispatch({
        type: metadataConstants.GET_AMDM_BY_ORGANISATION_SUCCESS,
        payload: managersData
      });
      message.success(data?.message);
    } catch (error) {
      dispatch({
        type: metadataConstants.UPDATE_AMDM_ERROR
      });
    }
  };
};

// -- update Organisation
export const updateOrganisation = (organisationData, orgId) => {
  return async dispatch => {
    try {
      dispatch({ type: metadataConstants.UPDATE_ORGANISATION_REQUEST });
      const { data } = await metadata.updateOrganisation(organisationData, orgId);
      dispatch({
        type: metadataConstants.UPDATE_ORGANISATION_SUCCESS,
        payload: data
      });
      message.success('Organisation has been successfully updated!');
    } catch (error) {
      dispatch({
        type: metadataConstants.UPDATE_ORGANISATION_ERROR
      });
    }
  };
};

// -- update BROAS
export const updateBroas = broasData => {
  return async dispatch => {
    try {
      dispatch({ type: metadataConstants.UPDATE_ORGANISATION_BROAS_REQUEST });
      const { data } = await metadata.updateBroas(broasData);
      dispatch({
        type: metadataConstants.UPDATE_ORGANISATION_BROAS_SUCCESS
      });
      message.success(data?.message);
    } catch (error) {
      dispatch({
        type: metadataConstants.UPDATE_ORGANISATION_BROAS_ERROR
      });
    }
  };
};

//-- get DSS Metadata
export const getConstantDssMetadata = () => {
  return async dispatch => {
    try {
      dispatch({ type: metadataConstants.GET_CONSTANT_DSS_METADATA_REQUEST });
      const { data } = await metadata.getConstantDssMetadata();
      let updatedData = {};
      if (_isEmpty(data)) {
        updatedData = {
          status: 'FAILURE'
        };
      } else {
        updatedData = data;
      }
      dispatch({
        type: metadataConstants.GET_CONSTANT_DSS_METADATA_SUCCESS,
        payload: updatedData
      });
    } catch (error) {
      dispatch({
        type: metadataConstants.GET_CONSTANT_DSS_METADATA_ERROR
      });
    }
  };
};

export const updateDssMetadataConstants = constantsData => {
  return async dispatch => {
    try {
      dispatch({ type: metadataConstants.UPDATE_DSS_METADATA_CONSTANTS_REQUEST });
      await metadata.updateDssMetadataConstants(constantsData);
      const dssConstantsData = await metadata.getConstantDssMetadata();
      if (!_isEmpty(dssConstantsData?.data)) {
        dispatch(getConstantDssMetadata());
        dispatch({ type: metadataConstants.UPDATE_DSS_METADATA_CONSTANTS_SUCCESS });
        message.success(dssConstantsData?.data?.message);
      } else {
        message.error(dssConstantsData?.error?.data?.message);
      }
    } catch (error) {
      dispatch({
        type: metadataConstants.UPDATE_DSS_METADATA_CONSTANTS_ERROR
      });
    }
  };
};

//-- get DSS Metadata via report date
export const getVariableDssMetadata = report_date => {
  return async dispatch => {
    try {
      dispatch({ type: metadataConstants.GET_VARIABLE_DSS_METADATA_REQUEST });
      const { data } = await metadata.getVariableDssMetadata(report_date);
      let updatedData = {};
      if (_isEmpty(data)) {
        updatedData = {
          status: 'FAILURE'
        };
      } else {
        updatedData = data;
      }
      dispatch({
        type: metadataConstants.GET_VARIABLE_DSS_METADATA_SUCCESS,
        payload: updatedData
      });
    } catch (error) {
      dispatch({
        type: metadataConstants.GET_VARIABLE_DSS_METADATA_ERROR
      });
    }
  };
};

// -- update DSS Metadata Variable

export const updateDssMetadataVariable = (variableMetadata, currentDateObject) => {
  return async dispatch => {
    try {
      dispatch({ type: metadataConstants.UPDATE_DSS_METADATA_VARIABLES_REQUEST });
      const dssVariableData = await metadata.updateDssMetadataVariables(variableMetadata);
      if (!_isEmpty(dssVariableData?.data)) {
        dispatch(getVariableDssMetadata(currentDateObject));
        dispatch({ type: metadataConstants.UPDATE_DSS_METADATA_VARIABLES_SUCCESS });
        message.success(dssVariableData?.data?.message);
      } else {
        message.error(dssVariableData?.error?.data?.message);
      }
    } catch (error) {
      dispatch({
        type: metadataConstants.UPDATE_DSS_METADATA_VARIABLES_ERROR
      });
    }
  };
};

// -- create DSS Metadata Variable

export const createDssMetadataVariables = (variableMetadata, currentDateObject) => {
  return async dispatch => {
    try {
      dispatch({ type: metadataConstants.CREATE_DSS_METADATA_VARIABLES_REQUEST });
      const { data } = await metadata.createDssMetadataVariables(variableMetadata);
      if (!_isEmpty(data)) {
        dispatch(getVariableDssMetadata(currentDateObject));
      }

      dispatch({ type: metadataConstants.CREATE_DSS_METADATA_VARIABLES_SUCCESS });
      if (data?.status === 'FAILURE') {
        message.error(data?.message);
      } else {
        message.success(data?.message);
      }
    } catch (error) {
      dispatch({
        type: metadataConstants.CREATE_DSS_METADATA_VARIABLES_ERROR
      });
    }
  };
};
