import Api from '../api';
import { isEmpty as _isEmpty, omitBy as _omitBy } from 'lodash';
import QueryString from 'query-string';

export default class ChartApi extends Api {
  getChartData = async props => {
    const queryParams = { ...props };
    const queryObj = _omitBy(queryParams, _isEmpty);
    const queryString = QueryString.stringify(queryObj);
    try {
      const { data } = await this.get(`?${queryString}`);
      return data;
    } catch (error) {
      return {
        hasError: true,
        error: error.response
      };
    }
  };
}