import Api from './ChartApi';
import { message } from 'antd';

const chart = new Api('polaris/chart/');

// --- Error Handller
const handleError = error => {
  const statusFirstDigit = error && (error.status / 100) >> 0;

  if (statusFirstDigit === 4 || statusFirstDigit === 5) {
    if (error?.data?.error_message) {
      message.error(error?.data?.error_message);
    } else {
      message.error('Something went wrong. Please try again after some time.');
    }
  }
};

// --- getAttributionModalOptions action
export const getChartData = async props => {
  let apiData = {};
  let errorObj = null;

  try {
    apiData = await chart.getChartData(props);
    return apiData;
  } catch (error) {
    handleError(errorObj);
  }
};