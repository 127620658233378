(function initTracker() {
  if (process.env.REACT_APP_TRACKER_KEY) {
    window.ShoptimizeTrackerServices.shopti_tracker_init(process.env.REACT_APP_TRACKER_KEY);
  }
})();

// const { shoptimize_track } = window.ShoptimizeTrackerServices;
const shoptimize_track = window.ShoptimizeTrackerServices && window.ShoptimizeTrackerServices.shoptimize_track;
console.log('shoptimize_track', shoptimize_track);

export const actions = {
  // USER EVENTS
  login: 'Login',
  logout: 'Logout',
  loginPageVisit: 'LoginPageVisit',
  orgChange: 'OrgChange',
  // RECOMMENDATION EVENTS
  recommendationListView: 'RecommendationListView',
  recommendationDetailView: 'RecommendationDetailView',
  recommendationUpdate: 'RecommendationUpdate',
  recommendationDelete: 'RecommendationDelete',
  recommendationCreate: 'RecommendationCreate',
  // INSIGHTS EVENTS
  insightsListView: 'InsightsListView',
  insightsDetailView: 'InsightsDetailView',
  insightCreate: 'InsightCreate',
  insightDelete: 'InsightDelete',
  insightRead: 'InsightRead',
  insightPublish: 'InsightPublish',
  insightUpdate: 'InsightUpdate',
  rejectSuggestedRecommendation: 'RejectSuggestedRecommendation'
};

export const track = (action, data) => {
  if (process.env.REACT_APP_TRACKER_KEY) {
    if (process.env.NODE_ENV === 'production') {
      shoptimize_track(action, data);
    } else {
      console.log({ action, data });
    }
  }
};
