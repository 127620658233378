import { integrationsConstants } from './constants';
import { message } from 'antd';
import Api from './api';

const integration = new Api('polaris/integration');

const handleError = error => {
  const statusFirstDigit = error && (error.status / 100) >> 0;
  if (statusFirstDigit === 4 || statusFirstDigit === 5) {
    if (error.response && error.response.data && error.response.data.detail) {
      message.error(error.response.data.detail);
    } else {
      message.error('Something went wrong. Please try again after some time.');
    }
  }
};

// --- Get All Integrations
export const getBaseVisualisations = () => {
  let apiData = {};
  let errorObj = null;
  return async dispatch => {
    try {
      dispatch({ type: integrationsConstants.GET_INTEGRATIONS_REQUEST });
      apiData = await integration.getBaseVisualisations();
      if (apiData && apiData.hasError) {
        errorObj = apiData.error;
        throw new Error();
      } else {
        const { data } = apiData;
        const visData = formatBaseVisualisationResponse(data);
        dispatch({
          type: integrationsConstants.GET_INTEGRATIONS_SUCCESS,
          payload: { list: [...visData] }
        });
      }
    } catch (error) {
      handleError(errorObj);
      dispatch({
        type: integrationsConstants.GET_INTEGRATIONS_ERROR,
        payload: { errorObj }
      });
    }
  };
};

/**
 * TODO : Make More generic by making map.
 */
const formatBaseVisualisationResponse = visualisations => {
  return visualisations.map(visualisation => {
    const visType = {
      title: visualisation.name,
      id: visualisation.id,
      key: visualisation.code,
      type: visualisation.type,
      query: visualisation.query
    };
    let visList = [];
    if (visualisation.base_visualisation_set && visualisation.base_visualisation_set.length) {
      visList = visualisation.base_visualisation_set.map(vis => {
        return {
          title: vis.name,
          id: vis.id,
          key: vis.code,
          type: vis.type,
          query: vis.query,
          integrations: vis.properties.integrations ? vis.properties.integrations : [],
          isSlow: vis.slow_query,
          time_granularity: vis.properties.time_resolution_availability
        };
      });
    }
    visType.children = visList;
    return visType;
  });
};
