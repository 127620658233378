import Api from '../api';

export default class Integrations extends Api {
  getBaseVisualisations = async () => {
    try {
      const data  = await this.get(`/visualisations/`);
      return data
    } catch (error) {
      return error;
    }
  }

  
}
