import { ExclamationCircleOutlined } from '@ant-design/icons';
import { updateDssMetadataConstants } from '../../../redux/MetaData/metadataAction';
import { isEmpty as _isEmpty } from 'lodash';
import moment from 'moment';
import { Button, Modal, Col, Form, Input, Row, DatePicker, Select } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../Form.scss';
const DSSMetadataConstantForm = props => {
  const { setDssMetadataConstantToggleForm, dssConstantMetadata } = props;
  const userData = useSelector(state => state.User?.userData);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const formInitialValue = {
    onboarding_date: !_isEmpty(dssConstantMetadata?.data?.constant_metrics_list?.ONBOARDING_DATE)
      ? moment(dssConstantMetadata?.data?.constant_metrics_list?.ONBOARDING_DATE)
      : '',
    business_type: dssConstantMetadata?.data?.constant_metrics_list?.BUSINESS_TYPE,
    adspend_freeze_date: !_isEmpty(dssConstantMetadata?.data?.constant_metrics_list?.ADSPEND_FREEZE_DATE)
      ? moment(dssConstantMetadata?.data?.constant_metrics_list?.ADSPEND_FREEZE_DATE)
      : '',
    location: dssConstantMetadata?.data?.constant_metrics_list?.LOCATION,
    seo_lead_name: dssConstantMetadata?.data?.constant_metrics_list?.SEO_LEAD_NAME,
    clinet_code: dssConstantMetadata?.data?.constant_metrics_list?.CLINET_CODE
  };

  const formItemLayout = {
    labelCol: {
      span: 24
    },
    wrapperCol: {
      span: 22
    }
  };
  const onUpdate = values => {
    var finalConstantData = {
      ...values,
      onboarding_date: !_isEmpty(values?.onboarding_date)
        ? moment(moment(values?.onboarding_date).utc().format('YYYY-MM-DD'))._i
        : '',
      adspend_freeze_date: !_isEmpty(values?.adspend_freeze_date)
        ? moment(moment(values?.adspend_freeze_date).utc().format('YYYY-MM-DD'))._i
        : '',
      updated_at: moment(moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'))._i,
      updated_by: userData?.username,
      is_active: true,
      created_at: moment(moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'))._i,
      create_by: userData?.username
    };

    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to update Constsnts Metadata detail ?',
      okText: 'YES',
      cancelText: 'CANCEL',
      onOk() {
        dispatch(updateDssMetadataConstants(finalConstantData));
        setDssMetadataConstantToggleForm(false);
      }
    });
  };
  return (
    <Form initialValues={formInitialValue} onFinish={onUpdate} layout={formItemLayout} {...formItemLayout} form={form}>
      <Row align='top' gutter={24}>
        <Col md={12}>
          <div className='c-formElement'>
            <Form.Item
              name={'business_type'}
              label='Business Type'
              rules={[
                {
                  required: true,
                  message: 'Please Select Business Type'
                }
              ]}
            >
              <Select showSearch name='business_type' placeholder='Select Business Type'>
                <Select.Option key={'1'} value={'ABU'}>
                  {'ABU'}
                </Select.Option>
                <Select.Option key={'2'} value={'EBU'}>
                  {'EBU'}
                </Select.Option>
                <Select.Option key={'3'} value={'GP Only'}>
                  {'GP Only'}
                </Select.Option>
              </Select>
            </Form.Item>
          </div>
        </Col>
        <Col md={12}>
          <div className='c-formElement'>
            <Form.Item
              name={'onboarding_date'}
              label='Onboarding Date'
              rules={[
                {
                  required: true,
                  message: 'Please Select Onboarding Date'
                }
              ]}
            >
              <DatePicker name='onboarding_date' style={{ width: '100%' }} />
            </Form.Item>
          </div>
        </Col>
      </Row>

      <Row align='top' gutter={24}>
        <Col md={12}>
          <div className='c-formElement'>
            <Form.Item name={'adspend_freeze_date'} label='Freeze Date'>
              <DatePicker name='adspend_freeze_date' style={{ width: '100%' }} />
            </Form.Item>
          </div>
        </Col>

        <Col md={12}>
          <div className='c-formElement'>
            <Form.Item name={'location'} label='Location'>
              <Input name='location' />
            </Form.Item>
          </div>
        </Col>
      </Row>

      <Row align='top' gutter={24}>
        <Col md={12}>
          <div className='c-formElement'>
            <Form.Item name={'seo_lead_name'} label='SEO Lead'>
              <Input name='seo_lead_name' />
            </Form.Item>
          </div>
        </Col>

        <Col md={12}>
          <div className='c-formElement'>
            <Form.Item
              name={'clinet_code'}
              rules={[
                {
                  required: true,
                  message: 'Please Enter Client Code'
                }
              ]}
              label='Client Code'
            >
              <Input name='clinet_code' />
            </Form.Item>
          </div>
        </Col>
      </Row>

      <Form.Item className='center'>
        <Button type='primary' htmlType='submit' style={{ marginRight: '10px', marginTop: '10px' }}>
          Add / Update DSS Metadata Constants
        </Button>
        <Button htmlType='button' onClick={() => setDssMetadataConstantToggleForm(false)}>
          Close
        </Button>
      </Form.Item>
    </Form>
  );
};

export default DSSMetadataConstantForm;
