import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, InputNumber, Modal, Row } from 'antd';
import SMResult from 'components/SMResult/SMResult';
import React from 'react';
import { useDispatch } from 'react-redux';
import { updateBroas } from '../../../redux/MetaData/metadataAction';
import '../Form.scss';

const BROASForm = props => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { broasData, setOrganisationBroasToggleForm } = props;

  const formInitialValue = {
    target_roas: broasData?.data?.data?.[0]?.target_roas
  };
  const formItemLayout = {
    labelCol: {
      span: 24
    },
    wrapperCol: {
      span: 22
    }
  };

  const onUpdate = values => {
    const updatedData = {
      broas: values?.target_roas
    };
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure you want to update BROAS ?`,
      okText: 'YES',
      cancelText: 'CANCEL',
      onOk() {
        dispatch(updateBroas(updatedData));
        setOrganisationBroasToggleForm(false);
      }
    });
  };

  if (broasData?.organisation_broas_fetching_status === 'fetching') {
    return <SMResult type='loader' message='Loading Broas' />;
  }

  if (broasData?.organisation_broas_fetching_status === 'fail') {
    return <SMResult type='error' message='Unable to Fetch Data' />;
  }

  return (
    <Form onFinish={onUpdate} {...formItemLayout} form={form} layout={formItemLayout} initialValues={formInitialValue}>
      <Row gutter={24}>
        <Col md={12}>
          <div className='c-formElement'>
            <Form.Item
              name={'target_roas'}
              label='Broas'
              rules={[
                {
                  required: true,
                  message: 'Please Enter Organisation Broas'
                },
                () => ({
                  validator(_, value) {
                    if (!value || value <= 2147483647) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        `Ensure this field has no more than 2147483647
                         characters.`
                      )
                    );
                  }
                })
              ]}
            >
              <InputNumber min={0} name='target_roas' style={{ width: '90%' }} />
            </Form.Item>
          </div>
        </Col>
      </Row>
      <Form.Item className='center'>
        <Button type='primary' htmlType='submit' style={{ marginRight: '10px', marginTop: '10px' }}>
          Update Broas
        </Button>
        <Button htmlType='button' onClick={() => setOrganisationBroasToggleForm(false)}>
          Close
        </Button>
      </Form.Item>
    </Form>
  );
};
export default BROASForm;
