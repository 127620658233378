import Api from '../api';

export default class Auth extends Api {
  checkLogin() {
    return this.isAuth();
  }

  login = (email, password) => {
    return new Promise(async (resolve, reject) => {
      try {
        var { data } = await this.instance.post(`/login/`, { email, password });
        var userData = this.getUserData(data);
        this.afterlogin(data.access, data.refresh, userData);
        resolve(userData);
      } catch (error) {
        reject(error);
      }
    });
  };

  logout = () => {
    return new Promise(async (resolve, reject) => {
      try {
        await this.instance.post('/logout/', { refresh_token: this.getRefreshTokenFromStorage() });
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  switchOrg = orgId => {
    return new Promise(async (resolve, reject) => {
      try {
        var { data } = await this.instance.post(`/switch/`, { organisation_id: orgId });
        var userData = this.getUserData(data);
        this.afterlogin(data.access, data.refresh, userData);
        resolve(userData);
      } catch (error) {
        reject(error);
      }
    });
  };

  getOrgs = () => {
    return new Promise(async (resolve, reject) => {
      try {
        var { data } = await this.get(`/list/`);
        var orgs = this.formatOrgList(data);
        resolve(orgs);
      } catch (error) {
        reject(error);
      }
    });
  };

  getUserData(response) {
    const loginPayload = JSON.parse(atob(response.access.split('.')[1]));
    return {
      username: loginPayload.username,
      email: loginPayload.email,
      organisationId: loginPayload.organisation_id,
      organisationSet: loginPayload.organisation_set,
      userId: loginPayload.user_id,
      organisationUserId: loginPayload.organisation_user_id,
      mgValidOrderStatus: loginPayload.mg_valid_order_status,
      orbSuperAdmin: loginPayload.is_orb_super_admin,
      orbNotificationManager: loginPayload.is_notification_manager,
      orbAdmin: loginPayload.is_orb_super_admin ? false : loginPayload.is_admin ? true : false
    };
  }

  formatOrgList(orgs) {
    return orgs.map(org => ({
      id: org.organisation_set.id,
      name: org.organisation_set.name,
      store_code: org.organisation_set.store_code,
      store_url: org.organisation_set.store_url,
      organisation_url_suffix: org.organisation_set.organisation_url_suffix,
      industry: org.organisation_set.industry
    }));
  }

  afterlogin(accessToken, refreshToken, user) {
    this.setAccessToken(accessToken);
    this.setRefreshToken(refreshToken);
    this.setUser(user);
  }

  afterlogout() {
    this.setAccessToken(null);
    this.setRefreshToken(null);
    this.setUser(null);
  }
}
