import { spotlightConstants } from './spotlight.constants';

const initialState = {
  expandedSpotlightInsights: [],
  insights: {
    data: [],
    isLoading: false,
    isError: null
  }
};

export const spotlightReducer = (state = initialState, action) => {
  switch (action.type) {
    case spotlightConstants.GET_SPOTLIGHT_INSIGHTS_REQUEST:
      return {
        ...state,
        insights: {
          isLoading: true,
          isError: null,
          data: []
        }
      };
    case spotlightConstants.GET_SPOTLIGHT_INSIGHTS_SUCCESS:
      return {
        ...state,
        insights: {
          isLoading: false,
          isError: null,
          data: [...action.payload]
        }
      };
    case spotlightConstants.GET_SPOTLIGHT_INSIGHTS_ERROR:
      return {
        ...state,
        insights: {
          isLoading: false,
          isError: action.payload,
          data: state.insights.data
        }
      };
    case spotlightConstants.EXPANDED_SPOTLIGHT_INSIGHTS:
      return {
        ...state,
        expandedSpotlightInsights: [...action.payload]
      };
    default:
      return state;
  }
};
