export const RevenueCalculatorConstants = {
  GET_REVENUECALCULATOR_DATA_REQUEST: 'REVENUE_CALCULATOR/GET_REVENUE_CALCULATOR_DATA_REQUEST',
  GET_REVENUECALCULATOR_DATA_SUCCESS: 'REVENUE_CALCULATOR/GET_REVENUE_CALCULATOR_DATA_SUCCESS',
  GET_REVENUE_CALCULATOR_DATA_ERROR: 'REVENUE_CALCULATOR/GET_REVENUE_CALCULATOR_DATA_ERROR',

  GET_PREDICTED_REVENUE_REQUEST: 'REVENUE_CALCULATOR/GET_PREDICTED_REVENUE_REQUEST',
  GET_PREDICTED_REVENUE_SUCCESS: 'REVENUE_CALCULATOR/GET_PREDICTED_REVENUE_SUCCESS',
  GET_PREDICTED_REVENUE_ERROR: 'REVENUE_CALCULATOR/GET_PREDICTED_REVENUE_ERROR',

  RESET_PREDICTED_REVENUE_DATA: 'REVENUE_CALCULATOR/RESET_PREDICTED_REVENUE_DATA'
};
