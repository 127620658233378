import { all, put, call, takeEvery } from 'redux-saga/effects';
import insightAction from './actions';
import { message } from 'antd';

import authActions from 'redux/User/actions';
import Api from './api';

import { track, actions as TRACKER_ACTION } from 'redux/tracker';

const insights = new Api('insights/list');
const polarisConfig = new Api('polaris/');
//
function* handleError(error) {
  if (error.status === 401) {
    yield put({
      type: authActions.LOGOUT
    });
  }
  if (error.response) {
    if (error.response.data && error.response.data.detail) {
      message.error(error.response.data.detail);
    } else {
      message.error('Something went wrong. Please try again after some time.');
    }
  } else {
    message.error('Something went wrong. Please try again after some time.');
  }
}

export function* getInsightAction() {
  try {
    const { data } = yield call(insights.getInsightAction);

    yield put({
      type: insightAction.SET_INSIGHT_DATA_RESPONSE,
      payload: {
        insight_data: data,
        insight_success: true
      }
    });
  } catch (error) {
    // console.log('error');
    yield call(handleError, error);
  }
}

export function* getInsightTimeConfigs() {
  const configFallback = { date_range_limit: 2, insight_list_range: 2 };

  const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
  try {
    yield put({
      type: insightAction.GET_TIME_CONFIGS_NEW,
      payload: {
        get_timeconfig_status: 'fetching'
      }
    });
    let data = yield call(polarisConfig.getInsightTimeConfigs);
    if (connection) {
      if (['slow-2g', '2g'].includes(connection.effectiveType)) {
        data = configFallback;
      }
    }
    if (data.response && (data.response.status / 100) >> 0 === 4) {
      yield put({
        type: insightAction.GET_TIME_CONFIGS_NEW,
        payload: { get_timeconfig_status: 'fail', ...configFallback }
      });
    } else {
      yield put({
        type: insightAction.GET_TIME_CONFIGS_NEW,
        payload: { get_timeconfig_status: 'success', ...data }
      });
    }
  } catch (error) {
    // console.log('error');
    yield call(handleError, error);
  }
}

export function* setInsightAPIResponseSuccess(action) {
  try {
    // const { data } = yield call(insights.getInsightActions);
    yield put({
      type: insightAction.SET_INSIGHT_API_SUCCESS_REDUCER,
      payload: action.payload
    });
  } catch (error) {
    // console.log('error');
    yield call(handleError, error);
  }
}

// Specific get insight action
export function* getSpecificInsightAction(action) {
  let errorObj = null;
  try {
    const actionData = yield call(insights.getSpecificInsightAction, action.payload.id);
    if (actionData && actionData.hasError) {
      errorObj = actionData.error;
      throw new Error();
    }
    yield put({
      type: insightAction.SET_INSIGHT_DATA,
      payload: {
        selected_insights: actionData
      }
    });
  } catch (error) {
    yield call(handleError, errorObj);
  }
}

// Specific get insight action
export function* getInsightsByDateAction(action) {
  let errorObj = null;
  try {
    yield put({
      type: insightAction.SET_INSIGHT_DATA_RESPONSE,
      payload: {
        get_insigts_status: 'fetching'
      }
    });
    const actionData = yield call(insights.getInsightsByDateAction, action.payload);
    if (actionData && actionData.hasError) {
      errorObj = actionData.error;
      throw new Error();
    }
    const newData = { ...actionData, data: actionData.data };
    yield put({
      type: insightAction.SET_INSIGHT_DATA_RESPONSE,
      payload: {
        get_insigts_status: 'success',
        insight_data: newData,
        insight_success: true
      }
    });
  } catch (error) {
    yield put({
      type: insightAction.SET_INSIGHT_DATA_RESPONSE,
      payload: {
        get_insigts_status: 'fail'
      }
    });

    yield call(handleError, errorObj);
  }
}

export function* updateInsightAction(action) {
  const { updatedInsightObject, filterDate, id } = action.payload;
  let errorObj = null;
  try {
    const patchApiResponse = yield call(insights.updateInsightAction, updatedInsightObject, id);
    const actionData = yield call(insights.getInsightsByDateAction, filterDate);
    if ((actionData && actionData.hasError) || (patchApiResponse && patchApiResponse.handleError)) {
      errorObj = actionData.error || patchApiResponse.error;
      throw new Error();
    }
    const newData = { ...actionData, data: actionData.data };
    yield put({
      type: insightAction.SET_INSIGHT_DATA_RESPONSE,
      payload: {
        insight_data: newData
      }
    });
    message.success('Insight has been successfully updated!');
    track(TRACKER_ACTION.insightUpdate, action.payload);
  } catch (error) {
    yield call(handleError, errorObj);
  }
}

// Publish / Unpublish Single insights
export function* publishInsightAction(action) {
  const { updatedInsightObject, filterDate, id } = action.payload;
  let errorObj = null;
  try {
    const patchApiResponse = yield call(insights.updateInsightAction, updatedInsightObject, id);
    const actionData = yield call(insights.getInsightsByDateAction, filterDate);
    if ((actionData && actionData.hasError) || (patchApiResponse && patchApiResponse.handleError)) {
      errorObj = actionData.error || patchApiResponse.error;
      throw new Error();
    }
    const newData = { ...actionData, data: actionData.data };
    yield put({
      type: insightAction.SET_INSIGHT_DATA_RESPONSE,
      payload: {
        insight_data: newData
      }
    });
    message.success('Insight has been successfully updated!');
    track(TRACKER_ACTION.insightUpdate, action.payload);
  } catch (error) {
    yield call(handleError, errorObj);
  }
}

// function to update selected insights object for patch request
function refactorSelectedInsightForPatch(data) {
  const addFilters = data.map(item => {
    return {
      insightId: item.id,
      updatePayload: {
        state: item.state
      }
    };
  });
  return addFilters;
}

// Publish multiple insights
export function* publishMultipleInsightAction(action) {
  const { checkedInsightList, filterDate } = action.payload;
  const updatedCheckedList = refactorSelectedInsightForPatch(checkedInsightList) || [];
  let errorObj = null;
  try {
    yield* updatedCheckedList.map(insight =>
      call(insights.updateInsightAction, insight.updatePayload, insight.insightId)
    );
    const actionData = yield call(insights.getInsightsByDateAction, filterDate);
    if (actionData && actionData.hasError) {
      errorObj = actionData.error;
      throw new Error();
    }
    const newData = { ...actionData, data: actionData.data };
    yield put({
      type: insightAction.SET_INSIGHT_DATA_RESPONSE,
      payload: {
        insight_data: newData
      }
    });
    message.success('Insight has been successfully Published!');
    track(TRACKER_ACTION.insightPublish, action.payload);
  } catch (error) {
    yield call(handleError, errorObj);
  }
}

// Read multiple insights
export function* readMultipleInsightAction(action) {
  const { checkedInsightList, filterDate } = action.payload;
  const updatedCheckedList = refactorSelectedInsightForPatch(checkedInsightList) || [];
  let errorObj = null;
  try {
    yield* updatedCheckedList.map(insight =>
      call(insights.updateInsightAction, insight.updatePayload, insight.insightId)
    );
    const actionData = yield call(insights.getInsightsByDateAction, filterDate);
    if (actionData && actionData.hasError) {
      errorObj = actionData.error;
      throw new Error();
    }
    const newData = { ...actionData, data: actionData.data };
    yield put({
      type: insightAction.SET_INSIGHT_DATA_RESPONSE,
      payload: {
        insight_data: newData
      }
    });
    message.success('All selected Insights has been Marked as Read!');
    track(TRACKER_ACTION.insightRead, action.payload);
  } catch (error) {
    yield call(handleError, errorObj);
  }
}

// Delete Single insights
export function* deleteInsightAction(action) {
  const { id, filterDate } = action.payload;
  let errorObj = null;
  try {
    yield put({
      type: insightAction.SET_INSIGHT_API_SUCCESS_REDUCER,
      payload: {
        insight_delete_success: false
      }
    });
    yield call(insights.deleteInsightAction, id);
    const actionData = yield call(insights.getInsightsByDateAction, filterDate);
    if (actionData && actionData.hasError) {
      errorObj = actionData.error;
      throw new Error();
    }
    const newData = { ...actionData, data: actionData.data };
    yield put({
      type: insightAction.SET_INSIGHT_API_SUCCESS_REDUCER,
      payload: {
        insight_delete_success: true
      }
    });
    yield put({
      type: insightAction.SET_INSIGHT_DATA_RESPONSE,
      payload: {
        insight_data: newData
      }
    });
    message.success('Insight has been successfully deleted!');
    track(TRACKER_ACTION.insightDelete, action.payload);
  } catch (error) {
    yield call(handleError, errorObj);
  }
}

// Delete multiple insights
export function* deleteMultipleInsightAction(action) {
  const { keysToDelete, filterDate } = action.payload;
  let errorObj = null;
  try {
    yield put({
      type: insightAction.SET_INSIGHT_API_SUCCESS_REDUCER,
      payload: {
        insight_delete_success: false
      }
    });
    // const response;
    yield* keysToDelete.map(insight => call(insights.deleteInsightAction, insight));
    const actionData = yield call(insights.getInsightsByDateAction, filterDate);
    if (actionData && actionData.hasError) {
      errorObj = actionData.error;
      throw new Error();
    }
    const newData = { ...actionData, data: actionData.data };
    yield put({
      type: insightAction.SET_INSIGHT_API_SUCCESS_REDUCER,
      payload: {
        insight_delete_success: true
      }
    });
    yield put({
      type: insightAction.SET_INSIGHT_DATA_RESPONSE,
      payload: {
        insight_data: newData
      }
    });
    message.success('Insight has been successfully deleted!');
    track(TRACKER_ACTION.insightDelete, action.payload);
  } catch (error) {
    yield call(handleError, errorObj);
  }
}

export function* onLinkInsight(action) {
  try {
    yield put({
      type: insightAction.LINK_INSIGHT,
      payload: action.payload
    });
  } catch (error) {
    yield call(handleError, error);
  }
}

export function* createInsightAction(action) {
  const { insightObject, filterDate } = action.payload;
  let errorObj = null;
  try {
    yield call(insights.createInsightAction, insightObject);
    const actionData = yield call(insights.getInsightsByDateAction, filterDate);
    if (actionData && actionData.hasError) {
      errorObj = actionData.error;
      throw new Error();
    }
    const newData = { ...actionData, data: actionData.data };
    yield put({
      type: insightAction.SET_INSIGHT_DATA_RESPONSE,
      payload: {
        insight_data: newData
      }
    });
    message.success('Insight has been successfully Created!');
    track(TRACKER_ACTION.insightCreate, action.payload);
  } catch (error) {
    yield call(handleError, errorObj);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(insightAction.SET_INSIGHT_DATA, getInsightAction),
    takeEvery(insightAction.GET_SPECIFIC_ACTION, getSpecificInsightAction),
    takeEvery(insightAction.GET_INSIGHT_BY_DATE_ACTION, getInsightsByDateAction),
    takeEvery(insightAction.UPDATE_INSIGHT_ACTION, updateInsightAction),
    takeEvery(insightAction.PUBLISH_INSIGHT_ACTION, publishInsightAction),
    takeEvery(insightAction.PUBLISH_MULTIPLE_INSIGHT_ACTION, publishMultipleInsightAction),
    takeEvery(insightAction.DELETE_INSIGHT_ACTION, deleteInsightAction),
    takeEvery(insightAction.DELETE_MULTIPLE_INSIGHT_ACTION, deleteMultipleInsightAction),
    takeEvery(insightAction.SET_INSIGHT_API_SUCCESS, setInsightAPIResponseSuccess),
    takeEvery(insightAction.READ_MULTIPLE_INSIGHTS, readMultipleInsightAction),
    takeEvery(insightAction.GET_TIME_CONFIGS, getInsightTimeConfigs),
    takeEvery(insightAction.CREATE_INSIGHT_ACTION, createInsightAction)
  ]);
}
