import { Descriptions } from 'antd';
import React from 'react';
import SMResult from 'components/SMResult/SMResult';

const DSSMetadataVariableDetails = props => {
  const { dssVariableMetadata } = props;

  if (dssVariableMetadata?.dss_variables_metadata_fetching_status === 'fetching') {
    return <SMResult type='loader' message='Loading Dss Variable Data' />;
  }

  return (
    <Descriptions bordered>
      <Descriptions.Item label='Net MTD GMV'>
        {dssVariableMetadata?.data?.variable_metrics_list?.NET_MTD_GMV}
      </Descriptions.Item>
      <Descriptions.Item label='Actual Ad Spends' span={2}>
        {dssVariableMetadata?.data?.variable_metrics_list?.ACTUAL_AD_SPENDS}
      </Descriptions.Item>

      <Descriptions.Item label='Actual Rev Share Billing'>
        {dssVariableMetadata?.data?.variable_metrics_list?.ACTUAL_REV_SHARE_BILLING}
      </Descriptions.Item>
      <Descriptions.Item label='Actual GMV' span={2}>
        {dssVariableMetadata?.data?.variable_metrics_list?.ACTUAL_GMV}
      </Descriptions.Item>

      <Descriptions.Item label='Spend Target'>
        {dssVariableMetadata?.data?.variable_metrics_list?.SPEND_TARGET}
      </Descriptions.Item>
      <Descriptions.Item label='Gross GMV Target' span={2}>
        {dssVariableMetadata?.data?.variable_metrics_list?.GROSS_GMV_TARGET}
      </Descriptions.Item>

      <Descriptions.Item label='Spends Via STZ'>
        {dssVariableMetadata?.data?.variable_metrics_list?.SPENDS_VIA_STZ}
      </Descriptions.Item>
      <Descriptions.Item label='Multiplier (%)' span={2}>
        {dssVariableMetadata?.data?.variable_metrics_list?.MULTIPLIER}
      </Descriptions.Item>

      <Descriptions.Item label='Rev Share (%)'>
        {dssVariableMetadata?.data?.variable_metrics_list?.REV_SHARE}
      </Descriptions.Item>
      <Descriptions.Item label='Fixed Fee' span={2}>
        {dssVariableMetadata?.data?.variable_metrics_list?.FIXED_FEE}
      </Descriptions.Item>

      <Descriptions.Item label='Min Billing'>
        {dssVariableMetadata?.data?.variable_metrics_list?.MIN_BILLING}
      </Descriptions.Item>

      <Descriptions.Item label='CRED Spends' span={2}>
        {dssVariableMetadata?.data?.variable_metrics_list?.CRED_SPENDS}
      </Descriptions.Item>

      <Descriptions.Item label='GPAY Spends'>
        {dssVariableMetadata?.data?.variable_metrics_list?.GPAY_SPENDS}
      </Descriptions.Item>
      <Descriptions.Item label='Mail Spends' span={2}>
        {dssVariableMetadata?.data?.variable_metrics_list?.MAIL_SPENDS}
      </Descriptions.Item>

      <Descriptions.Item label='Whatsapp Spends'>
        {dssVariableMetadata?.data?.variable_metrics_list?.WHATSAPP_SPENDS}
      </Descriptions.Item>
      <Descriptions.Item label='Other Spends' span={2}>
        {dssVariableMetadata?.data?.variable_metrics_list?.OTHER_SPENDS}
      </Descriptions.Item>

      <Descriptions.Item label='Additional Billing'>
        {dssVariableMetadata?.data?.variable_metrics_list?.ADDITIONAL_BILLING}
      </Descriptions.Item>
      <Descriptions.Item label='Whatsapp Billing' span={2}>
        {dssVariableMetadata?.data?.variable_metrics_list?.WHATSAPP_BILING}
      </Descriptions.Item>

      <Descriptions.Item label='Actual Total Billing'>
        {dssVariableMetadata?.data?.variable_metrics_list?.ACTUAL_TOTAL_BILLING}
      </Descriptions.Item>
      <Descriptions.Item label='Billing Target' span={2}>
        {dssVariableMetadata?.data?.variable_metrics_list?.BILLING_TARGET}
      </Descriptions.Item>

      <Descriptions.Item label='SIPL Target'>
        {dssVariableMetadata?.data?.variable_metrics_list?.SIPL_TARGET}
      </Descriptions.Item>

      <Descriptions.Item label='Team Lead' span={2}>
        {dssVariableMetadata?.data?.variable_metrics_list?.TEAM_LEAD_NAME}
      </Descriptions.Item>

      <Descriptions.Item label='A/C Lead'>
        {dssVariableMetadata?.data?.variable_metrics_list?.ACCOUNT_LEAD}
      </Descriptions.Item>
      <Descriptions.Item label='Status' span={2}>
        {dssVariableMetadata?.data?.variable_metrics_list?.STORE_STATUS}
      </Descriptions.Item>

      <Descriptions.Item label='Business Model' span={2}>
        {dssVariableMetadata?.data?.variable_metrics_list?.BUSINESS_MODEL}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default DSSMetadataVariableDetails;
