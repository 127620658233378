import insightThunkAction from '../InsightNotificationThunk/constant';

const initialState = {
  alert_message: {
    status: '',
    alert_message_data: {},
    metrics: {},
    dimensions: {}
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case insightThunkAction.GET_ALERT_MESSAGE_REQUEST:
      return {
        ...state,
        alert_message: {
          status: 'fetching',
          alert_message_data: {}
        }
      };
    case insightThunkAction.GET_ALERT_MESSAGE_SUCCESS:
      return {
        ...state,
        alert_message: {
          status: 'success',
          alert_message_data: action.payload
        }
      };
    case insightThunkAction.GET_ALERT_MESSAGE_ERROR:
      return {
        ...state,
        alert_message: {
          status: 'error',
          alert_message_data: {}
        }
      };

    case insightThunkAction.GET_METRICS_LIST_REQUEST:
      return {
        ...state,
        metrics: {
          status: 'fetching',
          metrics_data: {}
        }
      };
    case insightThunkAction.GET_METRICS_LIST_SUCCESS:
      return {
        ...state,
        metrics: {
          status: 'success',
          metrics_data: action.payload
        }
      };
    case insightThunkAction.GET_METRICS_LIST_ERROR:
      return {
        ...state,
        metrics: {
          status: 'error',
          metrics_data: {}
        }
      };

    case insightThunkAction.GET_DIMENSION_LIST_REQUEST:
      return {
        ...state,
        dimensions: {
          status: 'fetching',
          dimensions_data: {}
        }
      };
    case insightThunkAction.GET_DIMENSION_LIST_SUCCESS:
      return {
        ...state,
        dimensions: {
          status: 'success',
          dimensions_data: action.payload
        }
      };
    case insightThunkAction.GET_DIMENSION_LIST_ERROR:
      return {
        ...state,
        dimensions: {
          status: 'error',
          dimensions_data: {}
        }
      };

    case insightThunkAction.GET_INSIGHT_ALERT_MESSAGE_CLOSABLE:
      let notification = state.alert_message.alert_message_data.insight || [];
      const updatedNotification = notification.filter(insight => insight.id !== action.payload);
      return {
        ...state,
        alert_message: {
          status: 'success',
          alert_message_data: { insight: [...updatedNotification] }
        }
      };
    default:
      return state;
  }
}
