import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { getChartData } from 'redux/Chart/ChartActions';
import { isEmpty as _isEmpty, isUndefined as _isUndefined } from 'lodash-es';
import dateRange from '../../DateRange/defaults';
import { Space } from 'antd';
import SMResult from 'components/SMResult/SMResult';
import { convertQueryBystatus } from 'utilityFunctions/InitialStates';
import { useSelector } from 'react-redux';

export const ChannelQueryData = props => {
  const { channelTitle, queryData } = props;
  const { userData } = useSelector(state => state.User);
  const [chartData, setChartData] = useState({});
  const [loading, setLoading] = useState(false);
  // --- Get chart data from query
  const getChartDataFromQuery = useCallback(async () => {
    setLoading(true);
    setChartData({});

    let finalDate = [
      moment(dateRange.currentRanges['Last 30 Days']?.[0]).format('YYYY-MM-DD'),
      moment(dateRange.currentRanges['Last 30 Days']?.[1]).format('YYYY-MM-DD')
    ];

    const queryConfigObject = {
      chart_type: 'msspline',
      granularity: 'day',
      date_range: JSON.stringify(finalDate) || [],
      query: JSON.stringify(convertQueryBystatus(queryData, userData?.mgValidOrderStatus, ['web'])),
      is_competitor_intelligence: false
    };

    const response = await getChartData(queryConfigObject)
      .then(response => response)
      .catch(error => error)
      .finally(() => setLoading(false));

    setChartData(response);
  }, [queryData]);

  useEffect(() => {
    !_isEmpty(queryData) && getChartDataFromQuery();

    return () => {
      setLoading(false);
      setChartData({});
    };
  }, [getChartDataFromQuery, queryData]);

  const spendsValue = value => {
    console.log(value);
    if (!_isUndefined(value)) {
      return (Number(value.split(' ')[1] || value) / 30).toFixed(2);
    }
  };

  if (loading) {
    return <SMResult type='loader' size='small' message={`Loading ${channelTitle}`} />;
  }

  return (
    <Space direction='vertical'>
      {channelTitle}
      <span>₹{spendsValue(chartData?.meta_data?.summary[0]?.tooltip_value) || 0}</span>
    </Space>
  );
};
