import { Button, Card, Divider } from 'antd';
import { isEmpty as _isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAMDMByOrganisation,
  getOrganisationUser,
  getSingleOrganisationRecord,
  getBroasForOrganisation
} from '../../redux/MetaData/metadataAction';
import { getOrganisationVertical } from '../../redux/Notification/NotificationAction';
import AMDMDetails from './AMDMDetails/AMDMDetails';
import AMDMForm from './AMDMForm/AMDMForm';
import OrganisationDetails from './OrganisationDetails/OrganisationDetails';
import OrganisationForm from './OrganisationForm/OrganisationForm';
import BROASDetails from './BROASDetails/BROASDetails';
import BROASForm from './BROASForm/BROASForm';

const MetaDataForm = () => {
  const [organisationToggleForm, setOrganisationToggleForm] = useState(false);
  const [amdmToggleForm, setAMDMToggleForm] = useState(false);
  const [organisationBroasToggleForm, setOrganisationBroasToggleForm] = useState(false);
  const metaData = useSelector(state => state?.MetaData);
  const verticalData = useSelector(state => state?.NotificationReducer?.organisationVertical?.data);
  const organisationId = useSelector(state => state.User.userData?.organisationId);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSingleOrganisationRecord(organisationId));
    dispatch(getAMDMByOrganisation());
    dispatch(getOrganisationUser());
    dispatch(getOrganisationVertical());
    dispatch(getBroasForOrganisation());
  }, [dispatch, organisationId]);

  const renderForm = type => {
    switch (type) {
      case 'organisation':
        return (
          !_isEmpty(metaData) && (
            <OrganisationForm
              singleMetaData={metaData?.organisationData}
              verticalData={verticalData}
              setOrganisationToggleForm={setOrganisationToggleForm}
            />
          )
        );
      case 'AMDM':
        return (
          !_isEmpty(metaData) && (
            <AMDMForm
              managerData={metaData?.managersData?.data}
              organisationUser={metaData?.organisationUser?.data}
              setAMDMToggleForm={setAMDMToggleForm}
            />
          )
        );
      case 'BROAS':
        return (
          !_isEmpty(metaData) && (
            <BROASForm
              broasData={metaData?.organisationBroas}
              setOrganisationBroasToggleForm={setOrganisationBroasToggleForm}
            />
          )
        );
      default:
        return;
    }
  };

  const renderDetails = type => {
    switch (type) {
      case 'organisation':
        return !_isEmpty(metaData) && <OrganisationDetails verticalData={verticalData} metaData={metaData} />;
      case 'AMDM':
        return !_isEmpty(metaData) && <AMDMDetails managerData={metaData?.managersData?.data} />;
      case 'BROAS':
        return !_isEmpty(metaData) && <BROASDetails broasData={metaData?.organisationBroas} />;
      default:
        return;
    }
  };
  return (
    <>
      <div className='container-fluid'>
        <Card
          title='Organisation Details'
          extra={<Button onClick={() => setOrganisationToggleForm(true)}>Edit</Button>}
          bordered={false}
          className='customCard'
        >
          {!organisationToggleForm && renderDetails('organisation')}
          {organisationToggleForm && renderForm('organisation')}
        </Card>

        <Divider />

        <Card
          title='Broas Details'
          extra={<Button onClick={() => setOrganisationBroasToggleForm(true)}>Edit</Button>}
          bordered={false}
          className='customCard'
        >
          {!organisationBroasToggleForm && renderDetails('BROAS')}
          {organisationBroasToggleForm && renderForm('BROAS')}
        </Card>
        <Divider />
        <Card
          title='AM DM Details'
          extra={<Button onClick={() => setAMDMToggleForm(true)}>Edit</Button>}
          bordered={false}
          className='customCard'
        >
          {!amdmToggleForm && renderDetails('AMDM')}
          {amdmToggleForm && renderForm('AMDM')}
        </Card>
      </div>
    </>
  );
};
export default MetaDataForm;
