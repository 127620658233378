import { Descriptions } from 'antd';
import React from 'react';
import { isEmpty as _isEmpty } from 'lodash';

const AMDMDetails = props => {
  const { managerData } = props;

  return (
    <>
      <Descriptions bordered>
        {_isEmpty(managerData?.accountManager) ? (
          <Descriptions.Item label='Account Manager'>Not Assigned</Descriptions.Item>
        ) : (
          <Descriptions.Item label='Account Manager'>{managerData?.accountManager}</Descriptions.Item>
        )}

        {_isEmpty(managerData?.digitalMarketer) ? (
          <Descriptions.Item label='Digital Marketer' span={2}>
            Not Assigned
          </Descriptions.Item>
        ) : (
          <Descriptions.Item label='Digital Marketer' span={2}>
            {managerData?.digitalMarketer}
          </Descriptions.Item>
        )}
      </Descriptions>
    </>
  );
};
export default AMDMDetails;
