import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import Switch from 'react-router-transition-switch';
import Loadable from 'react-loadable';
import { connect } from 'react-redux';

import Layout from 'layouts';
import NotFoundPage from 'pages/System/404';
import Recommendations from './components/Recommendations/Recommendations';
import MetaDataForm from './components/MetaData/MetaData';
import RecommendationTemplates from './components/RecommendationTemplates/RecommendationTemplates';
import InsightDetailWrapper from './pages/InsightDetailWrapper/InsightDetailWrapper';
import NotificationAdmin from './components/NotificationAdmin/NotificationAdmin';
import DSSMetadata from 'components/MetaData/DSSMetadata';

const loadable = loader =>
  Loadable({
    loader,
    delay: false,
    loading: () => <div className='shop__initial__loading' />
  });

const routes = [
  {
    path: '/inviteuser',
    Component: loadable(() => import('./components/Dashboard/CreateNewUser/CreateNewUser')),
    exact: true
  },
  {
    path: '/insights',
    Component: loadable(() => import('./pages/Insights/Insights')),
    exact: true
  },
  {
    path: '/insights/:id',
    Component: InsightDetailWrapper,
    exact: true
  },
  {
    path: '/recommendations',
    Component: Recommendations,
    exact: true
  },
  {
    path: '/metadata',
    Component: MetaDataForm,
    exact: true
  },
  {
    path: '/dss-metadata',
    Component: DSSMetadata,
    exact: true
  },
  {
    path: '/templates',
    Component: RecommendationTemplates,
    exact: true
  },
  {
    path: '/notification',
    Component: NotificationAdmin,
    exact: true
  },
  {
    path: '/dashboard',
    Component: loadable(() => import('pages/Dashboard/Dashboard')),
    exact: true
  },
  {
    path: '/system/login',
    Component: loadable(() => import('pages/System/login')),
    exact: true
  },
  {
    path: '/system/forgot-password',
    Component: loadable(() => import('pages/System/forgot-password')),
    exact: true
  },
  {
    path: '/system/register',
    Component: loadable(() => import('pages/System/register')),
    exact: true
  },
  {
    path: '/system/404',
    Component: loadable(() => import('pages/System/404')),
    exact: true
  },
  {
    path: '/settings',
    Component: loadable(() => import('pages/System/settings')),
    exact: true
  },
  // oauth callbacks
  {
    path: '/callback',
    Component: loadable(() => import('pages/System/callback')),
    exact: false
  },
  {
    path: '/spotlight',
    Component: loadable(() => import('pages/Spotlight/index')),
    exact: true
  }
];

@connect()
class Router extends React.Component {
  render() {
    const { history } = this.props;
    return (
      <ConnectedRouter history={history}>
        <Layout>
          <Switch
            render={props => {
              const {
                children,
                location: { pathname }
              } = props;
              return (
                <SwitchTransition>
                  <CSSTransition key={pathname} timeout={300} classNames='slide-fadein-up'>
                    {children}
                  </CSSTransition>
                </SwitchTransition>
              );
            }}
          >
            <Route exact path='/' render={() => <Redirect to='/recommendations' />} />
            {routes.map(({ path, Component, exact }) => (
              <Route path={path} key={path} exact={exact} component={Component} />
            ))}
            <Route component={NotFoundPage} />
          </Switch>
        </Layout>
      </ConnectedRouter>
    );
  }
}

export default Router;
