import Api from '../api';
import { isEmpty as _isEmpty } from 'lodash-es';
export default class Metadata extends Api {
  getAMDMByOrganisation = async () => {
    try {
      const data = await this.get(`/client/users/`);
      return data;
    } catch (error) {
      return error;
    }
  };

  getOrganisationUser = async () => {
    try {
      const data = await this.get(`/organisation/organisation-user`);
      return data;
    } catch (error) {
      return error;
    }
  };

  getBroasForOrganisation = async () => {
    try {
      const data = await this.get(`/organisation/base-roas/`);
      return data;
    } catch (error) {
      return error;
    }
  };

  updateAMDM = async amdmData => {
    try {
      const data = await this.post(`/organisation/user/amdm/`, amdmData);
      return data;
    } catch (error) {
      return {
        hasError: true,
        error: error.response
      };
    }
  };

  updateBroas = async broasData => {
    try {
      const data = await this.post(`/organisation/base-roas/`, broasData);
      return data;
    } catch (error) {
      return {
        hasError: true,
        error: error.response
      };
    }
  };

  getSingleOrganisationRecord = async orgId => {
    try {
      const data = await this.get(`/organisation/${orgId}/`);
      return data;
    } catch (error) {
      return error;
    }
  };

  updateOrganisation = async (organisationData, orgId) => {
    try {
      const data = await this.patch(`/organisation/${orgId}/`, organisationData);
      return data;
    } catch (error) {
      return {
        hasError: true,
        error: error.response
      };
    }
  };

  getConstantDssMetadata = async () => {
    try {
      const data = await this.get(`/dss-metadata/constants/`);

      return data;
    } catch (error) {
      return error;
    }
  };

  getVariableDssMetadata = async report_date => {
    try {
      var url = `/dss-metadata/variables/`;
      if (!_isEmpty(report_date)) {
        url = `/dss-metadata/variables/?report_date=${report_date}`;
      }
      const data = await this.get(url);
      return data;
    } catch (error) {
      return error;
    }
  };

  updateDssMetadataConstants = async dssMetadataConstantsData => {
    try {
      const data = await this.patch(`/dss-metadata/constants/`, dssMetadataConstantsData);
      return data;
    } catch (error) {
      return {
        hasError: true,
        error: error.response
      };
    }
  };

  updateDssMetadataVariables = async dssMetadataVariablesData => {
    try {
      const data = await this.patch(`/dss-metadata/variables/`, dssMetadataVariablesData);
      return data;
    } catch (error) {
      return {
        hasError: true,
        error: error.response
      };
    }
  };

  createDssMetadataVariables = async dssMetadataVariablesData => {
    try {
      const data = await this.post(`/dss-metadata/insert/variables/`, dssMetadataVariablesData);
      return data;
    } catch (error) {
      return {
        hasError: true,
        error: error.response
      };
    }
  };
}
